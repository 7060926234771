import React, { Component } from 'react';
import { Container,Row, Col,FormGroup,Label,Button,Card,CardBody,Table} from 'reactstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import { baseurl } from './BaseUrl';
import FlatList from 'flatlist-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faMobileAlt, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const findAllitemUrl = baseurl + 'listofbeneficiary';


class ListBeneficiary extends Component{
constructor(props) {
 super(props);
 this.state = {
  Rows: [ ],
 }
 
}
renderPerson = (person, idx) => {
//alert(JSON.stringify(person));
 return (
 <tr  key={`${person._id}`}>
<td>{person.Name} </td>
<td>{person.phoneNumber} </td>
<td>{person.email} </td>
<td>{person.Location} </td>


<td>{person.City} </td>
<td>{person.State} </td>
<td>{person.pincode} </td>
 </tr>
 );
}
componentDidMount() {
    //alert("ff");
axios.post(findAllitemUrl)
.then(Response => {
 //alert(JSON.stringify(Response));
this.setState({Rows:Response.data})

})
.catch(err => {
console.log(err);
})
}

    render(){
        return(
            <div><br></br>
            <Container>
            <Row className="justify-content-end mt-0">
                                        <Link to="/DashboardAdmin" style={{ color: "white", textDecoration: "none" }}>
                                            <FontAwesomeIcon style={{ color: "red", fontSize: "30px" }} icon={faTimesCircle} ></FontAwesomeIcon> </Link>
                                    </Row> 
<h2 style={{textAlign:'center'}}>List of vendors</h2>
<Table striped id="students">
  <tr>

<th>Vendor Name </th>
<th>Ph.No </th>
<th>E-mail</th>
<th> Address </th>


<th>City</th>
<th>State</th>
<th>Pincode</th>
</tr>

<FlatList list={this.state.Rows} renderItem={this.renderPerson}/>
</Table>
               
 
                <br></br>
            </Container>
        </div>
        )
    }
}
export default ListBeneficiary