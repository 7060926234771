import React, {Component} from 'react';
import NewHeader from '../Components/NewHeader';
import {Container, Col, Form, FormGroup,InputGroup,InputGroupAddon,InputGroupText, Button,Row, Label,Input,Card,CardHeader,CardBody} from 'reactstrap';
//import {Link} from 'react-router-dom';
import {Link,Route,Switch, Redirect} from 'react-router-dom';
import axios from 'axios';
import { baseurl } from './BaseUrl';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faAddressCard,faMobileAlt,faEnvelope,faGraduationCap,faPhone,faUnlock} from '@fortawesome/free-solid-svg-icons';
import { faInstagram,faFacebook,faTwitterSquare } from '@fortawesome/free-brands-svg-icons' ;
const form1sub = baseurl + 'DResourceRegistration1/form1';
const VerifyResRegOtp = baseurl + 'DVerifyResRegOtp1';
const form2sub = baseurl + 'DResourceRegistration/form2';
const form3sub = baseurl + 'DResourceRegistration/form3';
const postalCode = baseurl + 'postalcode';


// const form3sub = baseurl + 'ResourceRegistration/form3';

//const Registrationurl = baseurl + 'registration';

class DealerSignup extends Component{
//state={isLogged:false}

    constructor(props) {
        super(props);
        this.state = {
            firstForm: true,
            secondForm: false,
            thirdForm: false,
     
         Name: '',
         email: '',
         phone: '',
         phoneNumber: '',
            //State for Form 2
            form2button: true,
            regOtp: '',
            pincode: '',
            City:'',
            State:'',
             address: ''
      
         
        }
    
        this.handleName = this.handleName.bind(this);
        this.handleemail = this.handleemail.bind(this);
     
        this.handlePhone = this.handlePhone.bind(this);
        this.handleFirstForm = this.handleFirstForm.bind(this);
        //2nd
        this.handleRegOtp = this.handleRegOtp.bind(this);
   
        this.handleSecondForm = this.handleSecondForm.bind(this);
        this.handlePincode = this.handlePincode.bind(this);
        this.handleCity=this.handleCity.bind(this);
        this.handleState=this.handleState.bind(this);
        this.handleThirdForm = this.handleThirdForm.bind(this);
       
    }

    handleName(e) {
        const re = /^[a-zA-Z]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ firstName: e.target.value, fnameError: false });
        }
    }
    handleemail(e) {
      
            this.setState({ lastName: e.target.value, lnameError: false });
      
    }
    //handlePhone(e) { this.setState({ phone: e.target.value }) }

////////////////////////////////////
handlePhone(e) {
    if (e.target.value.length > 10) {
        return;
    }
    this.setState({ phone: e.target.value, phoneError: false });
}

handleFirstForm(e) {
    e.preventDefault();
    if (e.target.fname.value === '') {
        alert("Please Enter Name");
        this.setState({ fnameError: true })
        return;
    }
    if (e.target.lname.value === '') {
        alert("Please Enter Email");
        this.setState({ lnameError: true })
        return;
    }
    if (e.target.contact.value.length < 10) {
        alert("Please Enter a valid Number");
        this.setState({ phoneError: true })
        return;
    }
    console.log("1st form submitted");
    let form1Obj = {
        Name: e.target.fname.value,
        email: e.target.lname.value,
        phoneNumber: e.target.contact.value
    }
	
    localStorage.setItem("email", e.target.lname.value);
	localStorage.setItem("mobile", e.target.contact.value);
    axios.post(form1sub, form1Obj)
        .then(response => {
            console.log(response);
            if (response.data.statusCode === 400) {
                alert("User is already Registered");
                toast.error("User is already Registered", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true
                });
            }
            else if (response.status === 200) {
                //set userid for further use into localstorage
                let reguserId = response.data._id;
                localStorage.setItem("reguserId", reguserId)

                sessionStorage.setItem("Name", response.data.Name);
                sessionStorage.setItem("email", response.data.email);
                sessionStorage.setItem("phoneNumber", response.data.phoneNumber);

                console.log("Successfully Registered")
                this.setState({
                    firstForm: false,
                    secondForm: true
                })
            }
        })
}

/////////////////////////////

 //methods for form2
 handleRegOtp(e) {
    if (e.target.value.length > 6) {
        return;
    }
    else if (e.target.value.length === 6) {
        //confirm registration otp is wrong or right
        let phone = this.state.phone;
       
        let verifyObj = {
            phoneNumber: phone,
            otp: e.target.value
        }
        this.setState({ regOtp: e.target.value })
        axios.post(VerifyResRegOtp, verifyObj)
            .then(response => {
                console.log(response);
                if (response.data.statusCode === 200) {
                    //User Verified, enable submit button
                    alert("OTP Verified")
                    toast("OTP Verified", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true
                    });
                   
// sessionStorage.setItem("logged", "dealer")
// window.location.reload();
this.setState({
            //     form2button: false,
            secondForm: false,
            thirdForm:true
            //    isLogged: true
            })
                }
                else if (response.data.statusCode === 400) {
                    alert("OTP is wrong")
                    toast("OTP is wrong", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true
                    });
                }
            })
    }
    else {
        this.setState({ regOtp: e.target.value })
    }
}

handleSecondForm(e) { 
    // alert(`your number ${this.state.phone} Registered successfully ` )
  
    console.log(e.target);
  
    axios.post(form2sub)
      .then(res => {
          console.log(res.status);
     if(res.status === 200) {
          //handle cases based on res.status 200 or 404
     alert("successfully registered");
          this.setState({
             secondForm: false,
             thirdForm: true,
            //  isLogged: true
          })
     } else {
     alert("Some thing went worng! Please try again");
     }
      })
      .catch(err => {
          console.log(err)
      })
  }
 
  
  
handlePincode(e) {
    if (e.target.value.length >= 7) {
        return;
    }
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
        this.setState({ pincode: e.target.value });
        if (e.target.value.length === 6) {
            //send axios request
            console.log("send axios request");
            axios.post(postalCode, { postalCode: e.target.value })
                .then(response => {
                    // handle success
                    console.log(response);
                    let data = response.data.PostOffice[0];
                    this.setState({
                        //  city: data.District,
                        // state: data.State
                        city: data.District,
                        state: data.State
                    })
                })
                .catch(error => {
                    // handle error
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        }
    }
  }
  
  
  
  handleCity = event => {
  
      
      this.setState({
      City: event.value
   })
       
  }
  handleState = event => {
    
       this.setState({
      State: event.value
      
    })
   
    
  }


  handleThirdForm(e) {
    e.preventDefault();
    console.log(e.target);
    // alert(this.state.defaultOption);
    console.log("3rd form submitted");
    let reguserId = localStorage.getItem("reguserId");
	
	let mobile = localStorage.getItem("mobile");
  let email = localStorage.getItem("email")

    let subObject = {
        userId: reguserId,
     
        pincode: e.target.pincode.value,
        City: e.target.City.value,
        State: e.target.State.value,
        location: e.target.location.value,
		 mobile: mobile,
      email: email,
       
       
    }
    axios.post(form3sub, subObject)
        .then(res => {
            console.log(res.status);
   if(res.status === 200) {
  
   {
    //alert("rfe")
       
  sessionStorage.setItem("logged", "dealer")
  sessionStorage.setItem("user_id",reguserId);
       this.setState({ isLogged:true })
 // window.location.reload();
   }
  
            //handle cases based on res.status 200 or 404
            this.setState({
                thirdForm: false,
                forthForm: true
   
            });
    this.myRef.current.scrollTo(0, 0);
   
   
   } else {
   alert("Some thing went wrong! Please try again");
   
   }
        })
        .catch(err => {
            console.log(err)
        })
  }
  


static defaultProps = {
    center: {
        lat: 28.414648,
        lng: 77.094404
    },
    zoom: 15
};

    onDismiss() {
        this.setState({ visible: false });
    }

    render(){
        return(
            <div className="bgfund">
            <Container >
            <br></br> <br></br><br></br>
            <Row className="justify-content-center">
         
            <Col lg="6" md="8">
            <Card className=" shadow border-0 " style={{backgroundColor:"white"}}>
         
                 
              <CardBody  className="px-lg-5 py-lg-5">
              
              <div style={{textAlign:"center", color:"gray"}}>
              {/* <img style={{height:"50px",width:"60px",borderRadius:"50px", backgroundColor:"white"}} src="../Image/logo.png"></img> */}
              <img style={{ height: "40px", width: "60px" }} src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img>
                            
              <h3 style={{textAlign:"center", color:"gray"}}><u>Vendor Signup  </u></h3>
             
              <br></br>
              {this.state.firstForm &&
                <Form key={1} onSubmit={this.handleFirstForm}>
              <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                        {/* <i className="ni ni-hat-3" /> */}
                        <FontAwesomeIcon icon={faGraduationCap}  aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="text" name="fname" placeholder="Name*" value={this.state.firstName} onChange={this.handleFirstName} className={this.state.fnameError ? 'error' : null} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{backgroundColor:"white"}}>
                        {/* <i className="ni ni-email-83" /> */}
                        <FontAwesomeIcon icon={faEnvelope}  aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="text" name="lname" placeholder="Email*" value={this.state.lastName} onChange={this.handleLastName} className={this.state.lnameError ? 'error' : null} />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{backgroundColor:"white"}}>
                        {/* <i className="ni ni-email-83" /> */}
                        <FontAwesomeIcon icon={faPhone} style={{transform: "rotate(90deg)"}} aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="number" name="contact" placeholder="Phone Number*" value={this.state.phone} onChange={this.handlePhone} className={this.state.phoneError ? 'error' : null} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                                       
                                        <Button type="submit" block>Get OTP</Button>
                     
                                    </FormGroup>
                                    <hr color='#045AC4' style={{ height: '1px' }}></hr><br></br>
                                    <h5 style={{ textAlign: 'center', fontWeight: 'bold', color: '#045AC4' }} >Already Registered</h5>
                                    <Link to="/DealerLogin" >
                                        <Button style={{ color: "white", backgroundColor: "#045AC4", width: "100%" }} block>Login Here</Button>
                                    </Link>
                </Form> }
                </div>
               
           
               
{/* ************************************************************************************* */}

                         
                            {this.state.secondForm &&
                                <Form key={2} onSubmit={this.handleSecondForm}>

<FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                        {/* <i className="ni ni-hat-3" /> */}
                        <FontAwesomeIcon icon={faUnlock}  aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="text" name="otp" placeholder="OTP" value={this.state.regOtp} onChange={this.handleRegOtp} required />
                     </InputGroup>
                </FormGroup>

                <FormGroup>
                                                              <Button type="submit" disabled={this.state.form2button} block>Send</Button>
                                    </FormGroup>
                                   

                                     <Route exact path="/DealerSignup"
                    render={() => (this.state.isLogged ? (<Redirect to="/DealerDashboard" />) : (null))} />
 
                                </Form>}
                         
                           
                            
               
                <br></br>


                {this.state.thirdForm &&
                <Form key={3} onSubmit={this.handleThirdForm}>
  


                <FormGroup>
                                  <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText style={{backgroundColor:"white"}}>
                                        {/* <i className="ni ni-email-83" /> */}
                                        <FontAwesomeIcon icon={faEnvelope}  aria-hidden="true" ></FontAwesomeIcon>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input style={{ boxShadow: "none"}} type="text" name="pincode"
                                                        value={this.state.pincode}
                                                                 onChange={this.handlePincode}
                                                                 placeholder="Enter pincode*"  />
                                  </InputGroup>
                                </FormGroup>
                
                
                <FormGroup>
                                  <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText style={{backgroundColor:"white"}}>
                                        {/* <i className="ni ni-email-83" /> */}
                                        <FontAwesomeIcon icon={faEnvelope}  aria-hidden="true" ></FontAwesomeIcon>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input style={{ boxShadow: "none"}} type="text" name="City"
                                                       value={this.state.city} onChange={this.handleCity}
                                                                 placeholder="Enter city*" />
                                  </InputGroup>
                                </FormGroup>
                
                                <FormGroup>
                                  <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText style={{backgroundColor:"white"}}>
                                        {/* <i className="ni ni-email-83" /> */}
                                        <FontAwesomeIcon icon={faEnvelope}  aria-hidden="true" ></FontAwesomeIcon>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input style={{ boxShadow: "none"}} type="text" name="State"
                                                       value={this.state.state} onChange={this.handleState}
                                                                 placeholder="Enter state*" />
                                  </InputGroup>
                                </FormGroup>
                
                                <FormGroup>
                                  <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText style={{backgroundColor:"white"}}>
                                        {/* <i className="ni ni-email-83" /> */}
                                         <FontAwesomeIcon icon={faEnvelope}  aria-hidden="true" ></FontAwesomeIcon>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input style={{ boxShadow: "none"}} type="text" name="location"
                        
                                                                 placeholder="Address*"  />
                                  </InputGroup>
                                </FormGroup> 
                
                
                {/* <Col md={3}>
                                            <FormGroup>
                                            <Label for="Location" >
                                                <b> Location</b></Label> */}
                                         
                                            
                                            {/* <GooglePlacesAutocomplete  onSelect={({ description }) => (
                          this.setState({ address: description })
                        )}
                    renderSuggestions={(active, suggestions, onSelectSuggestion) => (
                      <div className="suggestions-container">
                        {
                          suggestions.map((suggestion) => (
                            <div
                              className="suggestion"
                              onClick={(event) => onSelectSuggestion(suggestion, event)}
                            >
                              {suggestion.description}
                            </div>
                          ))
                        }
                      </div>
                    )}
                  /> */}
                                            {/* </FormGroup> */}
                
                                            
                                            
                                            
                                            
                                            {/* </Col> */}
                
                
                
                             
                                <Button type="submit" block>Submit</Button>
                                                 
                
                                <Route exact path="/DealerSignup"
                    render={() => (this.state.isLogged ? (<Redirect to="/DealerDashboard" />) : (null))} />
 
                
                                              </Form> }

                                              </CardBody>
            </Card>
             
            </Col>  
            <br></br>

<Col md="6"><h2 style={{ fontStyle: "italic", color: "#1472B1", textAlign: "center", marginTop: "120px", marginLeft: "30px" }} className="text-justify">
The dealers in waste material are an integral part and parcel of our cycle. Dealers can facilitate the process by providing funds for waste material.  </h2>
</Col>  
       </Row>
            <br></br>
            </Container> <NewHeader />
        </div>
        )
    }
}
export default DealerSignup
