import React, { Component } from 'react';
import {Container,Row,Col} from 'reactstrap';
class Overview extends Component{
    render(){
        return(
            <div className="bg">
                <br></br>
                <Container> 
                <div>  <marquee direction="right"><b><p style={{color:"maroon"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
                </Container>
               
            <div style={{backgroundColor:"black"}}> <p style={{color:"white",textAlign:"center",fontSize:"70px"}}><br></br>Overview</p>
            <p style={{color:"white",textAlign:"center",fontSize:"25px",fontStyle:"italic"}}>_____________ What do we do?</p>
            <br></br></div>

<div>
    <br></br>
    <br></br>
   
    <Container>
    <h2>Our Work:</h2>
    <br></br>
 <p className="pfont text-justify  text-muted"> <b style={{color:"black"}}>  ---------- NGO </b>  works with the aid of Government educational institutions and the help of community volunteers to educate and empower girls with higher studies which can shape their career in the competitive world. </p>
<br></br>
<p className="pfont text-justify  text-muted">This help is provided to the socially and economically weaker girl child to pursue higher education after 10+2. We aim to provide learning outcomes through help offered by the community volunteers in the form of the kind. There are several government educational institutions which even offer free education or provide scholarships to the under-privileged girls in the society to complete their studies in the field of Medicine, Engineering, and Management. </p>
 <br></br>
 <p className="pfont text-justify  text-muted"> With the help of education, these girls can transform their lives and be a part of the competitive business world. They will be able to brush shoulders with girls belonging to diverse strata and walks of life. This initiative will help in bridging the gender gap in education. </p>
 <br></br>

 <p className="pfont text-justify  text-muted"> The path is not very easy. We run the NGO with the aid of the government on one hand and bring community volunteers together on the other hand. We organize everything; right from getting approval from the government educational institution to preparing a scalable model wherein the right set of people can provide aid for higher education amongst underprivileged girls in the society. </p>

 <br></br>
 <div style={{textAlign:"center"}}> 
<img src="./Image/ngo.jpg" className="responsiveImg" style={{borderRadius:"30px"}} ></img>
</div >
<br></br>
<br></br>
<br></br>
    </Container>
 

</div>

            </div>


        )
    }
}
export default Overview