import React, {  } from 'react'
import NewHeader from '../Components/NewHeader';

// import Chart from "react-google-charts";
import {
    
    Alert, Container,  Row, Col,  Button, CardBody,   Card,  CardHeader, CardSubtitle
} from 'reactstrap';
import { faAddressCard, faMobile, faEnvelope, faSignature,faEdit, faUser} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Link} from 'react-router-dom';

import GoogleMapReact from 'google-map-react';
const Marker = props => {
    return <div><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></div>
} 

class DealerDashboard extends React.Component {
    static defaultProps = {
        center: {
            lat: 28.414648,
            lng: 77.094404
        },
        zoom: 15
    };
    constructor(props) {
        super(props);

        this.state = {
            visible: true
        };

        // this.onDismiss = this.onDismiss.bind(this);

        let is_login = sessionStorage.getItem("logged");
        if(is_login === 'none') {
            this.props.history.push('/');
            }


        this.onDismiss = this.onDismiss.bind(this);



    }

    onDismiss() {
        this.setState({ visible: false });
    }

    render() {
        return ( 
       // <div className="donorbg">
       <div className="bgfund"  style={{padding:"30px"}}>
       {/* <div className="dshbrdimg" style={{padding:"30px"}} > */}
            <br></br>
        {/* <Container style={{ backgroundColor: '#d5d5d5' }}> */}
        <Container  className="divbg"> 
        <br></br>
<Row className="justify-content-center"><h2  style={{color:"white"}}><u>Dealer Dashboard </u></h2> </Row>
            <div style={{ padding: '10px' }}>
                <Row style={{ padding: '10px' }}>
                    <Col md={4} sm={12}>
                        <Row>
                            <Col md={12}>
                            <Card className=" shadow border-0 "style={{ paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px' }} >
<CardBody  className="px-lg-3 py-lg-3"> 
                                {/* <Card style={{ paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px' }}> */}
                                <Row>
                                            <Col md={10}>
                    <h4  className="text-muted" style={{textAlign:"center"}}> <FontAwesomeIcon style={{color:"#2E3092"}} icon={faUser}   aria-hidden="true" style={{color:"darkblue",fontSize:"23px"  }}></FontAwesomeIcon> My Profile</h4> </Col>
                                            <FontAwesomeIcon style={{color:"#2E3092"}}/>                                            
                                                <Link to="/VendorProfile" style={{ color: "black" }} id="TooltipExample">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Link>
                                        </Row>
 <br></br>  <br></br>

                                    <Row>
                                        <Col md={12}>   <FontAwesomeIcon style={{color:"#2E3092"}} icon={faSignature} aria-hidden="true" ></FontAwesomeIcon> &nbsp; &nbsp;{sessionStorage.getItem("Name")}</Col>
                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Col md={12}>   <FontAwesomeIcon style={{color:"#2E3092"}} icon={faMobile} aria-hidden="true" ></FontAwesomeIcon> &nbsp; &nbsp;{sessionStorage.getItem("phoneNumber")}</Col>
                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Col md={12}>   <FontAwesomeIcon style={{color:"#2E3092"}} icon={faEnvelope} aria-hidden="true" ></FontAwesomeIcon> &nbsp; &nbsp;{sessionStorage.getItem("email")}</Col>
                                    </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col md={12}>
                                <Card  className=" shadow border-0 " style={{ paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px', paddingRight: '10px' }}>
                                <CardBody  className="px-lg-2 py-lg-2">
                                    <div style={{ height: '250px', width: '100%' }}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: 'AIzaSyBKdXoEy8iDv_7yvzRJkH2mW9LFieOIT4g' }}
                                            defaultCenter={this.props.center}
                                            defaultZoom={this.props.zoom}>
                                            <Marker lat={28.414648} lng={77.094404} />
                                        </GoogleMapReact>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={8} sm={12}>
                        <Row  style={{paddingTop:"10px"}}>
                            
                            <Col md={12}>
                                <Alert color="warning" isOpen={this.state.visible}>
                                 <Link to="/PickingDetails" style={{textDecoration:"none",  color:"000A5B"}}><b>Pickup Request</b></Link>  &nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* <Button style={{ marginLeft: '30%' }} color='danger' id='btnCancelBooking'> */}
                                    {/* <button>Donate</button> */}
                                        {/* <FontAwesomeIcon icon={faBan}></FontAwesomeIcon> */}
                                        {/* </Button> */}
                                    {/* <UncontrolledTooltip placement="right" target="btnCancelBooking">Cancel this Booking</UncontrolledTooltip> */}
                               </Alert>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Alert color="warning" isOpen={this.state.visible}>
                                 <Link to="#" style={{textDecoration:"none",color:"000A5B"}}><b>Ragman Details</b></Link>  &nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* <Button style={{ marginLeft: '30%' }} color='danger' id='btnCancelBooking'> */}
                                    {/* <button>Donate</button> */}
                                        {/* <FontAwesomeIcon icon={faBan}></FontAwesomeIcon> */}
                                        {/* </Button> */}
                                    {/* <UncontrolledTooltip placement="right" target="btnCancelBooking">Cancel this Booking</UncontrolledTooltip> */}
                               </Alert>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Alert color="warning" isOpen={this.state.visible}>
                                 <Link 
                                //  to="/ViewDonorItem" 
                                 style={{textDecoration:"none",color:"000A5B"}}><b>Message By प्रnee</b></Link>  &nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* <Button style={{ marginLeft: '30%' }} color='danger' id='btnCancelBooking'> */}
                                    {/* <button>Donate</button> */}
                                        {/* <FontAwesomeIcon icon={faBan}></FontAwesomeIcon> */}
                                        {/* </Button> */}
                                    {/* <UncontrolledTooltip placement="right" target="btnCancelBooking">Cancel this Booking</UncontrolledTooltip> */}
                               </Alert>
                            </Col>
                        </Row>


                        


                        {/* <br></br>
                        <Row>
                        <Col md={4}> 
                         <Link to="DonorDetailsForm">
                        <Button  className="btn btn-info"><b>Donate Now</b></Button></Link>
                        </Col>  </Row> */}
                        <br></br><br></br><br></br>
                        <Row>
                            <Col md="4">
                                {/* <Card className=" shadow border-0 "> */}
                                <CardBody style={{textAlign:"center"}} className="px-lg-3 py-lg-3">
                                <img src="../Image/plastic.jpg" className="responsiveImg " style={{height:"150px",width:"150px"}}></img>
                                 <br></br>  
                                 <h5 className="text-muted">Plastic</h5>
                                   

                                 </CardBody>
                                 {/* </Card> */}
                                 </Col>

                            <Col md="4">

                            {/* <Card className=" shadow border-0 "> */}
                              <CardBody style={{textAlign:"center"}} className="px-lg-3 py-lg-3">
                              <img src="../Image/glass.jpg" className="responsiveImg" style={{height:"150px",width:"150px"}}></img>
                                 <br></br>  
                                <h5 className="text-muted">Glass</h5>
                             

                                </CardBody>
                                {/* </Card> */}

                            </Col>

                            <Col md="4">
                            {/* <Card className=" shadow border-0 "> */}
                      <CardBody style={{textAlign:"center"}} className="px-lg-3 py-lg-3">
                     <img src="../Image/clothes.png" className="responsiveImg" style={{height:"150px",width:"150px"}}></img>
                        <br></br>  
                     <h5 className="text-muted">Clothes</h5>
                     

                        </CardBody>
                            {/* </Card> */}

                            </Col>

                         </Row>


                       
                    </Col>
                </Row>
                {/* For Spacing */}
               
               
            </div>

        </Container>	 <NewHeader />
        <br></br>
        </div>
           
        );
    }
}
export default DealerDashboard;