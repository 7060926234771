import React, {Component} from 'react';
import NewHeader from '../Components/NewHeader';
import {Container, Col, Form, FormGroup,InputGroup,InputGroupAddon,InputGroupText, Button,Row, Label,Input,Card,CardHeader,CardBody} from 'reactstrap';
//import {Link} from 'react-router-dom';
import {Link,Route, Redirect} from 'react-router-dom';
import axios from 'axios';
import { baseurl } from './BaseUrl';
import { ToastContainer, toast } from 'react-toastify';
import MultipleSignUp from './MultipleSignUp';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faAddressCard,faMobileAlt,faEnvelope,faGraduationCap,faPhone,faUnlock} from '@fortawesome/free-solid-svg-icons';
import { faInstagram,faFacebook,faTwitterSquare } from '@fortawesome/free-brands-svg-icons' ;
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/assets/index.css';
// import {sms} from './sms';
const form1sub = baseurl + 'ResourceRegistration/form1';
const VerifyResRegOtp = baseurl + 'VerifyResRegOtp'
const form2sub = baseurl + 'ResourceRegistration/form2';
const form3sub = baseurl + 'ResourceRegistration/form3';
const postalCode = baseurl + 'postalcode';


//const Registrationurl = baseurl + 'registration';

export default class OtherNgo extends Component{
//state={isLogged:false}

    constructor(props) {
		//alert("dsa");
        super(props);
        this.state = {
           
         
        }
 
 
     
    }

    handleName(e) {
        // const re = /^[a-zA-Z]+$/;
        // if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ firstName: e.target.value, fnameError: false });
        // }
    }
    handleemail(e) {
       // const re = /^[a-zA-Z]+$/;
       // if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ email: e.target.value, lnameError: false });
       // }
    }
  //  handlePhone(e) { this.setState({ phone: e.target.value }) }












      static defaultProps = {
        center: {
            lat: 28.414648,
            lng: 77.094404
        },
        zoom: 15
    };
  

    onDismiss() {
        this.setState({ visible: false });
    }

    render(){
        return(
         <div className="bg">   
         {/* <div className="donorbg">  */}
         <br></br>
                <Container> 
                <div>  <marquee behavior="alternate" direction="right"><b><p style={{color:"maroon",fontStyle:"italic"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
                </Container>
                      
            <div className="divbg"><br></br> <p style={{color:"white",textAlign:"center",fontSize:"50px"}}>NGO</p>
            <p style={{color:"white",textAlign:"center",fontSize:"20px",fontStyle:"italic"}}>Non Governmental Organization<br></br> <br></br>
           
</p> </div>
            <Container >

            <br></br> <br></br>
            <Row className="justify-content-center">
          
            <Col md="7">
            <p className="pfont text-muted text-justify">Inter-dependence and networking have become buzzwords in the industry. 
            &nbsp;<img style={{height:"30px",width:"45px"}} src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img> &nbsp; highly recommends an environment of inter-dependency as it acts as a catalyst in the achievement of our noble cause.
              The dominant role and contributions made by RWA, Corporate, Government of India, and NGOs towards our Trust cannot be overlooked. </p>
           <br></br><br></br>
            <p className="pfont text-muted text-justify">Mutual help and assistance can make a sea of difference in every field.
             We will get swelled with gratitude as other NGOs join hands with us in the achievement of our goal.
              We invite NGOs to join us and help in making a marked change towards the development of the circumstances of underprivileged girls and their families. </p>
          
           
           
               
                </Col>  

            <Col  md="5">
            <br></br>
            <MultipleSignUp/>
            </Col>   
          
       </Row>
            <br></br> <br></br><br></br>
            </Container> <NewHeader />
        </div>
        )
    }
}
//export default DonorSign

