import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import { Container, Row, Col, FormGroup, Label, Button, Card, CardBody, Table } from 'reactstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseurl } from './BaseUrl';
import FlatList from 'flatlist-react';
import Moment from 'react-moment';
import 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faMobileAlt, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
const findAllitemUrl = baseurl + 'myorder';


class DonorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Rows: [],

        }
        let is_login = sessionStorage.getItem("logged");
        console.log(is_login);
        if (is_login === 'none') {
            this.props.history.push('/');
            window.location.reload();
        }
    }
    renderPerson = (person, idx) => {
        //alert(JSON.stringify(person));
        return (
            <tr key={`${person._id}`}>
                <td>{person.Itemname} </td>
                <td>{person.Quantity} </td>
                <td>{person.Location} </td>
                <td><Moment format="DD/MM/YYYY">
                    {person.Availabledate}
                </Moment> </td>
            </tr>
        );
    }
    componentDidMount() {
        //console.log(this.props.id);//Selected Service Id
        //sessionStorage.getItem("logged");
        let datad = {
            user_id: sessionStorage.getItem("user_id"),

        }

        axios.post(findAllitemUrl, datad)
            .then(Response => {
                //alert(JSON.stringify(Response));
                this.setState({ Rows: Response.data.data })

            })
            .catch(err => {
                console.log(err);
            })
    }

    render() { //alert(JSON.stringify(this.data));
        return (
            <div className="donorbg">
                <Container>


                    <br></br><br></br><br></br>

                    <Row className="justify-content-center" >

                        <Col lg="10" md="8">
                            <Card className=" shadow border-0 " style={{ backgroundColor: "white" }}>
                                <CardBody className="px-lg-5 py-lg-4">

                                    <Row className="justify-content-end mt-0">
                                        <Link to="/DonorDashboard" style={{ color: "white", textDecoration: "none" }}>
                                            <FontAwesomeIcon style={{ color: "red", fontSize: "23px" }} icon={faTimesCircle} ></FontAwesomeIcon> </Link>
                                    </Row> 

                                    <h2 style={{ textAlign: "center" }} className="text-muted"><u>My Contribution Details</u></h2>

                                    <br></br>
                                    <div style={{ overFlowx: "auto" }}>

                                        <Row>
                                            <Table id="students">
                                                <tr>
                                                    <th>Itemname </th>
                                                    <th>Quantity </th>
                                                    <th>Location </th>
                                                    <th>Availabledate </th>
                                                </tr>

                                                <FlatList list={this.state.Rows} renderItem={this.renderPerson} />
                                            </Table>
                                        </Row>
                                    </div>
                                    <br></br> <br></br>
                                    <div style={{ textAlign: 'center' }}>

                                        <Link to="/DonorDetailsForm" >  <Button className="btn btn-info">Add More</Button></Link>
                                    </div>
                                    &nbsp;&nbsp;



              </CardBody>
                            </Card>
                        </Col>

                    </Row>

                    <br></br><br></br><br></br>
                </Container>
                <NewHeader />
            </div>

        );
    }
}
export default DonorDetails;
