import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import {Container,Row,Col} from 'reactstrap';
import { baseurl } from './BaseUrl';
import axios from 'axios';

const get_homecontent = baseurl + 'get_homecontent1';

 export default class Vision extends Component{

    constructor(props) {
        super(props);

this.state = {
            data: ''
        };
       
    }

    componentDidMount() {
   
     
    
    axios.post(get_homecontent)
    .then(Response => {
   
    this.setState( {
    data:Response.data.data[0].vision
    } );
    
    })
    .catch(err => {
    console.log(err);
    })
    
    
        }
    


    render(){
        return(
            <div className="bg">
                <br></br>
                <Container> 
                <div>  <marquee direction="right" behavior="alternate"><b><p style={{color:"maroon",fontStyle:"italic"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
                </Container>
                
            <div className="divbg"><br></br> <p style={{color:"white",textAlign:"center",fontSize:"50px"}}>Our vision</p>
            <p style={{color:"white",textAlign:"center",fontSize:"20px",fontStyle:"italic"}}> ....... We become what we think about !!!!!</p><br></br></div>


    <br></br>
    <br></br>
    <Container >
   
    <article>
 
 <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div>
</article>
   
   
    </Container>
    <NewHeader />

    <br></br><br></br>

            </div>


        )
    }
}
// export default Vision