
import React, { Component } from 'react';
import { Container,Row,Col,Card,CardBody,Button} from 'reactstrap';
// import NgoHeader from '../Components/ngoHeader';
import Slider from '../Components/Slider';
import NewHeader from '../Components/NewHeader';
import { Link } from "react-router-dom";
// import Login from '../Components/LoginPage';
// import Footer from '../Components/FooterN';
// import NgoFooter from '../Components/NgoFooter';
// import DonorHome from '../Components/DonorHome';
// import AdmBenefi from '../Components/HomeBeneficiary';
// import HomeLogis from '../Components/HomeLogistic';
//import DonorPage from '../Components/Donor';
// import LoginPage from '../Components/Login';
// import AdminDashboard from '../Components/DasboardAdmin';
import AidImages from '../Components/AidImages';
import news from '../Components/Dynamicnews';
import axios from 'axios';
import { baseurl } from './BaseUrl';

//import { apk1 } from '../MobileApp/ap';

const save_ip = baseurl + 'save_ip';
const get_homecontent = baseurl + 'get_homecontent';
export default class Home extends Component {
constructor(props) {
        super(props);

this.state = {
            data: ''
			
			
        };
       //var path = `${window.location.origin.toString()}/Experience.pdf/`;
	   //alert(this.state.ppath);
    }

  componentDidMount() {
   
    axios.get(save_ip)
    .then(Response => {
      alert(Response);
   
    })
    .catch(err => {
    console.log(err);
    })

axios.post(get_homecontent)
.then(Response => {
// alert(JSON.stringify(Response.data.data[0].home));
this.setState( {
data:Response.data.data[0].home
} );

})
.catch(err => {
console.log(err);
})


    }

    render() {
        return (
            <div className="bg">
             
        <br></br>
              
                   <Container>
                 <Row>
                 <Col  xs="2" md="1">
                  <Link to="/RWA"><img title="Click to join" style={{height:"60px",width:"70px",maxHeight:"auto",maxWidth:"100%"}} src="https://i.postimg.cc/C5nTSC8D/Untitled.png"></img> </Link>
                 </Col>
                 <Col md="10" xs="8">
                  

                <b><p style={{color:"maroon",fontStyle:"italic"}}>
       <a href="https://drive.google.com/file/d/1JcnOHZSYbajQiKMJ19SLi8EH3ffEUI5L/view" target="_blank">  Click here to Download Pra-nee App</a>
                </p> </b>

                  
                 </Col>
                 <Col  xs="2" md="1">
                <Link to="/Corporate"><img title="Click to join" style={{height:"60px",width:"70px",maxHeight:"auto",maxWidth:"100%"}} src="https://i.postimg.cc/PrhbYX3s/CSR.png" ></img>  </Link>
                </Col>
                </Row>
              
<Row className="slider1">
         <Col md="9">
                <Slider/></Col>
                  
                <Col md="3">
                    
                    <Link to ="FullNews">
                    
                    <p style={{textAlign:"center",fontStyle:"italic"}}> <u>Latest news</u> </ p>
                    </Link> 
				<marquee behavior="scroll" direction="up" onmouseover="this.stop()" onmouseout="this.start()">
<AidImages />

       </marquee>            

                </Col> 
               
                </Row> </Container>
                <br></br>
         
                 
                        <br></br>
                        <Container>
                            <article>
 
 <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div>
</article>
                       
                    <div>
                        <h2 style={{textAlign:"center",color:"maroon"}}><u>Our Work Process</u> </h2>
                        <Card className=" shadow border-0 "> <CardBody className="px-lg-3 py-lg-3">
                        {/* <img src="https://i.postimg.cc/4y0xtgYJ/Workflow.jpg" className="workflow "style={{borderRadius:"60px"}}></img> */}
                       
                        <img src="https://res.cloudinary.com/deb9cwxor/image/upload/v1619507766/Work_process_website_banner_yplbyx.png" className="workflow "style={{borderRadius:"60px"}}></img>
                       

                        </CardBody>
                         
                        </Card>
                    </div>
<br></br> <br></br>
               </Container>
            
             
               
  <NewHeader />
   
             
               <br></br>
             
                {/* <AidImages/> */}
                  </div>
           
        );
    }
}  
