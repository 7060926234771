import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import {Container,Row,Col} from 'reactstrap';
import { baseurl } from './BaseUrl';
import axios from 'axios';

const get_homecontent = baseurl + 'get_homecontent2';
class Pranee1 extends Component{

    constructor(props) {
        super(props);

this.state = {
            data: ''
        };
       
    }

    componentDidMount() {
   
        axios.post(get_homecontent)
        .then(Response => {
      
        this.setState( {
        data:Response.data.data[0].pranee
        } );
        
        })
        .catch(err => {
        console.log(err);
        })
        
        
            }
        

    render(){
        return(
            <div className="bg">
                <br></br>
               <Container>
               <div >  <marquee  direction="right" behavior="alternate"><b><p style={{color:"maroon"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
               </Container>
         
   <div className="divbg"><br></br> <p style={{color:"white",textAlign:"center",fontSize:"50px"}}>प्रnee</p>
           
            <p style={{color:"white",textAlign:"center",fontSize:"20px",fontStyle:"italic"}}>“A few people of integrity can go a long way.”</p>
            <Container>
            <p style={{color:"white",textAlign:"right",fontSize:"12px",fontStyle:"italic"}}>  Bill Kauth </p>
            </Container>
<br></br> </div>
 



<div>
    <br></br>
    <br></br>
    <Container>
    <h1><b  style={{color:"#ED2A7B"}}>प्र</b><b style={{color:"#2E3092"}}>nee</b>:</h1>
    <br></br>
 
    <article>
 
 <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div>
</article>
<br></br>
 <Row>
     <Col md={4}>
     <img src="https://i.postimg.cc/rwwwMf9w/n3.jpg" className="responsive" style={{borderRadius:"10px"}} ></img>
     </Col>
     <Col md={4}>
     <img src="https://i.postimg.cc/tCHL37Z0/inc2.png" className="responsive" ></img>
     </Col>
     <Col md={4}>
     <img src="https://i.postimg.cc/kgTzPcj9/inc3.jpg" className="responsive " ></img>
     </Col>
 </Row>
 <br></br>
 
 <Row className="justify-content-center pfont">
 <p style={{fontStyle:"italic" , color:"#2E3092"}} >"Education breeds confidence.Confidence breeds hope.Hope breeds peace."</p>
 </Row>
 <Row className="justify-content-center" >
 <p  style={{fontStyle:"italic", color:"#2E3092"}}>__ Confucius</p>
</Row>
 
<br></br>
<br></br>
<br></br>



   

    </Container>
    <NewHeader />

</div>

            </div>


        )
    }
}
export default Pranee1