import React, {  } from 'react';
import {
    Container, Col, Form,   Row, 
    Card, CardBody, CardText,  CardImg
} from 'reactstrap';


export default class AboutusHeader extends React.Component {
    constructor() {
        super();
        this.state = {
            ServiceNGO: [
                {
                    name: '• Role of girl education and our services to achieve the end',
                    id: 1,
                    url: 'Images/NGO.jpg',
                    Description:'The focal area in which we provide assistance is to empower girls through education. They receive academic help from government institutions which offer them free education or charge minimal fees. We fund their education. Education can empower girls as they become capable of skilled workers and future professionals. This acts as the perfect means to uplift their respective family status in future. They utilize the platform provided to them to stand up for their community and enhance their living conditions. '
                },
                {
                    name: '• Aid to enroll in government educational institutions',
                    id: 2,
                    url: 'Images/NGO.jpg',
                    Description:'The girls are selected on the basis of their family income which means that girls belonging to families living Below Poverty Line are selected to become the privileged one. A comprehensive sponsorship program is created to take care of higher education in the field of Medicine, Engineering, and Management courses. '
                },
              
                {
                    name: '• We invite the community to join as volunteers',
                    id: 3,
                    url: 'Images/image3.jpg',
                    Description:'Community support matters in our Endeavour. Volunteers from the society come forth to offer assistance in empowering girls. Since we do not accept aid in the form of cash, the volunteers help in the accumulation of aid in the form of a kind. The volunteers are engaged in spreading awareness about our NGO and adding quality to our services. '
                },
                {
                    name: '• We support Beti Bachao, Beti Padhao',
                    id: 4,
                    url: 'Images/image3.jpg',
                    Description:'The society can keep on harping endlessly about Beti Bachao, Beti Padhao but we in -----------NGO work tirelessly to achieve the end with impressive results. The rural areas still consider girls to be a burden and are obsessed with the idea to get them married to boys of their caste. But they need to change their mindset and recognize women as a career-oriented person. This is the biggest service which we aim to offer to the communities living below the poverty line.'
                },
                {	
                    name: '• Empowered girls can turn volunteers. ',
                    id: 5,
                    url: 'Images/image3.jpg',
                    Description:'This is a unique service offered by the girls empowered with education by our NGO. Those girls are encouraged to adopt other girl child and provide support to fund their primary education. This is seen as a great initiative which the girls can accept and further strengthens the future of other girls. '
                }
            ]

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({ ServiveValue: e.target.value });
        this.setState({ ServiceName: e.target.name });
        this.setState({ServiceDescription:e.target.Description})
    }

    handleSubmit(e) {
        e.preventDefault();
        // console.log(this.state.ServiceName + ':' + this.state.ServiveValue);
        //this.setState({[e.target.name]: e.target.value}); 
    }
    render() {
        const ServiceNGO = this.state.ServiceNGO.map((Serv) => {
            return (
                <div className="searchbg">
                    <Container>
                        <Row>
                    <Card className="cardbg" key={Serv.id} style={{ margin: '10px', color: 'red' }}>
                        <CardBody className="CardCsss" style={{ backgroundColor: 'blanchedalmond' }}>
                            <Row >
                                <Col md={2}>
                                    <CardImg src={Serv.url} alt='NGOImage' style={{ height: '100px', width: '100px' }}></CardImg>
                                </Col>
                                <Col md={10}>
                                    <CardText className="Cardtext" style={{ paddingLeft: '10px' }}><span className="Card__title"><b></b> <b>{Serv.name}</b></span></CardText>
                                    <CardText className="Cardtext" style={{ paddingLeft: '10px' }}><span className="Card__title"><b><br></br></b> {Serv.Description}</span></CardText>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <br></br>
                    </Row>
                    </Container>
                </div>
            );
        });
        return (
            <Container className="searchbg">
                <br></br>
                <Form className="form App" onSubmit={this.handleSubmit} style={{ height: 'auto' }}>
                    <br></br>
                    <h5 style={{ textAlign: 'center', fontWeight: 'bold', color: '#045AC4' }} >NGO Organization</h5>
                    <hr color='#9f6060' style={{ height: '2px' }}></hr><br></br>
                   
                    <br></br>
                        <Container> 
                    <p>Extending aid for child education to underprivileged children is synonym with Nongovernmental organizations or NGOs in India. We aim to provide education assistance to the underprivileged girls after their completion of 10+2. This can be possible with the help of government educational institutions across the country.</p>
                   <br></br>

                   <p>------------- is engaged in offering the following services to society:</p>
                    </Container>

                    {ServiceNGO}
                    

                    <br></br>
                </Form><br></br>
            </Container>
        )
    }
}
