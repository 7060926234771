import React,{Component} from 'react';
import {} from 'reactstrap';

class DonorHome extends Component{
    render(){
        return(
            <div>
                <h2>Welcome Donor!!!!</h2>
            </div>
        )
    }
}
export default DonorHome