import React, { Component } from 'react';
import {  Nav, NavItem, Row, Col } from 'reactstrap';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { } from '@fortawesome/free-solid-svg-icons';
import { faInstagram,faFacebook,faTwitterSquare } from '@fortawesome/free-brands-svg-icons' ;

class FooterN extends Component {
  render() {
    return (
      <footer className="formbg">
      
        <Row>
          <Col md={12}>
            <Nav justified>
              <NavItem
                eventKey={1}>
              </NavItem>
              <NavItem 
                eventKey={2}
                title="Item">
               <b style={{color:'white'}}> © 2019 NGO Organization</b>| 
                {/* <Link>Staff Login  |</Link>  */}
                <Link><b style={{color:'white'}}>Privacy Policy</b></Link>
                     </NavItem>
              <NavItem
                eventKey={3}>
               <Link> Some other professional link</Link>
                      </NavItem>
            </Nav>
          </Col>
        </Row>
       
      </footer>
    )
  }
}
export default FooterN; 