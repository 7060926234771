import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
class news extends Component {
    render(){
        return(
            <Container>
            <div className="bgfund">
            <br></br>
<h4 style={{textAlign:"center",fontStyle:"italic"}}> <u>Glimpse of our volunteers</u></h4>
          <Container>
          <br></br>
               <Row >
                   <Col md={4} style={{textAlign:"center"}}>
                   <a href="./image/c1.jpg" style={{ color:'maroon', textDecoration:"none"}} ><p style={{textAlign:"center"}}><b>Study material</b></p><p>  <img style= {{borderRadius:"5px"}} src="./Image/c1.jpg" className="imge"></img></p></a>
                   
                   </Col>
                   <Col md={4} style={{textAlign:"center"}}>
                   <a href="./image/c2.jpg" style={{color:'maroon', textDecoration:"none"}} ><p style={{textAlign:"center"}}><b>Clothes</b></p><p>  <img style= {{borderRadius:"5px"}} src="./Image/c2.jpg" className="imge"></img></p></a>
                   
                   </Col>
                   <Col md={4} style={{textAlign:"center"}}>
                   <a href="./image/c3.jpg" style={{color:'maroon', textDecoration:"none"}}><p style={{textAlign:"center"}}><b>Food</b></p><p>  <img style= {{borderRadius:"5px"}} src="./Image/c3.jpg" className="imge"></img></p></a>
                   
                   </Col>
               </Row>
               </Container>
               <br></br>
          


            </div>
            <br></br>
            </Container>
            
        )
    }
}export default news