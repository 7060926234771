import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import { Container, Row, Col, Form,Card,CardBody, FormGroup, Label, Input, Button } from 'reactstrap';
import {Link,Route,Redirect} from 'react-router-dom';
import axios from 'axios';
import { baseurl } from './BaseUrl';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/assets/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faMobileAlt, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
const findAllitemUrl = baseurl + 'findAllitem';
const form1sub = baseurl + 'donor_order';


//const defaultOption = 'Please Select';
class DnrDetailsForm extends Component {
constructor(props) {
  super(props);
  this.state = {
       Rows: [],
  arr_doner:[],
      Itemname:"",
      Quantity:[],
      Unit:[],
 isLogged:"",
 defaultOption:[],
 defaultOption1:"Please Select",
 selectedDropdownArray: [],
  dropdownArray: ['select','1','2','3','4','5']
     

     
  }
  this.addRow();
  this.createMessage=this.createMessage.bind(this);
  this.handleFirstForm = this.handleFirstForm.bind(this);
  let is_login = sessionStorage.getItem("logged");
if(is_login === 'none') {
this.props.history.push('/');
}

}
handleFirstForm(e) {
    e.preventDefault();
   
    console.log("1st form submitted");
//alert(sessionStorage.getItem("user_id"));
    let form1Obj = {
      unit:this.state.Unit,
        quantity: this.state.Quantity,
        item: this.state.defaultOption,
        unit:this.state.Unit,
        date: e.target.date.value,
        // location: e.target.location.value,
        location: this.state.address,
user_id:sessionStorage.getItem("user_id"),
       
    }

//alert(JSON.stringify(form1Obj));
    axios.post(form1sub, form1Obj)
        .then(response => {
           // alert(JSON.stringify(response.status));
            if (response.status === 400) {
                alert("Some thing went worng! Please Try Again");
               
            }
            else if (response.status === 200) {
                //set userid for further use into localstorage
               // let reguserId = response.data._id;
                //localStorage.setItem("reguserId", reguserId)
                alert("Data Submitted Successfully")
this.props.history.push('/DonorDashboard')
               
            }
        })
}


createMessage = event => {
  event.preventDefault()

}


handleQuantityChange = event => {
this.state.Quantity.push(event.target.value);
  //this.setState({
   // Quantity: event.target.value
 // })
 // alert(this.Quantity);
}
handleitemChange = event => {
//alert(JSON.stringify(event));
this.state.defaultOption.push(event.value);
 // this.setState({
   // defaultOption: event.value
 // })
}
handleunitChange = event => {
  this.state.Unit.push(event.target.value);
    //this.setState({
     // Quantity: event.target.value
   // })
   // alert(this.Quantity);
  }


handleAvailabledateChange = event => {
  this.setState({
    Availabledate: event.target.value
  })
}

// handleUnitChange = event => {
//   this.setState({
//     Unit: event.target.value
//   })
// }


componentDidMount() {
        //console.log(this.props.id);//Selected Service Id
        axios.get(findAllitemUrl)
            .then(Response => {
                console.log(Response)
let filtered = Response.data.filter(item => {
                    return item.Service === this.props.id
                })
                console.log(filtered);
filtered.map(item => {
                    Object.assign(item, { showDescription: false })
                })
                console.log(filtered);
this.setState({
                    rowsss: filtered
                })
for(var i =0;i<this.state.rowsss.length;i++){
this.state.arr_doner.push(this.state.rowsss[i].Itemname);
}
//alert(JSON.stringify(this.state.arr_doner))
               
            })
            .catch(err => {
                console.log(err);
            })
}  


addRow = () => {
var rows = this.state.Rows
rows.push(  
<Container> <Row >
                 <Col md={4}>
                  <FormGroup>
                  <Label>
                                   <b>Item</b>
                                    </Label>       
                                   
<Dropdown name="item" options={this.state.arr_doner} onChange={this.handleitemChange}  placeholder="Select" /> </FormGroup>

                  </Col>

                  <Col md={4}>
                 
                  <FormGroup>
                                    <Label>
                                       <b> Unit</b>
                                      </Label>
                                      <Input type="select"  onChange={this.handleunitChange}>
                                        <option>Please select</option>
                                        <option>Kg</option>
                                        <option>Number</option>
                                       

                                    </Input>
                                   </FormGroup>     
                  </Col>


                  <Col md={4}>
                  <FormGroup>
                  <Label>
                                   <b>Quantity</b>
                                    </Label>
                       <Input type="text" name="Quantity" onBlur={this.handleQuantityChange}
                                   
                                      className="form-control"
                                     placeholder="Quantity"></Input>
                                   </FormGroup>  
                  </Col>
             

                    </Row></Container>)
this.setState({rows: rows})
}
deleteRow = () => {
var rows = this.state.Rows
rows.pop()
this.setState({rows: rows})
}

    render() {

//main contains

    return (
      
<div className="wasteimg" >
  
<Container >
<Form key={1} onSubmit={this.handleFirstForm}>
<Row className="justify-content-center"  >
<Col lg="10" md="8">
 <div style={{paddingTop:"60px",paddingBottom:"60px"}}>
<Card className=" shadow border-0 " style={{backgroundColor:"white"}}>
<CardBody  className="px-lg-5 py-lg-4">
<Row className="justify-content-end mt-0">
                                        <Link to="/DonorDashboard" style={{ color: "white", textDecoration: "none" }}>
                                            <FontAwesomeIcon style={{ color: "red", fontSize: "23px" }} icon={faTimesCircle} ></FontAwesomeIcon> </Link>
                                    </Row>
<div style={{textAlign:"center"}}>
{/* <img style={{height:"50px",width:"60px",borderRadius:"50px", backgroundColor:"white"}} src="../Image/logo.png"></img> */}


<img style={{height:"45px",width:"65px"}} src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img>
<br></br><br></br>
<h3 className=" text-muted"><u>My Contribution</u></h3>
</div>
<br></br>

<div>
  
{this.state.Rows.map((r) => (
 <div>
  
   {r}
  
  
  
   
   </div>
))}
 <Row> 
   
    <Col xs="4" md={10}></Col>
    <Col xs={8} md={2} >
<Button color="success" style={{width:"50px",maxWidth:"100%"}}   onClick={this.addRow}><b>+</b></Button>


&nbsp;&nbsp;

                 
<Button color="danger" style={{width:"50px", maxWidth:"100%"}}   onClick={this.deleteRow}><b>-</b></Button>
</Col>
</Row>  
</div>

<br></br>
<Row >
                                <Col md={6} style={{paddingLeft:"25px",paddingRight:"35px"}}>
                                  <FormGroup>
                            <Label for="AvailableDate" >
                                <b>  Available Date:</b></Label>
                         
                            <Input  style={{height:"43px",maxHeight:"auto"}} type="date"   className="form-control" placeholder="" name="date"></Input>
                            </FormGroup>
                            </Col>

                            <Col md={6} style={{paddingLeft:"25px"}}>
                            <FormGroup>
                            <Label for="Location" >
                                <b> Address</b></Label>
                         
                            
                                                        <GooglePlacesAutocomplete  onSelect={({ description }) => (
                  this.setState({ address: description })
                )}
    renderSuggestions={(active, suggestions, onSelectSuggestion) => (
      <div className="suggestions-container">
        {
          suggestions.map((suggestion) => (
            <div
              className="suggestion"
              onClick={(event) => onSelectSuggestion(suggestion, event)}
            >
              {suggestion.description}
            </div>
          ))
        }
      </div>
    )}
  />
                            </FormGroup>

                                                        
                                                        
                                                        
                                                        
                            </Col>
<Col md={3}></Col>
                        </Row>   

                        <br></br> 
              <Row className="justify-content-center">
                                   
                      <Button style={{width:"150px",maxWidth:"100%"}} type="submit" color="info">Submit</Button>
                      &nbsp;  &nbsp; 
                      {/* <Button style={{width:"150px",maxWidth:"100%"}} type="submit" color="info">
                        <Link  to="/DonorDashboard" style={{color:"white",textDecoration:"none"}}>Back</Link>
                        

                        
                        
                        </Button> */}
                               </Row>
              <br></br>
</CardBody>



</Card>
</div>
</Col>
</Row>
</Form>
</Container>
<NewHeader />
</div>

  );
     }
}
export default DnrDetailsForm