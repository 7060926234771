//import React from 'react';
import React, { Components } from 'react';
import './App.css';
import ScrollUpButton  from "react-scroll-up-button";
import Home from './Components/Home';
import { BrowserRouter as Switch,  Route } from "react-router-dom";
import NewHeader from './Components/NewHeader';
import OrgHeader from './Components/OrgHeader';
import AdminSignup from './Components/AdminSignup';
import AboutusHeader from './Components/AboutUsHeader';
import NgoFooter from './Components/NgoFooter';
import AdminLogin from './Components/AdminLogin';
import SignUp from './Components/SignUp';
import HomeBeneficiary from './Components/HomeBeneficiary';
import DonorHome from './Components/DonorHome';
import HomeLogistic from './Components/HomeLogistic';
import Contact from './Components/Contact';
import FooterN from './Components/FooterN';
// import DasboardAdmin from './Components/DasboardAdmin';
import DonorSign from './Components/DonorSign';
import DonorDetailsForm from './Components/DonorDetailsForm';
import ListingDonor from './Components/ListingDonors';
import RecMessage from './Components/RecMessage';
import ContactHelpless from './Components/contactHelpless';
import FormBeneficiarysign from './Components/FormBeneficiarySign';
import visionMission from './Components/VisionMisson';
import ListingBeneficiary from './Components/ListBeneficiary';
import DonorDashboard from './Components/DonorDashboard';
import BeneficiaryDonorView from './Components/BeneficiaryDonorView';
import ViewTotalAmount from './Components/ViewTotalAmount';
import LogisticDashboard from './Components/LogisticDashboard';
import ViewDonorItem from './Components/ViewDonorItem';
import LogisticReceivebyDonor from './Components/LogisticReceivebyDonor';
import LogisticForm from './Components/LogisticForm';
import PendingDonorDetails from './Components/PendingDonorDetails';
import PickingDetails from './Components/PickingDetails';
import Gallery from './Components/Gallery';
import PreviewDetails from './Components/PreviewBeneficiary';
import Logistic from './Components/LogisticDashboard';
import Award from './Components/Awards';
import Vision from'./Components/Vision';
import AidImages from './Components/AidImages';
import Services from './Components/Services';
import Overview from './Components/Overview';
import Reports from './Components/Reports';
import Consultancy from './Components/Consultancy';
import Education from './Components/Education';
import HealtData from './Components/Health';
import WomanEmpowerment from './Components/WomanEmpowerment';
import Livelihood from './Components/Livelihood';
import Volunteer from './Components/Volunteer';
import Funding from './Components/Funding';
import Pranee1 from './Components/Pranee';
import Aim from'./Components/Aim';
import DonorLogin from './Components/DonorLogin';

import RagmanLogin from './Components/RagmanLogin';
import BeneficiaryLogin from './Components/BeneficiaryLogin';
import MasterDonorForm from './Components/MasterDonationItems';
import viewRagman from './Components/viewRagman';
import DealerSignup from './Components/DealerSignup';
import DealerLogin from './Components/DealerLogin';
import DealerDashboard from './Components/DealerDashboard';
import BeneficiaryView from './Components/BeneficiaryView';
import RateCard from './Components/RateCard';
import Environment from './Components/Environment';
import Beneficiaries from'./Components/Beneficiaries';
import Mentor from './Components/Mentor';
import SudhirProfile from './Components/SudhirProfile';
import GargiProfile from './Components/GargiProfile';
import RWA from './Components/RWA';
import Corporate from './Components/Corporate';
import GOI from './Components/GOI';
import OtherNgo from './Components/Ngo';
import OurTeam from './Components/OurTeam';
import ScrollToTop from './Components/ScrollToTop';
import DashboardAdmin from './Components/DashboardAdmin';
import MasterUserType from'./Components/MasterUserType';
import VisitorView from './Components/VisitorView';
 import donerView from './Components/donerView';
 import contentHome from './Components/contentHome';
 import contentPranee from './Components/contentPranee';
 import contentVision from './Components/contentVision';
 import contentTeam from './Components/contentOurTeam';
 import contentWomen from './Components/ContentWomen';
 import contentEnvironment from './Components/ContentEnvironment';
 import contentCounselling from './Components/CMScounselling';
 import contentfunding from './Components/CMSfunding';
 import Faq from './Components/Faq';
 import RSN from './Components/RSN'; 
 import HostBeneficiary from './Components/HostBeneficiary';
 import SelectionProcess from './Components/SelectionProcess';
 import DonorProfile from './Components/DonorProfile';
 import VendorProfile from './Components/VendorProfile';
 import FullNews from './Components/FullNews';

//  import news from './Components/news';

 
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        Login: "none",
        RagmanLogin: false,
        DonorLogin: false
    }
    this.handleDonorLogin = this.handleDonorLogin.bind(this);
    // this.handleRagmanLogin = this.handleRagmanLogin.bind(this);
}

componentDidMount() {
  console.log("Master Component is mounted");
  let logged = sessionStorage.getItem('logged');
  console.log(logged);
  if (logged === undefined) {
      console.log("no one is logged")
      this.setState({
          Login: "none"
      })
  }
  else if (logged === "res") {
      this.setState({
          Login: "res"
      })
  }
  else if (logged === "user") {
      this.setState({
          Login: "user"
      })
  }
}


handleDonorLogin() {
    sessionStorage.setItem("logged", "res")
    this.setState({
      DonorLogin: true,
        Login: "res"
    })
}

// handleRagmanLogin() {
//   sessionStorage.setItem("logged", "user")
//   this.setState({
//     DonorLogin: true,
//       Login: "user"
//   })
// }
 
 
render(){
  return (

    <Switch>

      

      {/* <OrgHeader /> */}
      <div style={{marginTop:"80px"}}> 
      <Route component={ScrollToTop} />
      <Route exact path="/" component={Home} />
      <Route exact path="/card" component={AboutusHeader} />
      <Route exact path="/AdminSignup" component={AdminSignup} />
      <Route exact path="/AdminLogin" component={AdminLogin} />
      <Route exact path="/SignUp" component={SignUp} />
      <Route exact path="/HomeBeneficiary" component={HomeBeneficiary} />
      <Route exact path="/Dono" component={DonorHome} />
      <Route exact path="/Logistic" component={HomeLogistic} />
      <Route exact path="/Contact" component={Contact} />
      <Route exact path="/DashboardAdmin" component={DashboardAdmin} />
      <Route exact path="/DonorSign" component={DonorSign} />
      <Route exact path="/DonorDetailsForm" component={DonorDetailsForm} />
      <Route exact path="/ListingDonor" component={ListingDonor} />
      <Route exact path="/RecMessage" component={RecMessage} />
      <Route exact path="/ContactHelpless" component={ContactHelpless} />
      <Route exact path="/FormBeneficiarysign" component={FormBeneficiarysign} />
      <Route exact path="/visionMission" component={visionMission} />
      <Route exact path="/ListingBeneficiary" component={ListingBeneficiary} />
      <Route exact path="/DonorDashboard" component={DonorDashboard} />

      <Route exact path="/Beneficiaries" component={Beneficiaries}/>
      <Route exact path="/Environment" component={Environment}></Route>
      {/* <Route path="/" component = {Home}>
           <Redriect from='/blog/' to="/tutorials/" />
           <Route path="/tutorials/" component={About} /> */}



      <Route exact path="/BeneficiaryDonorView" component={BeneficiaryDonorView} />
      <Route exact path="/ViewTotalAmount" component={ViewTotalAmount} />
      <Route exact path="/LogisticDashboard" component={LogisticDashboard} />
      <Route exact path="/ViewDonorItem" component={ViewDonorItem}/>
      <Route exact path="/LogisticReceivebyDonor" component={LogisticReceivebyDonor}/>
      <Route exact path="/LogisticForm" component={LogisticForm}/>
      <Route exact path="/PendingDonorDetails" component={PendingDonorDetails}/>
      <Route exact path="/PickingDetails" component={PickingDetails}/>
      <Route exact path="/Gallery" component={Gallery}/>
      <Route exact path="/PreviewDetails" component={PreviewDetails}/>
      <Route exact path="/Logistic" component={Logistic}/>
      <Route exact path="/Overview" component={Overview}></Route>
      <Route exact path="/Award" component={Award}></Route>
      <Route exact path="/AidImages" component={AidImages} ></ Route>
      <Route exact path="/Services" component={Services}></Route>
      <Route exact path="/Vision" component={Vision}></Route>
      <Route exact path="/Consultancy" component={Consultancy}></Route>
      <Route exact path="/Pranee1" component={Pranee1}></Route>
      <Route exact path="/Funding" component={Funding}></Route>
      <Route exact path="/Aim" component={Aim}></Route>
      <Route exact path="/Reports" component={Reports}></Route>
      <Route exact path="/Education" component={Education}></Route>
      <Route exact path="/HealtData" component={HealtData}></Route>
      <Route exact path="/WomanEmpowerment" component={WomanEmpowerment}></Route>
      <Route exact path="/Livelihood" component={Livelihood}></Route>
      <Route exact path="/Volunteer" component={Volunteer}></Route>
      {/* <Route path="/DonorLogin" component={DonorLogin}></Route> */}
      {/* <Route path="/RagmanLogin" component={RagmanLogin}></Route> */}
     
      {/* <Route path="/login" render={(props) => <Login {...props} userlog={() => this.handleUserLogin()} />} /> */}
      <Route exact path="/DonorLogin" render={(props) => <DonorLogin {...props} something={() => this.handleDonorLogin()} />} />
      <Route exact path="/RagmanLogin" render={(props) => <RagmanLogin {...props} userlog={() => this.handleRagmanLogin()} />} />
      <Route exact path="/BeneficiaryLogin" render={(props) => <BeneficiaryLogin {...props} userlog={() => this.handleBeneficiaryLogin()} />} />
      <Route exact path="/MasterDonorForm" component={MasterDonorForm}></Route>
      <Route exact path="/viewRagman" component={viewRagman}></Route>
      <Route exact path="/DealerSignup" component={DealerSignup}></Route>
      <Route exact path="/DealerLogin" component={DealerLogin}></Route>
      <Route exact path="/DealerDashboard" component={DealerDashboard}></Route>
      <Route exact path="/BeneficiaryView" component={BeneficiaryView}></Route>
      <Route exact path="/RateCard" component={RateCard}></Route>
      <Route exact path="/Mentor" component={Mentor} />
      <Route exact path="/SudhirProfile" component={SudhirProfile} />
      <Route exact path="/GargiProfile" component={GargiProfile} />
      <Route exact path="/RWA" component={RWA} />
      <Route exact path="/Corporate" component={Corporate} />
{/* <Route exact path="/DashboardAdmin" component={DashboardAdmin}></Route> */}
      <Route exact path="/GOI" component={GOI} />
      <Route exact path="/OtherNgo" component={OtherNgo} />
      <Route exact path="/OurTeam" component={OurTeam} />
      <Route path="/MasterUserType" component={MasterUserType}></Route>
      <Route path="/VisitorView" component={VisitorView}></Route>
       <Route path="/donerView" component={donerView}></Route> 
       <Route path="/contentHome" component={contentHome}></Route>
       <Route path="/contentPranee" component={contentPranee}></Route>
       <Route path="/contentVision" component={contentVision}></Route>
       <Route path="/contentTeam" component={contentTeam}></Route>
       <Route path="/contentWomen" component={contentWomen}></Route>
       <Route path="/contentEnvironment" component={contentEnvironment}></Route>
       <Route path="/contentCounselling" component={contentCounselling}></Route>
       <Route path="/contentfunding" component={contentfunding}></Route>
       <Route exact path="/Faq" component={Faq} />
       <Route exact path="/RSN" component={RSN} />
       <Route exact path="/HostBeneficiary" component={HostBeneficiary} />
       <Route exact path="/SelectionProcess" component={SelectionProcess} />
       {/* <Route exact path="/news" component={news}/> */}
       <Route exact path="/DonorProfile" component={DonorProfile}></Route>
       <Route exact path="/VendorProfile" component={VendorProfile}></Route>
       <Route exact path="/FullNews" component={FullNews}></Route>

       
      <ScrollUpButton  style={{backgroundColor:"#2890E9",width:"40px",height:"40px"}}  />
      <NgoFooter />
      </div>
      {/* <Footer /> */}
    </Switch>

  )
}
}

export default App;
