import React, { Component } from 'react';
import {Container} from 'reactstrap';
class Reports extends Component {
    render() {
        return (
            <div className="bgPage">
                <div>
                    <img src="./image/Reports.png" alt="Award" className="img" width='1400px' height="200px"/ >
                                 
                </div>
                <br />
                <br />
                <Container>
                    <h3>Milestones/Recognitions:</h3>
                    <p>Feb’18: AIMA (All India Management Association) Award to Anshu Gupta</p>
                    <p> Dec’17: Ngo-organisation’s disaster relief and rehabilitation effort received iF Social Impact Prize</p>
                    <p>July’17:Curry Stone Design Prize recognized Anshu Gupta , Founder Ngo as the Social Design Circle honoree</p>

                    <br></br>
                    <h3>Awards :</h3>
                    <p>July’07: Vastra Samman recognized as one of The Good Practices by Dubai International Awards.</p>
                    <p>2004: Prestigious Ashoka Fellowship to Anshu Gupta for his innovative idea & its mass social impact.</p>
                </Container>



            </div>
        )
    }
}
export default Reports;