import React, { Component } from 'react';
import {Label } from 'reactstrap';

class HomeBeneficiary extends Component{
    render(){
return(
    <div>
        <Label>Home</Label>
    <h4>Admin Home Page Beneficiary</h4>
    </div>
)
    }
}
export default HomeBeneficiary;