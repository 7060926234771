import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import {Container,Row,Col,Form,Button,FormGroup,Label,Input} from 'reactstrap';

import { baseurl } from './BaseUrl';
import axios from 'axios';
const postalCode = baseurl + 'postalcode';

const donorprofiles = baseurl + 'Dealerprofilee';
const form3sub = baseurl + 'DealerUpdatebyId';


class VendorProfile extends Component{
    constructor(props) {
        super(props);
      
this.state = {
            name: '',
            email: '',
            pincode: '',
            city:'',
            state:'',
            address:'',
        };
        this.handleNameChange = this.handleNameChange.bind(this); 
        this.handleemailChange = this.handleemailChange.bind(this);
        this.handlepincodeChange = this.handlepincodeChange.bind(this);
        this.handlecityChange = this.handlecityChange.bind(this); 
        this.handlestateChange = this.handlestateChange.bind(this);
        this.handleaddressChange = this.handleaddressChange.bind(this);
        
        

    }
    handleNameChange(e) {
        this.setState({ name: e.target.value });
    }
    handleemailChange(e) {
        this.setState({ email: e.target.value });
    }
    handlepincodeChange(e) {
  if (e.target.value.length >= 7) {
      return;
  }
  const re = /^[0-9\b]+$/;
  if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ pincode: e.target.value });
      if (e.target.value.length === 6) {
          //send axios request
          console.log("send axios request");
          axios.post(postalCode, { postalCode: e.target.value })
              .then(response => {
                  // handle success
                  console.log(response);
                  let data = response.data.PostOffice[0];
                  this.setState({
                      //  city: data.District,
                      // state: data.State
                      city: data.District,
                      state: data.State
                  })
              })
              .catch(error => {
                  // handle error
                  console.log(error);
              })
              .finally(function () {
                  // always executed
              });
      }
  }
}

    handlecityChange(e) {
        this.setState({ city: e.target.value });
    }
    handlestateChange(e) {
        this.setState({ state: e.target.value });
    }
    handleaddressChange(e) {
        this.setState({ address: e.target.value });
    }
    
    
    componentDidMount() {
     let userID = sessionStorage.getItem("user_id");
    // alert(userID);
     let register = {
        userID: userID
       
       
    }
   // alert(JSON.stringify(register));
    console.log(register);
        axios.post(donorprofiles,register)
        .then(Response => {
       // alert(JSON.stringify(Response));
        //alert(JSON.stringify(Response.data.result));
        console.log(Response.data.result);
        if(Response.data.statusCode == 200)
        {
           // alert(JSON.stringify(Response.data.result[0].Name));
           // this.state.name=Response.data.result[0].Name;
            //alert(this.state.name);
            this.setState({
                name: Response.data.result[0].Name,
                email:Response.data.result[0].email,
                pincode: Response.data.result[0].pincode,
                city:Response.data.result[0].City,
                state: Response.data.result[0].State,
                address:Response.data.result[0].Location,
                

            })
        } 
        else{
            alert("data not found")
        }
        })
        .catch(err => {
        console.log(err);
        })
        
        
            }
        
            handleSubmit(e) {
                e.preventDefault();
                console.log(e.target);
                // alert(this.state.defaultOption);
              
                sessionStorage.setItem("Name", e.target.name.value);
                sessionStorage.setItem("email", e.target.email.value);
                let subObject = {
                     userID : sessionStorage.getItem("user_id"),
                 
                   Name:e.target.name.value,
                   email:e.target.email.value,
                  
                    // address: e.target.address.value,
                    pincode: e.target.pincode.value,
                    City: e.target.City.value,
                    State: e.target.State.value,
                    Location: e.target.location.value,
                   
                    
                   
                }
                //alert(JSON.stringify(subObject));
                axios.post(form3sub, subObject)

                    .then(res => {
                      // alert(JSON.stringify(res.data));
                        console.log(res.status);
                        
               if(res.data.statusCode === 200) {
                  // alert( e.target.name.value);
                //sessionStorage.setItem("Name", e.target.name.value);
                //sessionStorage.setItem("Name", e.target.name.value);
                // sessionStorage.setItem("email", e.target.email.value);
              alert("Profile updated Sucessfully!");
              
              
               
               } else {
               alert("Some thing went wrong! Please try again");
               
               }
                    })
                    .catch(err => {
                        console.log(err)
                    })
              }


    render(){
        return(
            <Container style={{ paddingBottom: '40px', height:'auto', width:'500px',align:'left' ,borderRadius:'10%' ,borderColor:'red' , borderWidth:'10px'  }}>
                
                <Form
                    
                    onSubmit={this.handleSubmit}
                    >
                    <h5 style={{ textAlign: 'center', fontWeight: 'bold', color: '#045AC4', paddingTop: '10px' }} >My Profile</h5>
                    <hr color='#045AC4' style={{ height: 2 }}></hr>
                    {/* <Row>
                        <Col md={12}>
                            <Button color="success" style={{ marginLeft: 25 }} onClick={this.toggleFormDisable}>Edit</Button>
                        </Col>
                    </Row> */}
                    <fieldset
                    // disabled={this.state.formDisabled}
                    
                    >
                        
                           
                                    <FormGroup>
                                        <Label><b>Name</b></Label>
                                        <Input type="text" name="name" placeholder="Name"
                                            onChange={this.handleNameChange}
                                            content={this.state.name} value={this.state.name}/>
                                    </FormGroup>
    
                                    <FormGroup>
                                        <Label><b>Email</b></Label>
                                        <Input type="text" name="email" placeholder="Email"
                                            onChange={this.handleemailChange}
                                            content={this.state.email} value={this.state.email}/>
                                    </FormGroup>
                              
                               
                            
                               
                                    <FormGroup>
                                        <Label><b>Pincode</b></Label>
                                        <Input type="text" name="pincode" placeholder="Pincode"
                                            onChange={this.handlepincodeChange}
                                            content={this.state.pincode} value={this.state.pincode}/>
                                    </FormGroup>
                              
                               
                                    <FormGroup>
                                        <Label><b>City</b></Label>
                                        <Input type="text" name="City" placeholder="City"
                                            onChange={this.handleHouseNoChange}
                                            content={this.state.city} value={this.state.city}/>
                                    </FormGroup>
                                

                           
                                    <FormGroup>
                                        <Label><b>State</b></Label>
                                        <Input type="text" name="State" placeholder="State"
                                            onChange={this.handlestateChange}
                                            content={this.state.state} value={this.state.state}/>
                                    </FormGroup>
                              

                           
                                    <FormGroup>
                                        <Label><b>Address</b></Label>
                                        <Input type="text" name="location" placeholder="Address"
                                            onChange={this.handleaddressChange}
                                            content={this.state.address} value={this.state.address}/>
                                    </FormGroup>
                               
                            <Row style={{ textAlign: 'center' }}>
                                <Col md={{ size: 10, offset: 1 }}>
                                    <Button type="submit" color="primary">Update</Button>&nbsp;
                                     </Col>
                            </Row>
                      
                    </fieldset>
                </Form>  <NewHeader />
            </Container>
           
        )
    }
}
export default VendorProfile