import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import {Container,Row,Col} from 'reactstrap';
import { baseurl } from './BaseUrl';
import axios from 'axios';
const get_homecontent = baseurl + 'get_homecontent6';

class Consultancy extends Component{


    constructor(props) {
        super(props);
    
    this.state = {
            data: ''
        };
       
    }

    componentDidMount() {
   
        axios.post(get_homecontent)
        .then(Response => {
        // alert(JSON.stringify(Response.data.data[0].counselling));
        this.setState( {
        data:Response.data.data[0].counselling
        } );
        
        })
        .catch(err => {
        console.log(err);
        })
        
        
            }

            

    render(){
        return(
            <div className="bg">
                <br></br>
                <Container> 
                <div>  <marquee direction="right" behavior="alternate"><b><p style={{color:"maroon"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
                </Container>
                
            <div className="divbg"><br></br> <p style={{color:"white",textAlign:"center",fontSize:"50px"}}>Counselling</p>
            <p style={{color:"white",textAlign:"center",fontSize:"20px",fontStyle:"italic"}}>Our team. Your advantage.</p>
            <br></br> 
             
             </div>

<div>
    <br></br>
    <br></br>
    <Container>
   
    <br></br>
    <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div> <br></br>



<div style={{textAlign:"center"}}> 
<p className="pfont " style={{fontStyle:"italic",  color:"#2E3092"}}><b>"The work of volunteers impacts on all our lives,
Even if we are not aware of it.”</b></p>
</div>
<br></br>
<div style={{textAlign:"center"}}> 
<img src="./Image/women.jpg" className="responsiveImg" style={{borderRadius:"30px"}} ></img>
</div >
<br></br>
<br></br>
<br></br>
    </Container>
    <NewHeader />

</div>

            </div>


        )
    }
}
export default Consultancy