import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import SliderEdu from '../Components/sliderEducation';
import SliderWoman from '../Components/sliderWoman';
import {Container,Row,Col} from 'reactstrap';
import { baseurl } from './BaseUrl';
import axios from 'axios';

const get_homecontent = baseurl + 'get_homecontent4';
export default class Education extends Component {
    constructor(props) {
        super(props);
    
    this.state = {
            data: ''
        };
       
    }
    componentDidMount() {
   
        axios.post(get_homecontent)
        .then(Response => {
      
        this.setState( {
        data:Response.data.data[0].women
        } );
        
        })
        .catch(err => {
        console.log(err);
        })
        
        
            }
        

    render() {
        return (
            <div className="bg">
               
               <br></br>
                <Container> 
                <div>  <marquee behavior="alternate" direction="right"><b><p style={{color:"maroon",fontStyle:"italic"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
                </Container>
                      
            <div className="divbg"> <br></br> <p style={{color:"white",textAlign:"center",fontSize:"50px"}}> Women Empowerment</p>
            <p style={{color:"white",textAlign:"center",fontSize:"20px",fontStyle:"italic"}}>Education's purpose is to replace an empty mind with an open one. 
            <Container> 
           
         <p style={{color:"white",textAlign:"right",fontSize:"12px",fontStyle:"italic"}}>  Malcolm Forbes </p>
         </Container>
</p> <br></br></div>

                
<div> 

    
    <br></br>
    <br></br>
    <Container>
    <Row className="justify-content-center">
    <Col md={7}> 
    <h1 style={{color:"#2E3092"}}>Empowerment:</h1>
    
    <br></br>
   
    <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div> 
    <br></br>
    </Col>
    <Col md={1}></Col>
     <Col md={4}>
     <img src="https://i.postimg.cc/5yhb8K3V/e1.jpg" className="responsive"  ></img>
     <br></br> 
     <br></br>  
     <br></br> 
     <img src="https://i.postimg.cc/63TXgxbK/e2.jpg" className="responsive" ></img>
      
    
     </Col>
 </Row>
 
 <Row className="justify-content-center">
 <Col md={4}> <img src="https://i.postimg.cc/9fkmWf70/e4.jpg" className="responsive"  ></img> </Col>
 </Row>

<br></br><br></br>
<br></br>




    </Container> <NewHeader />
    </div>      
              
            </div>
        );
    }
}  
