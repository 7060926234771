import React, { Component } from 'react';
import {Container,Row,Col,Card,CardBody} from 'reactstrap';
class Beneficiaries extends Component{
    render(){
        return(
            <div className="bgfund"> 
                <Container> 
                    <br></br>
                <div>  <marquee direction="right"><b><p style={{color:"maroon"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
                </Container>
                <div>
               <img style={{height:"180px",maxHeight:"auto",width:"100%" }} src="../Image/beneficiary4.jpg" className="responsiveImg"/> 
               </div>
               <br></br> <br></br>
               <Container>
                   <Row>
                       <Col md={12}>
                   <Card className=" shadow border-0 " style={{backgroundColor:"white"}}>
                   <CardBody  className="px-lg-5 py-lg-5">
                       <Row> 
                       <Col md={6}>
                           <Card className=" shadow border-0 " style={{backgroundColor:"white"}}> 
                               <CardBody className="px-lg-3 py-lg-3">
                             
               <img  src="../Image/gs5.jpeg" className="zoom"/> 
             
                               </CardBody>
                           </Card>
                       </Col>
                       <Col md={6}>
                       <Card className=" shadow border-0 " style={{backgroundColor:"white"}}> 
                               <CardBody className="px-lg-3 py-lg-3">
                             
                  
                               <div style={{height:"350px",maxheight:"auto"}}>         
                  <p className="pfont text-justify text-muted">Every individual is at once the beneficiary and the victim of the linguistic tradition into which he has been born - the beneficiary inasmuch as language gives access to the accumulated records of other people's experience, the victim in so far as it confirms him in the belief that reduced awareness is the only awareness and as it bedevils his sense of reality, so that he is all too apt to take his concepts for data, his words for actual things.</p>
 <p style={{fontStyle:"italic" ,fontWeight:"bold"}}>____Aldous Huxley </p>
 </div>
                               </CardBody>
                           </Card>
                       </Col>
                       </Row>
                       <br></br><br></br>
                       <Row>
                         <Col md={6}>
                         <Card className=" shadow border-0 " style={{backgroundColor:"white"}}> 
                               <CardBody className="px-lg-3 py-lg-3">
                             
                      <div style={{height:"350px",maxheight:"auto"}}>         
                  <p className="pfont text-justify text-muted">Every individual is at once the beneficiary and the victim of the linguistic tradition into which he has been born - the beneficiary inasmuch as language gives access to the accumulated records of other people's experience, the victim in so far as it confirms him in the belief that reduced awareness is the only awareness and as it bedevils his sense of reality, so that he is all too apt to take his concepts for data, his words for actual things.</p>
 <p style={{fontStyle:"italic" ,fontWeight:"bold"}}>____Aldous Huxley </p>
 </div>

             
                               </CardBody>
                           </Card>
                           </Col>  
                           <Col md={6}>
                         <Card className=" shadow border-0 " style={{backgroundColor:"white"}}> 
                               <CardBody className="px-lg-3 py-lg-3">
                             
               <img  src="../Image/gs7.jpg"  className="zoom"/> 
             
                               </CardBody>
                           </Card>
                           </Col>  
                       </Row>
                       <br></br>
                       
                         
                     
                       <Row>
                         <Col md={6}>
                         <Card className=" shadow border-0 " style={{backgroundColor:"white"}}> 
                               <CardBody className="px-lg-3 py-lg-3">
                             
                               <img  src="../Image/gs3.jpg" className="zoom"/> 
             
                               </CardBody>
                           </Card>
                           </Col>  
                           <Col md={6}>
                         <Card className=" shadow border-0 " style={{backgroundColor:"white"}}> 
                               <CardBody className="px-lg-3 py-lg-3">
                                     
                      <div style={{height:"350px",maxheight:"auto"}}>         
                  <p className="pfont text-justify text-muted">Every individual is at once the beneficiary and the victim of the linguistic tradition into which he has been born - the beneficiary inasmuch as language gives access to the accumulated records of other people's experience, the victim in so far as it confirms him in the belief that reduced awareness is the only awareness and as it bedevils his sense of reality, so that he is all too apt to take his concepts for data, his words for actual things.</p>
 <p style={{fontStyle:"italic" ,fontWeight:"bold"}}>____Aldous Huxley </p>
 </div>
              
             
                               </CardBody>
                           </Card>
                           </Col>  
                       </Row>
                       <br></br>  <br></br>
                       <Row> 
                       <Col md={6}>
                           <Card className=" shadow border-0 " style={{backgroundColor:"white"}}> 
                               <CardBody className="px-lg-3 py-lg-3">
                             
                       
                               <div style={{height:"350px",maxheight:"auto"}}>         
                  <p className="pfont text-justify text-muted">Every individual is at once the beneficiary and the victim of the linguistic tradition into which he has been born - the beneficiary inasmuch as language gives access to the accumulated records of other people's experience, the victim in so far as it confirms him in the belief that reduced awareness is the only awareness and as it bedevils his sense of reality, so that he is all too apt to take his concepts for data, his words for actual things.</p>
 <p style={{fontStyle:"italic" ,fontWeight:"bold"}}>____Aldous Huxley </p>
 </div>
             
                               </CardBody>
                           </Card>
                       </Col>
                       <Col md={6}>
                       <Card className=" shadow border-0 " style={{backgroundColor:"white"}}> 
                               <CardBody className="px-lg-3 py-lg-3">
                             
                               <img  src="../Image/gs4.jpg" className="zoom"/> 
             
                               </CardBody>
                           </Card>
                       </Col>
                       </Row>
                       <br></br><br></br>
                       <Row> 
                       <Col md={6}>
                           <Card className=" shadow border-0 " style={{backgroundColor:"white"}}> 
                               <CardBody className="px-lg-3 py-lg-3">
                             
               <img  src="../Image/gs6.jpg" className="zoom"/> 
             
                               </CardBody>
                           </Card>
                       </Col>
                       <Col md={6}>
                       <Card className=" shadow border-0 " style={{backgroundColor:"white"}}> 
                               <CardBody className="px-lg-3 py-lg-3">
                                     
                      <div style={{height:"350px",maxheight:"auto"}}>         
                  <p className="pfont text-justify text-muted">Every individual is at once the beneficiary and the victim of the linguistic tradition into which he has been born - the beneficiary inasmuch as language gives access to the accumulated records of other people's experience, the victim in so far as it confirms him in the belief that reduced awareness is the only awareness and as it bedevils his sense of reality, so that he is all too apt to take his concepts for data, his words for actual things.</p>
 <p style={{fontStyle:"italic" ,fontWeight:"bold"}}>____Aldous Huxley </p>
 </div>
              
             
                               </CardBody>
                           </Card>
                       </Col>
                       </Row>
                       <br></br><br></br>
                  
                   </CardBody>
                   </Card>
                   </Col>
                     
                   </Row>
               </Container>
                <br></br>
                <br></br>

                </div>  
        );
    }

} export default Beneficiaries