import React, { Component } from 'react';
import { Button, Navbar, Nav, NavDropdown, Form, Container, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
// import Mentor from './Mentor';

class NewHeader extends React.Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        //this.logout = this.logout.bind(this);
    
        this.state = {
          isOpen: false
        };
        
    
      }
    
      toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
      logout() {
    
        sessionStorage.setItem("logged", "none");
        sessionStorage.setItem("user_id","")
        //window.location.reload();
      }


    render() {

        let is_login = sessionStorage.getItem("logged");
    console.log(is_login);
    if (is_login === 'res') {
        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" className="bg-gradient-info fixed-top" variant="dark">
            <Container>
                <Navbar.Brand as={Link} to="/" >
                    <i style={{ fontSize: "25px", fontFamily: "Luminari", color: "white" }}><div className="logoDiv">
                        <img style={{ height: "40px", width: "55px", marginTop: "10px", marginLeft: "8px", marginRight: "5px" }}
                            src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img>
                    </div></i>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        {/* <Nav.Item>
                            <Nav.Link eventKey="1" as={Link} to="/BeneficiaryView" >
                            <FontAwesomeIcon style={{ color: "white" }} icon={faHistory} aria-hidden="true"></FontAwesomeIcon>
                            Beneficiary View
                            </Nav.Link>
                        </Nav.Item>  */}

                        <Nav.Item>
                            <Nav.Link eventKey="2" as={Link} to="/DonorDetailsForm" >
                            <FontAwesomeIcon style={{ color: "white" }} icon={faHandHoldingUsd} aria-hidden="true"></FontAwesomeIcon>
                            Donate Now
                            </Nav.Link>
                        </Nav.Item> 
                        
                        <Nav.Item>
                            <Nav.Link eventKey="3" as={Link} to="/DonorDashboard" >
                            <FontAwesomeIcon style={{ color: "white" }} icon={faGlobe} aria-hidden="true"></FontAwesomeIcon>
                            Dashboard
                            </Nav.Link>
                        </Nav.Item> 

                        <Nav.Item >
                        <Nav.Link onClick={this.logout} eventKey="4" as={Link}  to="/" >
                        <FontAwesomeIcon style={{ color: "white" }} icon={faArrowAltCircleLeft} aria-hidden="true"></FontAwesomeIcon>
                            
                             Logout
                            </Nav.Link>
                            
                        </Nav.Item> 

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        );

    }

    else if (is_login === 'regment') {
        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" className="bg-gradient-info fixed-top" variant="dark">
            <Container>
                <Navbar.Brand >
                    <i style={{ fontSize: "25px", fontFamily: "Luminari", color: "white" }}><div className="logoDiv">
                        <img style={{ height: "40px", width: "55px", marginTop: "10px", marginLeft: "8px", marginRight: "5px" }}
                            src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img>
                    </div></i>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                      


                        <Nav.Item >
                        <Nav.Link onClick={this.logout} eventKey="5" as={Link}  to="/" >
                        <FontAwesomeIcon style={{ color: "white" }} icon={faArrowAltCircleLeft} aria-hidden="true"></FontAwesomeIcon>
                            
                             Logout
                            </Nav.Link>
                            
                        </Nav.Item> 

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        );
    }


    else if (is_login == 'dealer') {

        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" className="bg-gradient-info fixed-top" variant="dark">
            <Container>
                <Navbar.Brand as={Link} to="/" >
                    <i style={{ fontSize: "25px", fontFamily: "Luminari", color: "white" }}><div className="logoDiv">
                        <img style={{ height: "40px", width: "55px", marginTop: "10px", marginLeft: "8px", marginRight: "5px" }}
                            src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img>
                    </div></i>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Item>
                            <Nav.Link eventKey="1" as={Link} to="/RateCard" >
                            Rate Card
                            </Nav.Link>
                        </Nav.Item> 

                       
                        <Nav.Item>
                            <Nav.Link eventKey="2" as={Link} to="/DealerDashboard"  >
                            <FontAwesomeIcon style={{ color: "white" }} icon={faGlobe} aria-hidden="true"></FontAwesomeIcon>
                            Dashboard
                            </Nav.Link>
                        </Nav.Item> 

                        <Nav.Item >
                        <Nav.Link onClick={this.logout} eventKey="3" as={Link}  to="/" >
                        <FontAwesomeIcon style={{ color: "white" }} icon={faArrowAltCircleLeft} aria-hidden="true"></FontAwesomeIcon>
                            
                             Logout
                            </Nav.Link>
                            
                        </Nav.Item> 

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

          
        )
      }


 else {
        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" className="bg-gradient-info fixed-top" variant="dark">
            <Container>
                <Navbar.Brand as={Link} to="/" >
                    <i style={{ fontSize: "25px", fontFamily: "Luminari", color: "white" }}><div className="logoDiv">
                        <img style={{ height: "40px", width: "55px", marginTop: "10px", marginLeft: "8px", marginRight: "5px" }}
                            src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img>
                    </div></i>
                </Navbar.Brand>
              
              
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Item>
                            <Nav.Link eventKey="1" as={Link} to="/" >
                            {/* <FontAwesomeIcon style={{color:"white"}} icon={faHome}  aria-hidden="true"></FontAwesomeIcon>
                             */}
                              Home
                    </Nav.Link>
                        </Nav.Item> 
                       <Nav.Item > 
                            
                            <NavDropdown  title="About" id="basic-nav-dropdown">
                                <NavDropdown.Item  eventKey="1" as={Link} to="/Pranee1"> प्रnee</NavDropdown.Item>
                                <NavDropdown.Item  eventKey="4" as={Link} to="/RSN">RSNB Foundation</NavDropdown.Item>
                                <NavDropdown.Item  eventKey="2" as={Link} to="/Vision">Vision</NavDropdown.Item>
                                <NavDropdown.Item  eventKey="3" as={Link} to="/OurTeam">Our Team</NavDropdown.Item>
                             
                            </NavDropdown> 
                        </Nav.Item> 
                        <Nav.Item>
                            <NavDropdown   title="Leadership" id="basic-nav-dropdown">
                              
                                <NavDropdown.Item eventKey="2" as={Link} to="/GargiProfile">Gargi Bahuguna</NavDropdown.Item>
                                <NavDropdown.Item eventKey="3" as={Link} to="/SudhirProfile">Sudhir Bahuguna</NavDropdown.Item>

                            </NavDropdown>
                        </Nav.Item>

                        <Nav.Item>
                            <NavDropdown title="Activities" id="basic-nav-dropdown">
                                <NavDropdown.Item eventKey="1" as={Link} to="/Consultancy">Counselling</NavDropdown.Item>
                                <NavDropdown.Item eventKey="2" as={Link} to="/Funding">Funding</NavDropdown.Item>
                                <NavDropdown.Item eventKey="3" as={Link} to="/Gallery">Gallery</NavDropdown.Item>
                                <NavDropdown.Item eventKey="4" as={Link} to="/Faq">FAQ</NavDropdown.Item>
                            </NavDropdown>
                        </Nav.Item> 


                        <Nav.Item>
                            <NavDropdown title="Impact" id="basic-nav-dropdown">
                                <NavDropdown.Item eventKey="1" as={Link} to="/Education">Women Empowerment</NavDropdown.Item>
                                <NavDropdown.Item eventKey="2" as={Link} to="/Environment">Environment</NavDropdown.Item>

                            </NavDropdown>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="2" as={Link} to="/Contact">
                            {/* <FontAwesomeIcon style={{ color: "white" }} icon={faAddressCard} aria-hidden="true"></FontAwesomeIcon> 
                             */}
                            Contact
                    </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                     
                            <NavDropdown  title="Get Involved" id="basic-nav-dropdown">
                                <NavDropdown.Item eventKey="1" as={Link} to="/RWA">RWA</NavDropdown.Item>
                                {/* <NavDropdown.Item eventKey="2" as={Link} to="/Ngo">NGO</NavDropdown.Item> */}
                                <NavDropdown.Item eventKey="3" as={Link} to="/Corporate">CSR</NavDropdown.Item>
                                <NavDropdown.Item eventKey="4" as={Link} to="/GOI">GOI</NavDropdown.Item>
                                <NavDropdown.Item eventKey="5" as={Link} to="/HostBeneficiary">Host your beneficiary</NavDropdown.Item>
                            </NavDropdown>
                          
                        </Nav.Item>

                       <Nav.Item>
                            <NavDropdown title="Join Us" id="basic-nav-dropdown">
                                <NavDropdown.Item eventKey="1" as={Link} to="/DonorSign">Donor</NavDropdown.Item>
                                <NavDropdown.Item eventKey="3" as={Link} to="/DealerSignup">Vendor</NavDropdown.Item>
                                
                                <NavDropdown.Item eventKey="4" as={Link} to="/SignUp">Ragman</NavDropdown.Item>
                                <NavDropdown.Item eventKey="2" as={Link} to="/FormBeneficiarysign">Beneficiary</NavDropdown.Item>
                               
                            </NavDropdown>
                        </Nav.Item>

                        <Nav.Item>
                            <NavDropdown title="Login" id="basic-nav-dropdown">
                                <NavDropdown.Item eventKey="1" as={Link} to="/DonorLogin">Donor</NavDropdown.Item>
                                <NavDropdown.Item eventKey="3" as={Link} to="/DealerLogin">Vendor</NavDropdown.Item>
                                <NavDropdown.Item eventKey="4" as={Link} to="/RagmanLogin">Ragman</NavDropdown.Item>
                               {/* <NavDropdown.Item eventKey="2" as={Link} to="/BeneficiaryLogin">Beneficiary</NavDropdown.Item>
                                
                                */}
                               
                            </NavDropdown>
                        </Nav.Item> 



                    </Nav>

                  

                </Navbar.Collapse>

                <Navbar.Brand as={Link} to="/" >
                    <i ><div>
                        <img style={{ height: "50px", width: "60px",  marginLeft: "8px", marginRight: "5px" }}
                            src="https://i.postimg.cc/rFWq60CW/Android-2.png"></img>
                    </div></i>
                </Navbar.Brand>

            </Container>
        </Navbar>

               
        );}
    }

}
export default NewHeader







