import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import { Row, Container, Label, Input, Col,InputGroup,InputGroupAddon,InputGroupText, Form,Card, CardBody, FormGroup, FormText, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faAddressCard,faMobileAlt,faEnvelope,faGraduationCap,faExclamationCircle ,faPhone,faTools,faSignature,faUnlock, faMobile, faCertificate, faCalendarDay, faCity, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import { faInstagram,faFacebook,faTwitterSquare } from '@fortawesome/free-brands-svg-icons' ;
import axios from 'axios';
import { baseurl } from './BaseUrl';
import { ToastContainer, toast } from 'react-toastify';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
const form1sub = baseurl + 'BResourceRegistration1/form1';
const VerifyResRegOtp = baseurl + 'BVerifyResRegOtp1';
const form2sub = baseurl + 'BResourceRegistration1/form2';
const form3sub = baseurl + 'BResourceRegistration/form3';
const findAllStateUrl = baseurl + 'findAllstate';
const postalCode = baseurl + 'postalcode';

class FormBeneficiarySign extends Component {
  constructor(props) {
    super(props);
this.myRef = React.createRef();
    this.state = {
        firstForm: true,
        secondForm: false,
        thirdForm: false,
        arr_state:[],
     Name: '',
     email: '',
     phone: '',
     phoneNumber: '',
        //State for Form 2
        form2button: true,
        regOtp: '',
        BPL:'',
        FatherName:'',
        Marks12:'',
        passingyear:'',
        pincode: '',
        City:'',
        State:'',
		State_value:'',
		defaultOption:'Select Passing Year',

     
    }

    this.handleName = this.handleName.bind(this);
    this.handleemail = this.handleemail.bind(this);
 
    this.handlePhone = this.handlePhone.bind(this);
    this.handleFirstForm = this.handleFirstForm.bind(this);
    //2nd
   

    this.handleRegOtp = this.handleRegOtp.bind(this);

    this.handleSecondForm = this.handleSecondForm.bind(this);

    this.handleBPL=this.handleBPL.bind(this);
    this.handleFatherName=this.handleFatherName.bind(this);
    this.handleMarks12=this.handleMarks12.bind(this);
    this.handlepassingyear=this.handlepassingyear.bind(this);
    this.handlePincode = this.handlePincode.bind(this);
    this.handleCity=this.handleCity.bind(this);
    this.handleState=this.handleState.bind(this);
    this.handleState_value=this.handleState_value.bind(this);

    this.handleThirdForm = this.handleThirdForm.bind(this);
   
}

   
handleName(e) {
  const re = /^[a-zA-Z]+$/;
  if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ firstName: e.target.value, fnameError: false });
  }
}
handleemail(e) {

      this.setState({ lastName: e.target.value, lnameError: false });

}
//handlePhone(e) { this.setState({ phone: e.target.value }) }

////////////////////////////////////
handlePhone(e) {
if (e.target.value.length > 10) {
  return;
}
this.setState({ phone: e.target.value, phoneError: false });
}

handleFirstForm(e) {
e.preventDefault();
if (e.target.fname.value === '') {
  this.setState({ fnameError: true })
  return;
}
if (e.target.lname.value === '') {
  this.setState({ lnameError: true })
  return;
}
if (e.target.contact.value.length < 10) {
  alert("Please Enter a valid Number");
  this.setState({ phoneError: true })
  return;
}
console.log("1st form submitted");
let form1Obj = {
  Name: e.target.fname.value,
  email: e.target.lname.value,
  phoneNumber: e.target.contact.value
}

localStorage.setItem("mobile", e.target.contact.value)
localStorage.setItem("email", e.target.lname.value)


axios.post(form1sub, form1Obj)
  .then(response => {
      console.log(response);
      if (response.data.statusCode === 400) {
          alert("User is already Registered");
          toast.error("User is already Registered", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true
          });
      }
      else if (response.status === 200) {
          //set userid for further use into localstorage
          let reguserId = response.data._id;
          localStorage.setItem("reguserId", reguserId)
          console.log("Successfully Registered")
          this.setState({
              firstForm: false,
              secondForm: true
          })
      }
  })
}

/////////////////////////////

//methods for form2
handleRegOtp(e) {
if (e.target.value.length > 6) {
  return;
}
else if (e.target.value.length === 6) {
  //confirm registration otp is wrong or right
  let phone = this.state.phone;
 
  let verifyObj = {
      phoneNumber: phone,
      otp: e.target.value
  }
  this.setState({ regOtp: e.target.value })
  axios.post(VerifyResRegOtp, verifyObj)
      .then(response => {
          console.log(response);
          if (response.data.statusCode === 200) {
              //User Verified, enable submit button
              alert("OTP Verified");
              /*
              toast("OTP Verified", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: true
              });
             */
this.setState({
          secondForm: false,
thirdForm:true
         //isLogged: true
      })
          }
          else if (response.data.statusCode === 400) {
              alert("OTP is wrong")
              toast("OTP is wrong", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: true
              });
          }
      })
}
else {
  this.setState({ regOtp: e.target.value })
}
}


////////////////////

handleSecondForm(e) {

// this.setState({
//           secondForm: false,
//           thirdForm: true,
   
//       })

console.log(e.target);

axios.post(form2sub)
  .then(res => {
      console.log(res.status);
 if(res.status === 200) {
      //handle cases based on res.status 200 or 404
 alert("successfully registered");
      this.setState({
         secondForm: false,
         thirdForm: true,
        //  isLogged: true
      })
 } else {
 alert("Some thing went worng! Please try again");
 }
  })
  .catch(err => {
      console.log(err)
  })
}





handlePincode(e) {
  if (e.target.value.length >= 7) {
      return;
  }
  const re = /^[0-9\b]+$/;
  if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ pincode: e.target.value });
      if (e.target.value.length === 6) {
          //send axios request
          console.log("send axios request");
          axios.post(postalCode, { postalCode: e.target.value })
              .then(response => {
                  // handle success
                  console.log(response);
                  let data = response.data.PostOffice[0];
                  this.setState({
                      //  city: data.District,
                      // state: data.State
                      city: data.District,
                      state: data.State
                  })
              })
              .catch(error => {
                  // handle error
                  console.log(error);
              })
              .finally(function () {
                  // always executed
              });
      }
  }
}







handleBPL(e) {
	if(e.target.value == 'No') {
		alert("You are not in BPL");
	}

	this.setState({ BPL: e.target.value }) 
}
handleFatherName(e) { this.setState({ FatherName: e.target.value }) }
handleMarks12(e) { this.setState({ Marks12: e.target.value }) }
handlepassingyear(e) { this.setState({ passingyear: e.target.value }) }

//handleCity(e) { this.setState({ City: e.target.value }) }
//handleState_value(e) { this.setState({ State_value: e.target.value }) }

// handleCity = event => {
// 	//alert(event.value);
// 	//this.state.arr_state.push(event.value);
	
// 	this.setState({
//     City: event.value
//  })
	 
// }
// handleState_value = event => {
// 	//alert(event.value);
	
	
// 	 this.setState({
//     defaultOption: event.value
	
//   })
//   //this.state.arr_state.push(event.value);
  
// }



handleCity = event => {

	
	this.setState({
    City: event.value
 })
	 
}
handleState = event => {
	//alert(event.value);
	
	
	 this.setState({
    State: event.value
	
  })
 
  
}



handleState_value = event => {
	//alert(event.value);
	
	
	 this.setState({
    defaultOption: event.value
	
  })
  //this.state.arr_state.push(event.value);
  
}


handleThirdForm(e) {
  e.preventDefault();
  console.log(e.target);
  // alert(this.state.defaultOption);
  console.log("3rd form submitted");
  let reguserId = localStorage.getItem("reguserId");
  
  let mobile = localStorage.getItem("mobile");
  let email = localStorage.getItem("email");
  
  let subObject = {
      userId: reguserId,
      BPL: e.target.BPL.value,
      FatherName: e.target.FatherName.value,
      Marks12:e.target.Marks12.value,
      //passingyear: e.target.passingyear.value,
      // City: e.target.City.value,
      // State: this.state.defaultOption,
      passingyear: this.state.defaultOption,
      pincode: e.target.pincode.value,
      City: e.target.City.value,
      State: e.target.State.value,
      mobile: mobile,
      email: email,

     
  }
  axios.post(form3sub, subObject)
      .then(res => {
          console.log(res.status);
 if(res.status === 200) {
 alert("Successfully registered");
 
          //handle cases based on res.status 200 or 404
          this.setState({
              thirdForm: false,
              forthForm: true
 
          });
  this.myRef.current.scrollTo(0, 0);
 

 

 
 
 } else {
 alert("Some thing went wrong! Please try again");
 
 }
      })
      .catch(err => {
          console.log(err)
      })
}



static defaultProps = {
center: {
  lat: 28.414648,
  lng: 77.094404
},
zoom: 15
};

onDismiss() {
  this.setState({ visible: false });
}





componentDidMount() {
  //console.log(this.props.id);//Selected Service Id
  axios.get(findAllStateUrl)
      .then(Response => {
          console.log(Response)
let filtered = Response.data.filter(item => {
              return item.Service === this.props.id
          })
          console.log(filtered);
filtered.map(item => {
              Object.assign(item, { showDescription: false })
          })
          console.log(filtered);
this.setState({
              rowsss: filtered
          })
for(var i =0;i<this.state.rowsss.length;i++){
this.state.arr_state.push(this.state.rowsss[i].StateName);
}
//alert(JSON.stringify(this.state.arr_doner))
         
      })
      .catch(err => {
          console.log(err);
      })
} 






    render() {

        return (
          <div className="bgfund">
            <Container>

<br></br><br></br>
<Row className="justify-content-center">
              <Col md="11">
              <h2 style={{fontStyle:"italic", color:"#1472B1",textAlign:"center"}}> The girls derive aid to get empowered with education. This paves the way for them to a bright future in the professional world.  </h2>
           
              </Col>
            </Row><br></br>
            <Row className="justify-content-center">
           <Link to="/SelectionProcess" style={{color:"red",fontSize:"20px"}}>  <FontAwesomeIcon  icon={faExclamationCircle } ></FontAwesomeIcon>  Beneficiary selection process </Link></Row> <br></br>
              <Row className="justify-content-center" >
             
              <Col lg="10" md="8">
              <Card className=" shadow border-0 " style={{backgroundColor:"white"}}>
<CardBody  className="px-lg-5 py-lg-5">
<div style={{textAlign:"center"}}>
              <img style={{height:"50px",width:"60px",borderRadius:"50px", backgroundColor:"white"}} src="../Image/logo.png"></img>
              {/* <h3 style={{textAlign:"center", color:"gray"}}><u> Application Form </u></h3> */}
            <h3  className=" text-muted">Register</h3>
              <p className="justify-content-center text-muted" > (Fill the application form below and submit)</p>
             </div>
              <br></br>
              {this.state.firstForm &&
                <Form key={1} onSubmit={this.handleFirstForm}>
              <FormGroup>
                <Label className="text-muted"><b> Name</b></Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                       
                        <FontAwesomeIcon icon={faGraduationCap}   aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="text" name="fname" placeholder="Name*" value={this.state.firstName} onChange={this.handleFirstName} className={this.state.fnameError ? 'error' : null} />
               
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <Label className="text-muted"><b>E-mail</b></Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                       
                        <FontAwesomeIcon icon={faEnvelope}   aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="text" name="lname" placeholder="Email*" value={this.state.lastName} onChange={this.handleLastName} className={this.state.lnameError ? 'error' : null} />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                <Label className="text-muted"><b>Mobile Number</b></Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                       
                        <FontAwesomeIcon icon={faMobile}   aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="number" name="contact" placeholder="Phone Number*" value={this.state.phone} onChange={this.handlePhone} className={this.state.phoneError ? 'error' : null} />
       
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                                       
                                       <Button className="btn btn-info" type="submit" block>Get OTP</Button>
                   
                                   </FormGroup>

                                   
                                 


</Form>}



              {this.state.secondForm &&
                                <Form key={2} onSubmit={this.handleSecondForm}>

<FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                        {/* <i className="ni ni-hat-3" /> */}
                        <FontAwesomeIcon icon={faUnlock}  aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="text" name="otp" placeholder="OTP" value={this.state.regOtp} onChange={this.handleRegOtp} required />
                     </InputGroup>
                </FormGroup>

                <div style={{textAlign:"center"}}>
                         

                          <Button type="submit" disabled={this.state.form2button}>Send</Button>
                         
                                           
                                         </div>

                </Form>}


                {this.state.thirdForm &&
                                <Form key={3} onSubmit={this.handleThirdForm}>


                <FormGroup>
                <Label className="text-muted"><b>Father Name</b></Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                       
                        <FontAwesomeIcon icon={faSignature}   aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="text" name="FatherName" placeholder="Enter Father's Name*"  value={this.state.FatherName}
                                                 onChange={this.handleFatherName}
                                               
                                                 className={this.state.fnameError ? 'error' : null} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <Label className="text-muted"><b>Percentage marks in 12th </b></Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                       
                        <FontAwesomeIcon icon={faCertificate}   aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="number" name="Marks12"  placeholder="Enter marks*"  
                                        value={this.state.Marks12}
                                                 onChange={this.handleMarks12}
                                               
                                                  />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <Label className="text-muted"><b>Passing Year </b></Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                       
                        <FontAwesomeIcon icon={faCalendarDay}   aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Dropdown name="State_ss" options={this.state.arr_state} onChange={this.handleState_value} value={this.state.defaultOption} placeholder="Select an option" />

                  </InputGroup>
                </FormGroup>







                <FormGroup>
                <Label className="text-muted"><b>Pincode </b></Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                       
                        <FontAwesomeIcon icon={faCalendarDay}   aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                   
                    <Input style={{ boxShadow: "none"}} type="text" name="pincode"
                                        value={this.state.pincode}
                                                 onChange={this.handlePincode}
                                                 placeholder="Enter pincode*"  />
                  </InputGroup>
                </FormGroup>

















                <FormGroup>
                <Label className="text-muted"><b>City </b></Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                       
                        <FontAwesomeIcon icon={faCity}   aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="text" name="City"
                                       value={this.state.city} onChange={this.handleCity}
                                                 placeholder="Enter city*" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <Label className="text-muted"><b>State </b></Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                       
                        <FontAwesomeIcon icon={faMapMarkerAlt}   aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    {/* <Input style={{ boxShadow: "none"}} type="text" name="State"
                                        value={this.state.State}
                                                 onChange={this.handleState}
                                                 placeholder="Enter state*"
                                                  /> */}



<Input style={{ boxShadow: "none"}} type="text" name="State"
                                       value={this.state.state} onChange={this.handleState}
                                                 placeholder="Enter state*" />
                                                  {/* <Dropdown name="State_ss" options={this.state.arr_state} onChange={this.handleState_value} value={this.state.defaultOption} placeholder="Select an option" />

   */}
  
  
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                <Label className="text-muted"><b>Do you have BPL Card? </b><p>( Below Poverty Line)</p> </Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                       
                        <FontAwesomeIcon icon={faCertificate}   aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="select" name="BPL"  
                                        value={this.state.BPL}
                                                 onChange={this.handleBPL} >
                                                 
                                                  <option>Yes</option>
                                                  <option>No</option>
         
                                                  </Input>
                  </InputGroup>
                </FormGroup>
                <Button type="submit">Submit</Button>
               
             

</Form>}
     
</CardBody>
              </Card>
              </Col>
           
              </Row>
             <br></br>
             <br></br>
             <br></br>
            </Container> <NewHeader />
</div>
        );
    }
}


export default FormBeneficiarySign;
