
import React, {Component} from 'react';
import NewHeader from '../Components/NewHeader';
import {Container, Col, Form, FormGroup,InputGroup,InputGroupAddon,InputGroupText, Button,Row, Label,Input,Card,CardHeader,CardBody} from 'reactstrap';
//import {Link} from 'react-router-dom';
import {Link,Route, Redirect} from 'react-router-dom';
import axios from 'axios';
import { baseurl } from './BaseUrl';
import { ToastContainer, toast } from 'react-toastify';

import MultipleSignUp from './MultipleSignUp';
const get_homecontent = baseurl + 'get_Csr';
export default class Corporate extends Component{
    constructor(props) {
        //alert("dsa");
        super(props);
        this.state = {
            data: ''}}

            componentDidMount() {
   
                axios.post(get_homecontent)
                .then(Response => {
                // alert(JSON.stringify(Response.data.data[0].funding));
                this.setState( {
                data:Response.data.data[0].csr
                } );
                
                })
                .catch(err => {
                console.log(err);
                })
                
                
                    }





    render(){
        return(
         <div className="bg">  
         {/* <div className="donorbg">  */}
         <br></br>
                <Container>
                <div>  <marquee behavior="alternate" direction="right"><b><p style={{color:"maroon",fontStyle:"italic"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
                </Container>
                     
            <div className="divbg" ><br></br> <p style={{color:"white",textAlign:"center",fontSize:"50px"}}>CSR</p>
            <p style={{color:"white",textAlign:"center",fontSize:"20px",fontStyle:"italic"}}>Corporate social responsibility
           </p> <br></br> </div>
            <Container >

            <br></br> <br></br>
            <Row className="justify-content-center">
         



            <Col md="7">
            <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div>


                </Col>  
         
         
         
         
         
            <Col  md="5">
              <br></br>
            <MultipleSignUp/>
            </Col>  
         
       </Row>
            <br></br> <br></br><br></br>
            </Container> <NewHeader />
        </div>
        )
    }
}
//export default DonorSign






