import React, { Component } from 'react'
import Chart from "react-google-charts";
import {
    UncontrolledTooltip, ListGroupItem, ListGroup, Badge,
    Alert, Container, Row, Col, Button, CardBody, Card, CardHeader, CardSubtitle
} from 'reactstrap';
import {
    faAddressCard, faMobileAlt, faEnvelope, faEdit, faUser,
    faSignature, faMobile
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleMapReact from 'google-map-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { baseurl } from './BaseUrl';
const findAllitemUrl = baseurl + 'listofdoner';
const listofbeneficiary = baseurl + 'listofbeneficiary';

const Marker = props => {
    return <div><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></div>
}

class DashboardAdmin extends Component {
    static defaultProps = {
        center: {
            lat: 28.414648,
            lng: 77.094404
        },
        zoom: 15
    };
    constructor(props) {
        super(props);

        this.state = {
            visible: true

        };
        this.state = {
            doner_count: 0
        };

        this.state = {
            ben_count:0
                    };
            

        let is_login = sessionStorage.getItem("logged");
        if (is_login === 'none') {
            this.props.history.push('/');
        }


        this.onDismiss = this.onDismiss.bind(this);

    }

    onDismiss() {
        this.setState({ visible: false });
    }
    // componentDidMount() {
    //     //alert("ff");
    //     axios.post(findAllitemUrl)
    //         .then(Response => {
    //             //alert(JSON.stringify(Response.data.data.length));
    //             this.setState({ doner_count: Response.data.data.length });
    //             //this.setState({Rows:Response.data.data.length})

    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }

    componentDidMount() {
        //alert("ff");
    axios.post(findAllitemUrl)
    .then(Response => {
     //alert(JSON.stringify(Response.data.data.length));
     this.setState({doner_count:Response.data.data.length});
    //this.setState({Rows:Response.data.data.length})
    
    })
    .catch(err => {
    console.log(err);
    });
    
    axios.post(listofbeneficiary)
    .then(Response => {
     //alert(JSON.stringify(Response.data.data.length));
     this.setState({ben_count:Response.data.length});
    //this.setState({Rows:Response.data.data.length})
    
    })
    .catch(err => {
    console.log(err);
    });
    }


    render() {
        return (
            <div className="bgfund" style={{ padding: "30px" }}>
               
                <Container className="divbg">
                    <br></br>
<Row className="justify-content-center"><h2  style={{color:"white"}}><u>Admin Dashboard </u></h2> </Row>
                    {/* <h2>Admin Dashboard:</h2> */}
                    <div style={{ padding: '10px' }}>
                        <Row style={{ padding: '10px' }} >
                            <Col md={4} sm={12}>
                                <Row>
                                    <Col md={12}>
                                        <Card className=" shadow border-0 " style={{ paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
                                            <CardBody className="px-lg-3 py-lg-3">

                                                <Row>
                                                    <Col md={10}>
                                                        <h4 className="text-muted" style={{ textAlign: "center" }}> <FontAwesomeIcon style={{ color: "#2E3092" }} icon={faUser} aria-hidden="true" style={{ color: "darkblue", fontSize: "23px" }}></FontAwesomeIcon> My Profile</h4> </Col>
                                                    <Col md={2}><FontAwesomeIcon icon={faEdit} style={{ color: "#2E3092" }} /></Col>
                                                </Row>
                                                <br></br>  <br></br>

                                                <Row>
                                                    <Col md={12}>   <FontAwesomeIcon style={{ color: "#2E3092" }} icon={faSignature} aria-hidden="true" ></FontAwesomeIcon> &nbsp; &nbsp;{sessionStorage.getItem("name")}</Col>
                                                </Row>
                                                <br></br>
                                                <Row>
                                                    <Col md={12}>   <FontAwesomeIcon style={{ color: "#2E3092" }} icon={faMobile} aria-hidden="true" ></FontAwesomeIcon> &nbsp; &nbsp;{sessionStorage.getItem("phoneNumber")}</Col>
                                                </Row>
                                                <br></br>
                                                <Row>
                                                    <Col md={12}>   <FontAwesomeIcon style={{ color: "#2E3092" }} icon={faEnvelope} aria-hidden="true" ></FontAwesomeIcon> &nbsp; &nbsp;{sessionStorage.getItem("email")}</Col>
                                                </Row>
                                                </CardBody>
                                        </Card>

                                                <br></br>
                                                
                                                    
                                                        <Card className=" shadow border-0 " style={{ paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px', paddingRight: '10px' }}>
                                                            <CardBody className="px-lg-2 py-lg-2">
                                                                <div style={{ height: '250px', width: '100%' }}>
                                                                    <GoogleMapReact
                                                                        bootstrapURLKeys={{ key: 'AIzaSyBKdXoEy8iDv_7yvzRJkH2mW9LFieOIT4g' }}
                                                                        defaultCenter={this.props.center}
                                                                        defaultZoom={this.props.zoom}>
                                                                        <Marker lat={28.414648} lng={77.094404} />
                                                                    </GoogleMapReact>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                         
                                           
                                    </Col>
                                </Row>
                              
                            </Col>
                            <Col md={8} sm={12}>


                                <Row style={{paddingTop:"10px"}}>
                                    <Col md={12}>
                                        <Alert color="warning" ><b>


                                            <Link to="/VisitorView" style={{textDecoration:"none",  color:"000A5B"}}>
                                                Total Number of visitors </Link></b>&nbsp;&nbsp;&nbsp;&nbsp;
                                   
                                        </Alert>
                                    </Col>
                                   

                                </Row>


                                <Row>
                                    <Col md={12}>
                                        <Alert color="warning" ><b>


                                            <Link style={{textDecoration:"none",  color:"000A5B"}} to="/RecMessage">
                                                 Send mail to donor  <Badge pill>{this.state.doner_count}</Badge></Link></b>&nbsp;&nbsp;&nbsp;&nbsp;
                                    
                                        </Alert>
                                    </Col>
                                    <br></br>

                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <Alert color="warning" ><b>


                                            <Link to="/ListingDonor">
                                                No. of Donors  <Badge pill>{this.state.doner_count}</Badge></Link></b>&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* <Button style={{marginLeft:'19%'}} color='danger'><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></Button> */}

                                        </Alert>
                                    </Col>
                                    <br></br>

                                </Row>



                                <Row>
                                    <Col md={12}>
                                        <Alert color="warning" ><b>


                                            <Link
                                             to="/ListingBeneficiary"
                                             >
                                                No. of Vendors  <Badge pill>
                                                     {this.state.ben_count}
                                                    </Badge></Link></b>&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* <Button style={{marginLeft:'19%'}} color='danger'><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></Button> */}

                                        </Alert>
                                    </Col>
                                    <br></br>

                                </Row>




                                 {/* <Row>
                        <Col md={12}>
                                <Alert color="warning" ><b>
                                    <Link to="/contentHome">
                                    Update Content</Link></b>&nbsp;&nbsp;&nbsp;&nbsp;
                                
                                </Alert>
                            </Col>
                        </Row>  */}
                                <Row style={{paddingTop:"70px"}}>
                                    {/* <Col md={4}>
                                <Card body inverse color="success" style={{ textAlign: 'center' }}>
                                    <CardHeader><Link to="/ListingDonor"><h1>{this.state.doner_count} </h1></Link></CardHeader>
                                    <br></br>
                                    <CardSubtitle>No. of Donors</CardSubtitle>
                                </Card>
                            </Col> */}
                                    {/* <Col style={{padding:"10px"}} md={4}>
                                        <Card body inverse color="info" style={{ textAlign: 'center' }}>
                                            <CardHeader><h1><Link to="/ListingBeneficiary" style={{ color: 'white' }}>18 !</Link></h1></CardHeader>
                                            <br></br>
                                            <CardSubtitle>No. of Beneficiary</CardSubtitle>
                                        </Card>
                                    </Col> */}
                                    <Col style={{padding:"10px"}} md={4}>
                                        <Card body inverse color="info" style={{ textAlign: 'center' }}>
                                            <CardHeader><h1><Link style={{ color: 'white' }} to="/ViewTotalAmount">14 !</Link></h1></CardHeader>
                                            <br></br>
                                            <CardSubtitle>Total Collection</CardSubtitle>
                                        </Card>
                                    </Col>
                                </Row>
                                <br></br>
                                {/* <Row>
                            <Col md={6}>
                                <Chart
                                    width={'300px'} height={'200px'} chartType="BarChart" loader={<div>Loading...</div>}
                                    data={[
                                        ['City', 'Comleted', 'Cancelled'],
                                        ['May 2019', 14, 12]
                                    ]}
                                    options={{
                                        title: 'Donors in May 2019',
                                        chartArea: { width: '40%' },

                                    }}
                                    // For tests
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </Col>
                            <Col md={6}>
                                <Chart
                                    width={'300px'} height={'200px'} chartType="BarChart" loader={<div>Loading...</div>}
                                    data={[
                                        ['City', 'Comleted', 'Cancelled'],
                                        ['April 2019', 11, 10]
                                    ]}
                                    options={{
                                        title: 'Donors in April 2019',
                                        chartArea: { width: '40%' },

                                    }}
                                    // For tests
                                    rootProps={{ 'data-testid': '1' }}
                                /></Col>
                        </Row> */}
                            </Col>
                        </Row>
                        {/* For Spacing */}
                        <Row>
                            <Col md={12}>
                                <div style={{ height: '25px' }}></div>
                            </Col>
                        </Row>
                        {/* <Row>
                    <Col md={12}>
                        <Chart
                            width={'100%'}
                            chartType="Calendar"
                            loader={<div>Loading Chart</div>}
                            data={[
                                [{ type: 'date', id: 'Date' }, { type: 'number', id: 'Won/Loss' }],
                                [new Date(2019, 5, 13), 'Mr Sharma, C2 2524 Vasant Kunj Delhi, 10:00 am '],
                                [new Date(2019, 5, 26), 'Mr Sharma, C2 2524 Vasant Kunj Delhi, 11:00 am '],
                            ]}
                            options={{
                                title: 'Donor Information',
                                backgroundColor: '#fff',
                                calender: { cellColor: '#981b48' },
                                noDataPattern: {
                                    backgroundColor: '#000',
                                    color: '#E9E9E9'
                                },

                                yearLabel: {
                                    fontName: 'Times-Roman',
                                    fontSize: '32',
                                    color: '#1A8763'
                                },
                                monthLabel: {
                                    fontName: 'Times-Roman',
                                    fontSize: '12',
                                    color: '#981b48'
                                },
                                monthOutlineColor: {
                                    stroke: '#981b48'
                                },
                                unusedMonthOutlineColor: {
                                    stroke: '#bc5679'
                                },
                                underMonthSpace: '16'
                            }}
                            rootProps={{ 'data-testid': '1' }}></Chart>
                    </Col>
                </Row> */}
                    </div>
                </Container>
                <br></br>
            </div>
        )
    }
}
export default DashboardAdmin;
