import React, {  } from 'react'
import NewHeader from '../Components/NewHeader';
import axios from 'axios';
// import Chart from "react-google-charts";
import {
   
    Alert, Container,  Row, Col,  Button,    Card,CardBody,  CardHeader, CardSubtitle
} from 'reactstrap';
import { faAddressCard, faMobileAlt, faEnvelope, faEdit, faUser,
    faSignature,faMobile} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Link} from 'react-router-dom';
import { baseurl } from './BaseUrl';
import GoogleMapReact from 'google-map-react';
const findAllitemUrl = baseurl + 'listofdoner';
const listofbeneficiary = baseurl + 'b34';
const Marker = props => {
    return <div><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></div>
}

class DonorDashBoard extends React.Component {
    static defaultProps = {
        center: {
            lat: 28.414648,
            lng: 77.094404
        },
        zoom: 15
    };
    constructor(props) {
        super(props);

        this.state = {
            visible: true,

        };
        this.state = {
            doner_count: 0
        };
let is_login = sessionStorage.getItem("logged");
 
if(is_login === 'none') {
this.props.history.push('/');
}
        this.onDismiss = this.onDismiss.bind(this);

    }

    onDismiss() {
        this.setState({ visible: false });
    }


    componentDidMount() {
        //alert("ff");
    axios.post(findAllitemUrl)
    .then(Response => {
     //alert(JSON.stringify(Response.data.data.length));
     this.setState({doner_count:Response.data.data.length});
    //this.setState({Rows:Response.data.data.length})
    
    })
    .catch(err => {
    console.log(err);
    });
    
    axios.post(listofbeneficiary)
    .then(Response => {
     //alert(JSON.stringify(Response.data.data.length));
     this.setState({ben_count:Response.data.length});
    //this.setState({Rows:Response.data.data.length})
    
    })
    .catch(err => {
    console.log(err);
    });
    }

    render() {

        return (
            // <div className="donorbg">
            <div className="bgfund"  style={{padding:"30px"}}>
           {/* <div className="dshbrdimg" style={{padding:"30px"}} > */}
                <br></br>
            {/* <Container style={{ backgroundColor: '#d5d5d5' }}> */}
            <Container  className="divbg"> 
            <br></br>
<Row className="justify-content-center"><h2  style={{color:"white"}}><u>Dashboard </u></h2> </Row>
                <div style={{ padding: '10px' }}>
                    <Row style={{ padding: '10px' }}>
                        <Col md={4} sm={12}>
                            <Row>
                                <Col md={12}>
                                <Card className=" shadow border-0 "style={{ paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px' }} >
    <CardBody  className="px-lg-3 py-lg-3"> 
                                    {/* <Card style={{ paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px' }}> */}
                                    <Row>
                                            <Col md={10}>
                    <h4  className="text-muted" style={{textAlign:"center"}}> <FontAwesomeIcon style={{color:"#2E3092"}} icon={faUser}   aria-hidden="true" style={{color:"darkblue",fontSize:"23px"  }}></FontAwesomeIcon> My Profile</h4> </Col>
                                            <FontAwesomeIcon style={{color:"#2E3092"}}/>                                            
                                                <Link to="/DonorProfile" style={{ color: "black" }} id="TooltipExample">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Link>
                                        </Row>
     <br></br>  <br></br>

                                        <Row>
                                            <Col md={12}>   <FontAwesomeIcon style={{color:"#2E3092"}} icon={faSignature} aria-hidden="true" ></FontAwesomeIcon> &nbsp;{sessionStorage.getItem("Name")}</Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col md={12}>   <FontAwesomeIcon style={{color:"#2E3092"}} icon={faMobile} aria-hidden="true" ></FontAwesomeIcon> &nbsp;{sessionStorage.getItem("phoneNumber")}</Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col md={12}>   <FontAwesomeIcon style={{color:"#2E3092"}} icon={faEnvelope} aria-hidden="true" ></FontAwesomeIcon> &nbsp;{sessionStorage.getItem("email")}</Col>
                                        </Row>
                                        </CardBody>
                                    </Card>


                                

                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col md={12}>
                                    <Card  className=" shadow border-0 " style={{ paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px', paddingRight: '10px' }}>
                                    <CardBody  className="px-lg-2 py-lg-2">
                                        <div style={{ height: '250px', width: '100%' }}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: 'AIzaSyBKdXoEy8iDv_7yvzRJkH2mW9LFieOIT4g' }}
                                                defaultCenter={this.props.center}
                                                defaultZoom={this.props.zoom}>
                                                <Marker lat={28.414648} lng={77.094404} />
                                            </GoogleMapReact>
                                        </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={8} sm={12}>
                          {/*  <Row  style={{paddingTop:"10px"}}>
                                
                                <Col md={12}>
                                    <Alert color="warning" isOpen={this.state.visible}>
                                     <Link to="/ListingDonor" style={{textDecoration:"none",  color:"000A5B"}}><b>Donors Links with Pra-nee</b></Link>  &nbsp;&nbsp;&nbsp;&nbsp;
                                        {/* <Button style={{ marginLeft: '30%' }} color='danger' id='btnCancelBooking'> */}
                                        {/* <button>Donate</button> */}
                                            {/* <FontAwesomeIcon icon={faBan}></FontAwesomeIcon> */}
                                            {/* </Button> */}
                                        {/* <UncontrolledTooltip placement="right" target="btnCancelBooking">Cancel this Booking</UncontrolledTooltip> */}
                                  {/* </Alert>
                                </Col>
                            </Row>    */}

                            <Row>
                                <Col md={12}>
                                    <Alert color="warning" isOpen={this.state.visible}>
                                     <Link to="/ViewDonorItem" style={{textDecoration:"none",color:"000A5B"}}><b>My Contribution Details</b></Link>  &nbsp;&nbsp;&nbsp;&nbsp;
                                        {/* <Button style={{ marginLeft: '30%' }} color='danger' id='btnCancelBooking'> */}
                                        {/* <button>Donate</button> */}
                                            {/* <FontAwesomeIcon icon={faBan}></FontAwesomeIcon> */}
                                            {/* </Button> */}
                                        {/* <UncontrolledTooltip placement="right" target="btnCancelBooking">Cancel this Booking</UncontrolledTooltip> */}
                                   </Alert>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Alert color="warning" isOpen={this.state.visible}>
                                     <Link 
                                      to="/BeneficiaryView" 
                                     style={{textDecoration:"none",color:"000A5B"}}><b>Beneficiary List</b></Link>  &nbsp;&nbsp;&nbsp;&nbsp;
                                        {/* <Button style={{ marginLeft: '30%' }} color='danger' id='btnCancelBooking'> */}
                                        {/* <button>Donate</button> */}
                                            {/* <FontAwesomeIcon icon={faBan}></FontAwesomeIcon> */}
                                            {/* </Button> */}
                                        {/* <UncontrolledTooltip placement="right" target="btnCancelBooking">Cancel this Booking</UncontrolledTooltip> */}
                                   </Alert>
                                </Col>
                            </Row>


                            


                            <br></br>
                            <Row>
                            <Col md={4}> 
                             <Link to="DonorDetailsForm">
                            <Button  className="btn btn-info"><b>Donate Now</b></Button></Link>
                            </Col>  </Row>
                            <br></br>
                            <Row>
                                

                                <Col md="4">

                                {/* <Card className=" shadow border-0 "> */}
                                <Card body inverse color="info" style={{ textAlign: 'center', marginTop: "20px" }}>
                                <CardHeader><h1>{this.state.ben_count} !</h1></CardHeader>
                                <br></br>
                                <CardSubtitle>Number of Beneficiary</CardSubtitle>

                            </Card>
                                    {/* </Card> */}

                                </Col>

                                <Col md="4">
                                {/* <Card className=" shadow border-0 "> */}
                                <Card body inverse color="success" style={{ textAlign: 'center', marginTop: "20px" }}>
                                <CardHeader><h1>{this.state.doner_count} !</h1></CardHeader>
                                <br></br>
                                <CardSubtitle>Number of Donors</CardSubtitle>

                            </Card>
                                {/* </Card> */}

                                </Col>

                             </Row>


                           
                        </Col>
                    </Row>
                    {/* For Spacing */}
                   
                   
                </div>

            </Container>
			 <NewHeader />
            <br></br>
            </div>
        );
    }
}
export default DonorDashBoard;
