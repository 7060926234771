import React, { Component } from 'react';
import { Card,  CardTitle, CardText, Row, Col,CardHeader,CardImg } from 'reactstrap';
class VisionMission extends Component{
    render(){
        return(
         
  <Card className="bgPage">

  <CardHeader >Vission and Mission</CardHeader>
<Row>
<Col sm="6">
  <Card body>
    <CardTitle>Vision</CardTitle>
    <CardImg   src="./image/vis.jpg" width="100px" />
 
  </Card>
</Col>
<Col sm="6">
  <Card body>
    <CardTitle><b>Vision</b></CardTitle>
    <CardText>
      <p>We want to emerge as the end-to-end community-based solution provider in the segment of empowering girls with education after 10+2. Often the girls belonging to the economically weaker sections of the society have to sacrifice their education after 10+2 for the sake of their male sibling, earning livelihood, or marriage. Even the talented girls are barred from continuing with further education which can actually become their weapon to bring change and improve life for them and their family. </p>
   <br></br>
   <p>Our vision is to bridge the gap between education and talented girls. They can get admission in the government colleges where they get admission in courses to purse Engineering, Medicine, and Management. This helps in enriching their lives by making their self-reliant. They build their careers and become self confident. Education acts as the perfect tool in gathering this aspect of life for them which can permanently become the sole reason for improvement in their living status. </p>
    </CardText>
   
  </Card>
</Col>
</Row>
<br></br>

<Row>
<Col sm="6">
  <Card body>
    <CardTitle><b>Mission</b></CardTitle>
    <CardText>
      <p>Well planned and comprehensive programs are designed for girl child education after 10+2. Majority of the girls living below the poverty line are not having the resources which can be helpful for their development. Our overall aim is to empower girls from poor and marginalized communities to make improvements in their life and mode of livelihood. We want to create an environment and surrounding for the girls where they can live and move with dignity in society.</p>
    <p>  One of the major drawbacks in government schools is the gender gap. Girls belonging to the underprivileged class are not encouraged to go to school. Alternatively, their siblings (brothers) are sent to school while the girls are held at home to contribute towards household chores. We aim to cover the gap by making people realize the power of education. Education in the elementary level is offered for free in the government and few private schools for the underprivileged children. Hence our initiative aims to empower the girls with higher education so that they can prove their worth in the professional world. </p>
    
    <p>  For achieving this end, the society needs to pay heed to the existing plans of education by the government. We appeal to the community, local guardians and parents of the underprivileged children not to rob the right of education of the girls. We are ready to face the challenges and provide a platform where the girls can come forth and avail the facilities awaiting them. </p>
   
   <p> Though Education is a right, still it is beyond the reach of many. We aim to work and act as the perfect bridge to make both the ends meet.</p>
    </CardText>
    {/* <Button>Go somewhere</Button> */}
  </Card>
</Col>
<Col sm="6">
  <Card body>
    <CardTitle>Mission</CardTitle>
    <CardImg src="./image/mi.jpg"/>
    {/* <Button>Go somewhere</Button> */}
  </Card>
</Col>
</Row>
</Card>

        );
    }
}

export default VisionMission;

