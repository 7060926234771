import React, { Component } from 'react';
import { Container,Row,  Label, Input, Col, Button, FormGroup, Form } from 'reactstrap';
 import {Link} from 'react-router-dom';
import axios from 'axios';
class Signup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            UserName: "",
            PhoneNo: "",
            EmailID: "",
           

        }

    }

    handleUserNameChange = event => {
        this.setState({
            UserName: event.target.value
        })
    }
    handlePhoneNoChange = event => {
        this.setState({
            PhoneNo: event.target.value
        })
    }
    handleEmailIDChange = event => {
        this.setState({
            EmailID: event.target.value
        })
    }
    
    handleSubmit = event => {
        alert(` Hello ${this.state.UserName} `)
       
        const user = {
            UserName: this.state.UserName,
            PhoneNo: this.state.PhoneNo,
            EmailID: this.state.EmailID,
           
            
        };
     
      axios.post('http://localhost:3000/insertSignUp/', user)
            .then(res => {
                console.log(res);
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        event.preventDefault();
    }


    render() {
        return (

            <div className="donorbg">
                <Container className="App-Container" md={{ size: 6, offset: 3 }}>
                    <br></br>
                    <Col md={{ size: 6, offset: 3 }} >
        <Form /*onSubmit={this.handleSubmit}*/  style={{borderRadius:"20px", backgroundColor:"#F0FFFF"}}>
                            <br></br>
                            <h5 className='' style={{ textAlign: 'center', fontWeight: 'bold' }}>Sign In</h5>
                            <hr color='#5DADE2' style={{ height: '2px' }}></hr><br></br>
                            <br></br>
                            <Col md={{ size: 10, offset: 1 }} >
                               <Row>
                                   <Col md={4}>
                                   <FormGroup>
                                    <Label>
                                       <b> User Name:</b>
                                      </Label>
                                      </FormGroup>
                                   </Col>
                                  
                                  < Col md={8}> 
                                  <FormGroup>
                                   <Input type="text" 
                                     value={this.state.UserName} 
                                     onChange={this.handleUserNameChange} required
                                     className="form-control" 
                                     placeholder="Enter User Name"></Input>
                                   </FormGroup>   
                                  </Col>

                               </Row>
                               <Row>
                                   <Col md={4}>
                                   <FormGroup>
                                    <Label>
                                       <b> Phone No:</b>
                                      </Label>
                                      </FormGroup>
                                   </Col>
                                  
                                  < Col md={8}> 
                                  <FormGroup>
                                  <Input type="phonenumber" 
                                     value={this.state.PhoneNo}
                                     onChange={this.handlePhoneNoChange} required
                                    className="form-control" 
                                    placeholder="Enter Phone Number"></Input>
                                   </FormGroup>   
                                  </Col>

                               </Row>
                               
                               <Row>
                                   <Col md={4}>
                                   <FormGroup>
                                    <Label>
                                       <b> Email ID:</b>
                                      </Label>
                                      </FormGroup>
                                   </Col>
                                  
                                  < Col md={8}> 
                                  <FormGroup>
                                  <Input type="text" 
                                    value={this.state.EmailID}
                                    onChange={this.handleEmailIDChange} required
                                    className="form-control"
                                    placeholder="Enter Email Id"></Input>
                                   </FormGroup>   
                                  </Col>

                               </Row>
                               </Col>

                           

                           
                           

                            <Col md={{ size: 2, offset: 3 }}>
                               
                                    <Button className="btn btn-info" ><Link to="/FormBeneficiarysign" style={{color:"white"}}>
                                        <b>Submit</b></Link>
                        </Button>
                               
                            </Col>

                           {/*} <FormGroup row>
                        <Col xs={12} style={{textAlign:"center",marginLeft:"5%"}}>
                            <Button><Link to="/FormBeneficiarysign">Submit</Link></Button>&nbsp;
                           
                                        <Button>Reset</Button>
                        </Col>
        </FormGroup> */}
                            <br></br>
                            <br>
                            </br>
                        </Form>
                    </Col>
                    <br></br>
                </Container>
            </div>

        )

    }
}
export default Signup