// yashwant system
//exports.MainNode = "192.168.1.46:3107/";

//anshu system
//exports.anshu = "192.168.1.84:3107/";

//sadhana system
//exports.sadhana = "192.168.1.84:3107/";

//any localhost

//exports.localhost = "http://localhost:4107/";

//always use this for your project and change according to your need
// exports.baseurl = "192.168.1.46:8000/";


// exports.baseurl = "http://localhost:8000/";
//exports.baseurl = "http://localhost:4107/";
// exports.baseurl = "http://localhost:5107/";
exports.baseurl = "https://arcane-headland-82582.herokuapp.com/";
// exports.baseurl = "http://169.254.149.118:8000/";