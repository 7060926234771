import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import { Container,Row, Col,FormGroup,Label,Button,Card,CardBody,Table} from 'reactstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import { baseurl } from './BaseUrl';
import FlatList from 'flatlist-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faMobileAlt, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const findAllitemUrl = baseurl + 'b34';
class BeneficiaryView extends Component{

    constructor(props) {
        super(props);
        this.state = {
         Rows: [ ],
        }
        
        this.state = {
           ben_count:0
                   };
           
       
       }
       renderPerson = (person, idx) => {
       //alert(JSON.stringify(person));
        return (
        <tr  key={`${person._id}`}>
       <td>{person.Name} </td>
       <td>{person.phoneNumber} </td>
       <td>{person.email} </td>
       <td>{person.BPL} </td>
       
       
       <td>{person.FatherName} </td>
       <td>{person.Marks12} </td>
       <td>{person.passingyear} </td>
       
       
       <td>{person.City} </td>
       <td>{person.State} </td>
       <td>{person.pincode} </td>
        </tr>
        );
       }
       componentDidMount() {
           //alert("ff");
       axios.post(findAllitemUrl)
       .then(Response => {
        //alert(JSON.stringify(Response));
       this.setState({Rows:Response.data})
       this.setState({ben_count:Response.data.length});
       
       })
       .catch(err => {
       console.log(err);
       })
       }
       
           render(){
               return(
                   <div><br></br>
                   <Container>
                   <Row className="justify-content-end mt-0">
       
                   <Col md={4}>
                     <b>  No of beneficiary -{this.state.ben_count}</b>
                             </Col>   
                             <Col md={7}>
                      
                             </Col>   
                             <Col md={1}>
                                               <Link to="/DonorDashBoard" style={{ color: "white", textDecoration: "none" }}>
                                                   <FontAwesomeIcon style={{ color: "red", fontSize: "30px" }} icon={faTimesCircle} ></FontAwesomeIcon> </Link>
                                                   </Col> 
                                           </Row> 
       <h2 style={{textAlign:'center'}}>List of beneficiary</h2>
       <Table striped id="students">
         <tr>
       
       <th>Name </th>
       <th>Ph.No </th>
       <th>E-mail</th>
       <th>BPL</th>
       
       <th>Father Name </th>
       <th>Marks of 12th</th>
       <th>Passing year</th>
       
       
       
       
       <th>City</th>
       <th>State</th>
       <th>Pincode</th>
       </tr>
       
       <FlatList list={this.state.Rows} renderItem={this.renderPerson}/>
       </Table>
                      
        
                       <br></br>
                   </Container>  <NewHeader />
               </div>
               )
           }

} export default BeneficiaryView