import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import {
    Card, Button, CardImg, CardTitle, CardText, CardDeck,
    CardSubtitle, CardBody, Container
} from 'reactstrap';
import { baseurl } from './BaseUrl';
import axios from 'axios';

const get_homecontent = baseurl + 'getFaq';
class Faq extends Component {

    constructor(props) {
        super(props);

this.state = {
            data: ''
        };
       
    }

    componentDidMount() {
   
        axios.post(get_homecontent)
        .then(Response => {
        // alert(JSON.stringify(Response.data.data[0].faq));
        this.setState( {
        data:Response.data.data[0].faq
        } );
        
        })
        .catch(err => {
        console.log(err);
        })
        
        
            }
        

    render() {

        return (


            <div className="bg">

                <div>
                    <img style={{ height: "180px", maxHeight: "auto", width: "100%" }} src="https://i.postimg.cc/gcfwczHQ/FAQ1.jpg" className="responsiveImg" />
                </div>
                <br></br>
                <br></br>
                <br></br>
                <Container>
                <article>
 
 <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div>
</article>
                </Container>
                <NewHeader />
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>


            </div>



        );
    }
}

export default Faq;