import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import { Container, Row, Card,InputGroup,InputGroupAddon,InputGroupText,CardBody, Label, Input, Col,Button,FormGroup,Form } from 'reactstrap';
import {Link,Route, Redirect} from 'react-router-dom';
import axios from 'axios';
import { baseurl } from './BaseUrl';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faAddressCard,faMobileAlt,faEnvelope,faGraduationCap,faPhone,faUnlock} from '@fortawesome/free-solid-svg-icons';
import { faInstagram,faFacebook,faTwitterSquare } from '@fortawesome/free-brands-svg-icons' ;
const otpGenUrl = baseurl + 'generateResLoginOtp1';
const verifyOtp = baseurl + 'verifyResLoginOTP1';
class RagmanLogin extends Component {

    constructor(props) {
        super(props)
        this.state = {
           
            mobile: '',
            otp: '',
            showOTPfield: false,
            buttonText: 'Send OTP',
            buttonDisabled: false,
            wrongPassword: false,
            loading: false
        }
        this.handleMobileChange = this.handleMobileChange.bind(this);
        this.handleOTPChange = this.handleOTPChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        console.log(this.props);
    }

    handleMobileChange(e) {
        if (e.target.value.length > 10) {
            return;
        }
        this.setState({
            mobile: e.target.value
        })
    }

    handleOTPChange(e) {
        if (e.target.value.length === 6) {
            //send request to verify otp
            this.setState({ buttonDisabled: false })
        }
        if (e.target.value.length > 6) {
            return;
        }
        this.setState({ otp: e.target.value });
    }
    handleSubmit(e) {
        e.preventDefault();
        if (this.state.showOTPfield === false) {
            // send Ajax request to generate OTP and fetch OTP in response
            console.log(e.target.mobile.value);
            if (e.target.mobile.value.length < 10) {
                alert("Please Enter a valid Number");
                toast("Please Enter a valid Number", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true
                });
                return;
            }
            let otpObj = {
                phoneNumber: e.target.mobile.value
            }
            console.log(otpObj);
            axios.post(otpGenUrl, otpObj)
                .then(response => {
                    console.log(response);
                    if (response.data.statusCode === 400) {
                        alert("Number is not registered");
                        toast("Number is not registered", {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: true
                        });
                    }
                    else {
                        this.setState({
                            showOTPfield: true,
                            buttonText: 'Login',
                            buttonDisabled: true
                        });
                    }

                })
                .catch(err => {
                    console.log(err);
                })
            // send Ajax request to generate OTP and fetch OTP in response

        }

        else {
            const formPayload = {
                phoneNumber: this.state.mobile,
                otp: this.state.otp
            };

            this.setState({ loading: true })
            axios.post(verifyOtp, formPayload)
                .then(response => {
                    console.log(response)
                    if (response.data.statusCode === 200) {
                        sessionStorage.setItem("user_id", response.data.userID);
						sessionStorage.setItem("Name", response.data.Name);
						sessionStorage.setItem("email", response.data.email);
						sessionStorage.setItem("phoneNumber", response.data.phoneNumber);

                        alert("OTP Verified")
                        toast("OTP Verified", {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: true
                        });

sessionStorage.setItem("logged", "regment")
                    //  this.props.something()
                        this.setState({
                            loading: false,
                            isLogged: true
                        })
//window.location.reload();
                    }
                    else if (response.data.statusCode === 400) {
                          alert("Wrong OTP");
                      
                        toast("Wrong OTP", {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: true
                        });
                        this.setState({ loading: false })
                    }
                    else {
                        console.log("Error Occured")
                    }
                })

           
        }
    }

    render() {
        return (
            <div className="bg">

<Container >
            <br></br>
            <Row className="justify-content-center">
         
            <Col lg="6" md="8">
            <Card className=" shadow border-0 " style={{backgroundColor:"white"}}>
         
                 
              <CardBody  className="px-lg-5 py-lg-5">
              <div>
               
              <h3 style={{textAlign:"center", color:"gray"}}><u>Ragman Login </u></h3>
             
              <br></br>
               
                <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{backgroundColor:"white"}}>
                        {/* <i className="ni ni-email-83" /> */}
                        <FontAwesomeIcon icon={faPhone} style={{transform: "rotate(90deg)"}} aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="number" name="mobile" placeholder="Mobile Number"
                                        onChange={this.handleMobileChange}
                                        value={this.state.mobile} />
                    </InputGroup>
                </FormGroup>

                                <FormGroup>
                                {this.state.showOTPfield &&
                                <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText style={{backgroundColor:"white"}}>
                                    {/* <i className="ni ni-email-83" /> */}
                                    <FontAwesomeIcon icon={faUnlock} style={{transform: "rotate(180deg)"}} aria-hidden="true" ></FontAwesomeIcon>
                                  </InputGroupText>
                                </InputGroupAddon>
                               
                                 <Input style={{ boxShadow: "none"}}
                                 type="otp" id="OTP" name="otp" placeholder="Enter OTP"
                                        onChange={this.handleOTPChange}
                                        value={this.state.otp} />
    </InputGroup> }
                                </FormGroup>
                                {/* <Button type="submit" onClick={this.handleSubmit} block>Log In</Button> */}
                                <FormGroup>
                                <Button type="submit" disabled={this.state.buttonDisabled} block>{this.state.buttonText}</Button>
                               
                                </FormGroup>


                            </Form>

                            <Route exact path="/RagmanLogin"
                    render={() => (this.state.isLogged ? (<Redirect to="/LogisticDashboard" />) : (null))} />
         
          </div>
          </CardBody>
          </Card>
          </Col>
    </Row> <br></br>
            </Container> <NewHeader />
            </div>
        )
    }
}
export default RagmanLogin