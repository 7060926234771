import React, { Component } from 'react';
import {Container,Row,Col} from 'reactstrap';
class Aim extends Component{
    render(){
        return(
            <div className="bg">
                <br></br>
                <Container> 
                <div>  <marquee direction="right" behavior="alternate"><b><p style={{color:"maroon"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
                </Container>
                
            <div style={{backgroundColor:"black"}}> <p style={{color:"white",textAlign:"center",fontSize:"70px"}}>Our Aim</p>
            <p style={{color:"white",textAlign:"center",fontSize:"25px",fontStyle:"italic"}}>.....Don't just stand for the success of other women - insist on it.  </p>  <br></br></div>
           
<div>
   
    <br></br>
    <Container>
   
    <br></br>

 <p className="pfont text-justify">We believe that education creates a professional skill that paves the way for sustainability. Keeping this in view we have carved and planned a canvas that aims to provide aid to the girls to add a feather in their cap, rise and shine in the society. </p>
<p className="pfont text-muted text-justify">  Well planned and comprehensive programs are designed for girl child education after 10+2. The majority of the girls living below the poverty line are not having the resources which can be helpful for their development. Our overall aim is to empower girls from poor and marginalized communities to make improvements in their life and mode of livelihood. We want to create an environment and surrounding for the girls where they can live and move with dignity in society. </p>
 
<p style= {{ fontSize:"18px" }}><b>An overview of the problems in our society:</b></p>
 <p className="pfont text-muted text-justify">•	One of the major drawbacks in government schools is the<b style={{color:"black"}}> gender gap</b>. Girls belonging to the underprivileged class are not encouraged to go to school. Alternatively, their siblings (brothers) are sent to school while the girls are held at home to contribute towards household chores. We aim to cover the gap by making people realize the power of education. Education at the <b style={{color:"black"}} > elementary level</b> is offered for free in the government and few private schools for the underprivileged children. Hence our initiative aims to empower the girls with higher education so that they can prove their worth in the professional world.  </p>
<br></br>
<p className="pfont text-muted text-justify">•	Discrimination between girls and boys is fiercely existent in the underprivileged strata of our society. The girl child is considered as a burden and denied the right to elementary education. We need to eradicate the problem at the grass-root level and this can be primarily become possible by creating awareness amongst the people. </p>
 <br></br>
 <p className="pfont text-muted text-justify">For achieving this end, society needs to pay heed to the existing plans of education by the government. We appeal to the community, local guardians and parents of the underprivileged children not to rob the right of education of the girls. We are ready to face the challenges and provide a platform where the girls can come forth and avail of the facilities awaiting them. </p>
<br></br>
<p className="pfont text-muted text-justify"><b>Though Education is a right, still it is beyond the reach of many. We aim to work and act as the perfect bridge to make both the parameters meet. By reducing the gender gap in schools, we can look towards building a bright future.</b> </p>
<br></br>
<div style={{textAlign:"center"}}> 
<img src="./Image/women.jpg" className="responsiveImg"style={{borderRadius:"30px"}} ></img>
</div >
<br></br>
<br></br>
<br></br>
    </Container>
 

</div>

            </div>


        )
    }
}
export default Aim