import React, { Component } from 'react';
import SliderLivelihood from '../Components/SliderLivelihood';

export default class Livelihood extends Component {
    render() {
        return (
            <div>
              <SliderLivelihood/>
                
            </div>
        );
    }
}  
