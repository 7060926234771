import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle,  UncontrolledDropdown } from 'reactstrap';
 const Custommenu = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
  

    const toggle = () => setDropdownOpen(prevState => !prevState);
  
    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          tag="span"
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
          className="px-4">
         <b   style={{ color: "white",cursor:"pointer" }}> About   </b>
        </DropdownToggle>
        
      
        <DropdownMenu style= {{marginLeft:"157px",marginTop:"-20px",backgroundColor:"#0665A4" ,width:"185px",maxWidth:"auto"}} className="px-4"> 
        <div onClick={toggle}> <Link to="/contentPranee" className="link"><b>Pra-nee</b></Link></div>
         
        
          <div onClick={toggle}> <Link to="/contentVision" className="link"><b>Vision</b></Link></div>
        
          <div onClick={toggle}>  <Link to="/contentTeam" className="link"><b>Our Team</b></Link></div>
         
        </DropdownMenu>
    
      </Dropdown>
    );
  }
  
  export default Custommenu;