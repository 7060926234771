import React, { Component } from 'react';
import { Container, Row, Col,  FormGroup, Label, Input, Button,Form } from 'reactstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { baseurl } from './BaseUrl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faMobileAlt, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


const SaveMaster = baseurl + 'ItemmasterII';

class MasterDonorForm extends Component {

constructor(props){
super(props);
this.state={
Itemname:''

}

}
handleItemnameChange = event => {
     this.setState({
     Itemname: event.target.value
   })
 }





 handleSubmit = event => {
    alert("Submitted")
  
  
  
  const user = {
   
    Itemname: this.state.Itemname
  
  
  };
  axios.post(SaveMaster, user)
  .then(res => {
    console.log(res);
    console.log(res.data);
  })
  .catch(function (error) {
    console.log(error);
  });
  event.preventDefault();



  }









    render() {
        return (
            <div className="">
            <Container >
                <br></br>

                <Row className="justify-content-end mt-0">
                                        <Link to="/DashboardAdmin" style={{ color: "white", textDecoration: "none" }}>
                                            <FontAwesomeIcon style={{ color: "red", fontSize: "30px" }} icon={faTimesCircle} ></FontAwesomeIcon> </Link>
                                    </Row> 

               <div className="UserSignUpD ">
                    <h2 style={{ textAlign: 'center' }}><b>Master Donating Item</b></h2>
                    <hr color='#045AC4' style={{ height: '2px' }}></hr>
                    <Form onSubmit={this.handleSubmit}>
                   
                        <FormGroup>
                                                        <Col md={{ size: 10, offset: 1 }}>
                              <Row>
                                   <Col md={4}>
                                   <FormGroup>
                                    <Label>
                                    <b>Donating Item</b>
                                      </Label>
                                      <Input type="text" 
                                    
                                    className="form-control" 
                                    placeholder="Donating Item"
                                    value = {this.state.Itemname} onChange = {this.handleItemnameChange}
                                    ></Input>
                                      </FormGroup>
                                   </Col>
                                  
                                  <Col md={4}> 
                                  <FormGroup>
                                    <Label>
                                    <b>Carbon cost</b>
                                      </Label>
                                      <Input type="text" 
                                    
                                     className="form-control" 
                                     placeholder="Carbon cost"></Input>
                                   </FormGroup>   
                                  </Col>
                                
                               </Row>
						
                        </Col>

                        </FormGroup>

<br></br>
                        <FormGroup row>
                        <Col xs={12} style={{textAlign:"center"}}>
                        {/* <Button  className="cardb btn btn-primary">
                                     Submit 
                                    </Button> */}

<Button color="info">Submit</Button> 

                        </Col>
                        </FormGroup>
                        </Form>
             
                </div>
                <br></br>
            </Container>
            </div>
        );
    }
}
export default MasterDonorForm
