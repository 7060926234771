import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import { Container, Row, Col, Card } from 'reactstrap';
import { baseurl } from './BaseUrl';
import axios from 'axios';
import {Link,Route,Redirect} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faMobileAlt, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const get_homecontent = baseurl + 'getnews1';
class FullNews extends Component {

    constructor(props) {
        super(props);

this.state = {
            data: ''
        };
       
    }



    componentDidMount() {
   
        axios.post(get_homecontent)
        .then(Response => {
        // alert(JSON.stringify(Response.data.data[0].funding));
        this.setState( {
        data:Response.data.data[0].cenews
        } );
        
        })
        .catch(err => {
        console.log(err);
        })
        
        
            }
        

    render(){
        return(
           
            <div>
            <br></br>
            <p style={{textAlign:"center",fontStyle:"bold", fontSize:"23px"}}> <u>Latest news</u> </ p>
               
            <Container className="news1">
             

            <Row className="justify-content-end mt-0">

                                        <Link to="/" style={{ color: "white", textDecoration: "none" }}>
                                            <FontAwesomeIcon style={{ color: "red", fontSize: "23px"}} icon={faTimesCircle} ></FontAwesomeIcon> </Link>
                                    </Row>                    
       {/* <Container style={{height:"290px" }}> */}
       
       
               
			    <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div>
              
               </Container><NewHeader />
              
          
               <br></br>

            </div>
            
          
            
        )
    }
}export default FullNews