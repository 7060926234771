import React, { Component } from 'react';
import { Button,FormGroup,Input,Card,CardBody,Form,Col, Container,Row } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard,faMobileAlt,faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { faInstagram,faFacebook,faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons' ;
import { Link } from 'react-router-dom';
import { baseurl } from './BaseUrl';
const visitor = baseurl + 'all_visitor';


class Ngofooter extends Component {
    constructor(props){
        super(props)
            this.state = {
                Name:"",
                Email: "",
                Message: "",
                Rows: [ ],
                Rows_count: 0,
            }
        
    }
    handleNameChange = event => {
        this.setState({
            Name: event.target.value
        })
    }
    handleEmailChange = event => {
        this.setState({
            Email: event.target.value
        })
    }
    handleMessageChange = event => {
        this.setState({
            Message: event.target.value
        })
    }
    handleSubmit = event => {
        alert( "Signed In" );
        
        const SubmitDAta = {
        
            Name: this.state.Name,
            Email: this.state.Email,
            Message: this.state.Message,
            
        };

        axios.post('http://localhost:3000/WriteUS/', SubmitDAta)
        .then(res => {
            console.log(res);
            console.log(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
        event.preventDefault();
    }

    componentDidMount() {
       
        axios.get(visitor)
            .then(Response => {
               //alert(JSON.stringify(Response.data.length));
this.setState({Rows:Response.data})
this.setState({Rows_count:Response.data.length})
               
            })
            .catch(err => {
                console.log(err);
            })
}

    render() {
        return (
            <div style={{backgroundColor:"black"}} > 
               
               <Container >
                    <br></br>
                   <Row  className="justify-content-center"> 
                      <Col md="10" >
                      <Row className="justify-content-center"> 
                       <Col md ="6" >
                        <h4 className="footer">
                               <u>Contact Us </u> 
                            </h4>
                            <div  style={{color:"white" }}> 
                           {/* <p> <FontAwesomeIcon icon={faAddressCard}
                            fixedWidth style={{color:"white"}}></FontAwesomeIcon> 
                            Wayme Technologies Private Limited  </p> */}
                          
                           <p> RSN Bahuguna Foundation,</p>
                           <p>  2524 C 2 ,</p>
                           <p>  Vasant Kunj New Delhi, </p>
                           <p>Pincode-110070 </p>
                        <br></br>
                            <FontAwesomeIcon icon={faMobileAlt} fixedWidth></FontAwesomeIcon> 9540711700<br />
                            <br></br>
                            <FontAwesomeIcon icon={faEnvelope} fixedWidth></FontAwesomeIcon>Runjhun@wayme.in

                            </div>
                            </Col>
                         
                       
<Col md="4"> 

<h4 className="footer">
                               <u>Write To Us</u> 
                            </h4>
                        <Card  className="footerCard " style={{borderColor:"black"}} >
                        
                        <Form  className="footerCard " onSubmit={this.handleSubmit} >
							<CardBody> 
                            {/* <h4 className="footer" style={{ textAlign:"center"}}>Write To Us</h4> */}
							 
									<FormGroup>
									     <Input type="Name" className="form-control" placeholder="Name" value = {this.state.Name} onChange = {this.handleNameChange}  />
									</FormGroup>
                                    <FormGroup>
									     <Input type="Email" className="form-control" placeholder="Email"  value = {this.state.Email} onChange = {this.handleEmailChange}/>
									</FormGroup>
									 <FormGroup>
			                             <Input  type="textarea" className="form-control"  required="" placeholder="Message" value = {this.state.Message}  onChange = {this.handleMessageChange}></Input>
									</FormGroup>
									 <FormGroup>
                                         <Button className="btn btn-info" block><b>Submit</b></Button>
                                    </FormGroup>
                                    </CardBody>
						</Form> 
                        
                        </Card>
                       
                        </Col>

                            
                       
                       
                        </Row>
                        </Col>
                        </Row>
                   <br></br>
                    <div style={{textAlign:"center"}}><h4 style={{color:"white"}}>Thank you for supporting us!</h4>
                    <a href="https://www.instagram.com/" target ="tab" title="Follow Us"> 
                     <FontAwesomeIcon className="insta" icon={faInstagram} ></FontAwesomeIcon> </a> &nbsp; 
                     <a href="https://www.facebook.com" target ="tab" title="Like Us">
                     <FontAwesomeIcon icon={faFacebook} className="fb" ></FontAwesomeIcon></a> &nbsp; 
                     <a href=" https://www.twitter.com/" target ="tab" title="Follow Us">
                     <FontAwesomeIcon icon= {faTwitter} className="tw"></FontAwesomeIcon></a>&nbsp;&nbsp;
                    <a href="https://www.linkedin.com/" target ="tab" title="Follow Us"> 
                     <FontAwesomeIcon className="linkdin" icon={faLinkedin} ></FontAwesomeIcon> </a> </div>
                    <hr color="white" style={{ height: '2px' }}></hr>
                  
<p style={{ textAlign: 'center',color:"white" }}>© 2020 Developed and managed by Wayme Technologies Private Limited | <a href="/" style={{color:"lightblue"}}>Privacy Policy </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No of visitors &nbsp;{this.state.Rows_count}</p>

</Container>
             
            </div>
        )
    }
}
export default Ngofooter