import React, { Component } from 'react';
import axios from 'axios';
import {
  Carousel, 
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import { baseurl } from './BaseUrl';
const get_gallary = baseurl + 'getSlider';

const items = [];

class Slider extends Component {
  constructor() {
	  
    super();
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
	this.state = {activeIndex:0,Rows_s:[],ab:0}
	
	
  }
  
  componentDidMount() {
     this.state ={
	Rows: [ ],
	Rows_s: [ ],
	new_arr: [ ],
	Rows_count: 0,
	R_count: 0
        };
        axios.post(get_gallary)
            .then(Response => {
			let abc = [];
			const row_count = Response.data.data.length;
			
			for(let i=0; i<row_count;  i++){
				var cc = i+1;
				
				abc = {id:cc,src:Response.data.data[i].Image_url,caption:'Pra-nee'}
				this.state.new_arr.push(abc);
			}
			
			this.setState({Rows_s:this.state.new_arr});
			this.setState({ab: Response.data.data.length });
           // alert(JSON.stringify(this.state.ab));
    })
	.catch(err => {
		console.log(err);
	})
}

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
	  
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.ab - 1 ? 0 : this.state.activeIndex + 1;
	//alert(items.length);
	//alert(nextIndex);
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.ab - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  
  	
  
  render() {
	   //alert(JSON.stringify(this.state));
   
	
	//alert(this.state.R_count);
	const { activeIndex } = this.state;
	//alert(activeIndex);
	const items = this.state.Rows_s;
    const slides = items.map((item) => {
      return (

        <CarouselItem
          key={item.id}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <img className="imgctr" src={item.src} alt={item.altText} className="slider"  />

          <CarouselCaption className="text-danger" captionHeader={item.caption} />
        </CarouselItem>
     
      );
    });

    return (
      <div>
        {/* <style>
          {
            `.custom-tag {
                max-width: 100%;
                height: 500px;
                background: black;
              }`
          }
        </style> */}
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel>

      </div>

    );

  }
}
export default Slider;