import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import { Container,Row, Col,FormGroup,Label,Button,Card,CardBody,Table} from 'reactstrap';
import axios from 'axios';

import { baseurl } from './BaseUrl';
import FlatList from 'flatlist-react';
import Moment from 'react-moment';
import 'moment-timezone';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faMobileAlt, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


const findAllitemUrl = baseurl + 'pickingDetails';



class PickingDetails extends Component {
constructor(props) {
  super(props);
  this.state = {
       Rows: [ ],
 
  }
  let is_login = sessionStorage.getItem("logged");
  if(is_login === 'none') {
      this.props.history.push('/');
      }


  this.onDismiss = this.onDismiss.bind(this); 
 }

renderPerson = (person, idx) => {
// alert(JSON.stringify(person));
  return (
      <tr  key={`${person._id}`}>
        <td>{person.Itemname} </td>
        <td>{person.Quantity} </td>
        <td>{person.Location} </td>

        <td><Moment format="DD/MM/YYYY">
                {person.Availabledate}
            </Moment> </td> 

   
      </tr>
  );
}
onDismiss() {
  this.setState({ visible: false });
}
componentDidMount() {
       
let datad = {
user_id: sessionStorage.getItem("user_id"),

}
// alert(JSON.stringify(datad));
axios.post(findAllitemUrl,datad)
.then(Response => {
  //alert(JSON.stringify(Response.data));
this.setState({Rows:Response.data.data})

})
.catch(err => {
console.log(err);
})
}
    render() {
        return (
            <div>
                <Container>
<br></br>
                <Row className="justify-content-end mt-0">
                                        <Link to="/DealerDashboard" style={{ color: "white", textDecoration: "none" }}>
                                            <FontAwesomeIcon style={{ color: "red", fontSize: "30px" }} icon={faTimesCircle} ></FontAwesomeIcon> </Link>
                                    </Row> 


                  
                    <h2 style={{ textAlign: 'center' }}> Today Receiving Details</h2>
                    <Table id="students">
   <tr>
<th>Itemname </th>
<th>Quantity </th>
<th>Location </th>
<th>Availabledate </th>
 </tr>
                                 
<FlatList list={this.state.Rows} renderItem={this.renderPerson}/>
</Table>
                    <br></br>
                </Container><NewHeader />
            </div>
        )
    }
}
export default PickingDetails

