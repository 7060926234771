import React, { Component } from 'react';
import {
  Carousel, 
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';


const items = [
  {
    id: 1,
    // src: 'http://www.pehchanngohmr.com/wp-content/uploads/2018/02/banner-about.jpg',
    src:'./image/WomanEm.jpg',
    altText: 'World best NGOs working for world Peace',
    caption: 'World best NGOs working for world Peace',
    // url:'./Users/Vedang/ngo_ecosystem/images/NGO.jpg',

  },
  {
    id: 2,
    src:'./image/WomanEm1.jpg',
    altText: 'SOS Childrens Village of India',
    caption: 'SOS Childrens Village of India',
    //  url:'../images/NgoHeader.jpg',
  },
  {
    id: 3,
    // url: '../public/Images/image3.jpg',
    src:'./image/WomanEm3.jpg',
    altText: 'Right to Education And the Role of NGOs',
    
    // url: '../images/slide_2.jpg',
  },
  {
    id: 3,
    // url: '../public/Images/Education.jpg',
    src:'./image/WomanEm4.jpg',
    altText: 'Girls Education in Rural Area of NGO',
    
    // url: '../images/slide_2.jpg',
  },
  {
    id: 3,
    // url: '../public/Images/Education.jpg',
    src:'./image/WomanEm5.jpg',
    altText: 'Girls Education in Rural Area of NGO',
    
    // url: '../images/slide_2.jpg',
  },
  {
    id: 3,
    // url: '../public/Images/Education.jpg',
    src:'./image/WomanEm6.jpg',
    altText: 'Girls Education in Rural Area of NGO',
   
    // url: '../images/slide_2.jpg',
  }

];

class SliderWomanEm extends Component {
  constructor() {
    super();
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (

        <CarouselItem
          key={item.id}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <img className="imgctr" src={item.src} alt={item.altText} className="Acss" width='1500' height="400px" />

          <CarouselCaption className="text-danger" captionText={item.caption}  />
        </CarouselItem>

      );
    });

    return (
      <div>
       
        <style>
          {
            `.custom-tag {
                max-width: 100%;
                height: 500px;
                background: black;
              }`
          }
        </style>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel>

      </div>

    );
  }
}
export default SliderWomanEm;