import React, { Component } from 'react';
import { Container,Row, Col,FormGroup,Label,Button,Card,CardBody,Table} from 'reactstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import { baseurl } from './BaseUrl';
import FlatList from 'flatlist-react';
import Moment from 'react-moment';
import 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faMobileAlt, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
const getitembydoner = baseurl + 'myorder';

class donerView extends Component{
constructor(props) {
  super(props);
  this.state = {
       Rows: [ ],
  name:''
  }
   
 }
renderPerson = (person, idx) => {
//alert(JSON.stringify(person));
 return (
 <tr  key={`${person._id}`}>
<td>{person.Itemname} </td>
<td>{person.Quantity} </td>
<td>{person.Location} </td>
<td><Moment format="DD/MM/YYYY">
                    {person.Availabledate}
                </Moment> </td>

 </tr>
 );
}
componentDidMount() {
let userId = this.props.location.pathname;
// let userId = this.props.location;
let id = userId.split("donerView/");
let idd = id[1].split("/");
this.setState({name:idd[1]});
    let form1Obj = {
            user_id: idd[0]
           
        }
axios.post(getitembydoner,form1Obj)
.then(Response => {
 //alert(JSON.stringify(Response.data));
this.setState({Rows:Response.data.data})

})
.catch(err => {
console.log(err);
})
}
 

    render(){
        return(
            <div><br></br>
            <Container>
            <Row className="justify-content-end mt-0">
                                        <Link to="/ListingDonor" style={{ color: "white", textDecoration: "none" }}>
                                            <FontAwesomeIcon style={{ color: "red", fontSize: "30px" }} icon={faTimesCircle} ></FontAwesomeIcon> </Link>
                                    </Row>

<h2 style={{textAlign:'center'}}>Item Donated by {this.state.name}</h2>

<Table striped id="students">
  <tr>

<th>Item </th>
<th>Quantity</th>
<th> Location</th>
<th> Availabledate </th>

</tr>

<FlatList list={this.state.Rows} renderItem={this.renderPerson}/>
</Table>

               
                <br></br>
            </Container>
        </div>

        )
    }
}
export default  donerView
