import React, { Component } from 'react';
import SliderHealth from '../Components/sliderHealth';

export default class Health extends Component {
    render() {
        return (
            <div>
               
              <SliderHealth/>
                
            </div>
        );
    }
}  
