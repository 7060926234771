import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import { Container,Row,Col,Card,CardBody} from 'reactstrap';
import { baseurl } from './BaseUrl';
import axios from 'axios';
const get_homecontent = baseurl + 'get_LEADERSHIP2';
class SudhirProfile extends Component {


    constructor(props) {
        //alert("dsa");
        super(props);
        this.state = {
            data: ''}}
    
            componentDidMount() {
       
              axios.post(get_homecontent)
              .then(Response => {
              // alert(JSON.stringify(Response.data.data[0].funding));
              this.setState( {
              data:Response.data.data[0].leadership2
              } );
              
              })
              .catch(err => {
              console.log(err);
              })
              
              
                  }



    render(){
        return(
            <div className="bg">
  {/* <div >
<img src="https://i.postimg.cc/rmRm62Td/news.jpg" className="AboutUsImg"></img>
            </div> */}

<div className="divbg"> <br></br>
<p style={{color:"white",textAlign:"center",fontSize:"50px"}}> Sudhir Bahuguna</p>
            <p style={{color:"white",textAlign:"center",fontSize:"20px",fontStyle:"italic"}}> "If your actions inspire others to dream more, learn more, do more and become more, you are a leader."
           
           <Container>
          
            <p style={{color:"white",textAlign:"right",fontSize:"12px",fontStyle:"italic"}}> John Quincy Adams  </p>
            </Container>
</p><br></br></div>
            <br></br>
            <br></br>
            <Container> 


<Row className="justify-content-center">
<Col md="10">

<div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div>

</Col>
</Row>
</Container> <NewHeader />
<br></br><br></br><br></br><br></br>
            </div>
            
        )
    }

}
 export default  SudhirProfile