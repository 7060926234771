import React, {Component} from 'react';
import {Container, Col, Form, FormGroup,InputGroup,InputGroupAddon,InputGroupText, Button,Row, Label,Input,Card,CardHeader,CardBody} from 'reactstrap';
//import {Link} from 'react-router-dom';
import {Link,Route, Redirect} from 'react-router-dom';
import axios from 'axios';
import { baseurl } from './BaseUrl';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faAddressCard,faMobileAlt,faEnvelope,faGraduationCap,faPhone,faUnlock} from '@fortawesome/free-solid-svg-icons';
import { faInstagram,faFacebook,faTwitterSquare } from '@fortawesome/free-brands-svg-icons' ;
const form1sub = baseurl + 'AResourceRegistration1/form1';
const VerifyResRegOtp = baseurl + 'AVerifyResRegOtp1';

// const form3sub = baseurl + 'ResourceRegistration/form3';

//const Registrationurl = baseurl + 'registration';

class AdminSignup extends Component{
//state={isLogged:false}

    constructor(props) {
        super(props);
        this.state = {
            firstForm: true,
            secondForm: false,
     
         Name: '',
         email: '',
         phone: '',
         phoneNumber: '',
            //State for Form 2
            form2button: true,
            regOtp: ''
      
         
        }
    
        this.handleName = this.handleName.bind(this);
        this.handleemail = this.handleemail.bind(this);
     
        this.handlePhone = this.handlePhone.bind(this);
        this.handleFirstForm = this.handleFirstForm.bind(this);
        //2nd
        this.handleRegOtp = this.handleRegOtp.bind(this);
   
        this.handleSecondForm = this.handleSecondForm.bind(this);
       
    }

    handleName(e) {
        const re = /^[a-zA-Z]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ firstName: e.target.value, fnameError: false });
        }
    }
    handleemail(e) {
      
            this.setState({ lastName: e.target.value, lnameError: false });
      
    }
    //handlePhone(e) { this.setState({ phone: e.target.value }) }

////////////////////////////////////
handlePhone(e) {
    if (e.target.value.length > 10) {
        return;
    }
    this.setState({ phone: e.target.value, phoneError: false });
}

handleFirstForm(e) {
    e.preventDefault();
    if (e.target.fname.value === '') {
        this.setState({ fnameError: true })
        return;
    }
    if (e.target.lname.value === '') {
        this.setState({ lnameError: true })
        return;
    }
    if (e.target.contact.value.length < 10) {
        alert("Please Enter a valid Number");
        this.setState({ phoneError: true })
        return;
    }
    console.log("1st form submitted");
    let form1Obj = {
        Name: e.target.fname.value,
        email: e.target.lname.value,
        phoneNumber: e.target.contact.value
    }
    axios.post(form1sub, form1Obj)
        .then(response => {
            console.log(response);
            if (response.data.statusCode === 400) {
                alert("User is already Registered");
                toast.error("User is already Registered", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true
                });
            }
            else if (response.status === 200) {
                //set userid for further use into localstorage
                let reguserId = response.data._id;
                localStorage.setItem("reguserId", reguserId)
                console.log("Successfully Registered")
                this.setState({
                    firstForm: false,
                    secondForm: true
                })
            }
        })
}

/////////////////////////////

 //methods for form2
 handleRegOtp(e) {
    if (e.target.value.length > 6) {
        return;
    }
    else if (e.target.value.length === 6) {
        //confirm registration otp is wrong or right
        let phone = this.state.phone;
       
        let verifyObj = {
            phoneNumber: phone,
            otp: e.target.value
        }
        this.setState({ regOtp: e.target.value })
        axios.post(VerifyResRegOtp, verifyObj)
            .then(response => {
                console.log(response);
                if (response.data.statusCode === 200) {
                    //User Verified, enable submit button
                    alert("OTP Verified")
                    toast("OTP Verified", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true
                    });
                   
sessionStorage.setItem("logged", "admin")
window.location.reload();
this.setState({
                form2button: false,
         
               isLogged: true
            })
                }
                else if (response.data.statusCode === 400) {
                    alert("OTP is wrong")
                    toast("OTP is wrong", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true
                    });
                }
            })
    }
    else {
        this.setState({ regOtp: e.target.value })
    }
}

handleSecondForm(e) { alert(`your number ${this.state.phone} Registered successfully ` )

this.setState({
                secondForm: false,
         
               isLogged: true
            })
 
   console.log(e.target);
 
    
}



static defaultProps = {
    center: {
        lat: 28.414648,
        lng: 77.094404
    },
    zoom: 15
};

    onDismiss() {
        this.setState({ visible: false });
    }

    render(){
        return(
            <div className="bg">
            <Container >
            <br></br>
            <Row className="justify-content-center">
         
            <Col lg="6" md="8">
            <Card className=" shadow border-0 " style={{backgroundColor:"white"}}>
         
                 
              <CardBody  className="px-lg-5 py-lg-5">
              <div style={{textAlign:"center", color:"gray"}}>
              <img style={{height:"50px",width:"60px",borderRadius:"50px", backgroundColor:"white"}} src="../Image/logo.png"></img>
               
              <h3 style={{textAlign:"center", color:"gray"}}><u>Adminstrative Signup  </u></h3>
             
              <br></br>
              {this.state.firstForm &&
                <Form key={1} onSubmit={this.handleFirstForm}>
              <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                        {/* <i className="ni ni-hat-3" /> */}
                        <FontAwesomeIcon icon={faGraduationCap}  aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="text" name="fname" placeholder="Name*" value={this.state.firstName} onChange={this.handleFirstName} className={this.state.fnameError ? 'error' : null} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{backgroundColor:"white"}}>
                        {/* <i className="ni ni-email-83" /> */}
                        <FontAwesomeIcon icon={faEnvelope}  aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="text" name="lname" placeholder="Email*" value={this.state.lastName} onChange={this.handleLastName} className={this.state.lnameError ? 'error' : null} />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{backgroundColor:"white"}}>
                        {/* <i className="ni ni-email-83" /> */}
                        <FontAwesomeIcon icon={faPhone} style={{transform: "rotate(90deg)"}} aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="number" name="contact" placeholder="Phone Number*" value={this.state.phone} onChange={this.handlePhone} className={this.state.phoneError ? 'error' : null} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                                       
                                        <Button type="submit" block>Get OTP</Button>
                     
                                    </FormGroup>
                                    <hr color='#045AC4' style={{ height: '1px' }}></hr><br></br>
                                    <h5 style={{ textAlign: 'center', fontWeight: 'bold', color: '#045AC4' }} >Already Registered</h5>
                                    <Link to="/AdminLogin" >
                                        <Button style={{ color: "white", backgroundColor: "#045AC4", width: "100%" }} block>Login Here</Button>
                                    </Link>
                </Form> }
                </div>
                </CardBody>
           
               
{/* ************************************************************************************* */}

                   
<CardBody  className="px-lg-5 py-lg-5">
                         
                            {this.state.secondForm &&
                                <Form key={2} onSubmit={this.handleSecondForm}>

<FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                        {/* <i className="ni ni-hat-3" /> */}
                        <FontAwesomeIcon icon={faUnlock}  aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{ boxShadow: "none"}} type="text" name="otp" placeholder="OTP" value={this.state.regOtp} onChange={this.handleRegOtp} required />
                     </InputGroup>
                </FormGroup>

                <FormGroup>
                                                              <Button type="submit" disabled={this.state.form2button} block>Send</Button>
                                    </FormGroup>
                                   

                                     <Route exact path="/AdminSignup"
                    render={() => (this.state.isLogged ? (<Redirect to="/DashboardAdmin" />) : (null))} />
 
                                </Form>}
                         
                           
                                </CardBody >
             
               
                <br></br>
            </Card>
             
            </Col>    
       </Row>
            <br></br>
            </Container>
        </div>
        )
    }
}
export default AdminSignup
