import React, { Component } from 'react';
import { Container, Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';
class Logistic extends Component {
    render() {
        return (
           <div className="bgPage">
            <Container>
                <Row>
                    <Col md={{ size: '8', offset: '2' }}>
                        <div className='bgsignup'>
                            <h2 style={{ textAlign: 'center' }}>Logistic</h2>
                            <hr color='#045AC4' style={{ height: '2px' }}></hr>

                            <div style={{ padding: '10px' }}>
                                <Col md={12}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label><b>Status</b> </Label>
                                            </FormGroup>
                                        </Col>

                                        <Col md={4}>

                                            <Input type='radio' name='radio' ></Input> {" "}In
    
                        </Col>
                                        <Col md={4}>
                                            <Input type='radio' name='radio'></Input>{" "}Out
                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label><b>Date</b></Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={8}>
                                            <Input type="date"></Input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label><b>Time</b></Label>
                                            </FormGroup>
                                        </Col >
                                        <Col md={8}>

                                            <Input type="time"></Input>

                                        </Col >
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label><b> Pick Up Location</b></Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={8}>
                                            <Input type="text" placeholder="enter address"></Input>
                                        </Col>
                                    </Row>
                                    <div align='center'>

                                        <Button color="info" >Submit</Button>
                                    </div>
                                </Col>

                            </div>

                        </div>
                    </Col>
                </Row>
               
            </Container>
            </div>

        );
    }

}
export default Logistic;