import React, { Component } from 'react';
import {Container,Row,Col} from 'reactstrap';
class Services extends Component{
    render(){
        return(
            <div className="bg">
                <br></br>
                <Container> 
                <div>  <marquee behavior="alternate" direction="right"><b><p style={{color:"maroon"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
                </Container>
                
            <div style={{backgroundColor:"black"}}> <p style={{color:"white",textAlign:"center",fontSize:"70px"}}>Our Services</p>
            <p style={{color:"white",textAlign:"center",fontSize:"25px",fontStyle:"italic"}}>The life of a man consists not in seeing visions and in dreaming dreams,<br></br> but in active charity and in willing service.
            <br></br><br></br>
            <p style={{color:"white",textAlign:"center",fontSize:"16px",fontStyle:"italic"}}> ____ Henry Wadsworth Longfellow </p>
</p><br></br></div>

<div>
    <br></br>
    <br></br>
    <Container>
    <br></br>
    <p className="pfont"><b><u>NGO</u></b></p>
    <p className="pfont text-muted text-justify"> <b> Education has the power to transform communities. The Indian girl child is considered below her male counterparts and hence is deprived of the gift of education. However, there is a paradigm shift in the thought process of the Indians over the years and women are seen equating their male counterparts and rubbing shoulders with them in every field. Girl child education is critical in India and people, society, and volunteers should come forward and must unite to fight for it.</b> </p>
<p className="pfont text-muted text-justify"><b> Extending aid for child education to underprivileged children is synonym with Nongovernmental organizations or NGOs in India. We aim to provide education assistance to the underprivileged girls after their completion of 10+2. This can be possible with the help of government educational institutions across the country.</b></p>
<br></br>

<p style={{textAlign:"center",color:"maroon",fontSize:"20px"}}> <b>------------- is engaged in offering the following services to society:</b></p>
   <br></br>
   <br></br>

    <Row >
        <Col md={6}>

<img src="https://i.postimg.cc/65zPJc3c/e1.jpg" className="responsiveImg"style={{borderRadius:"20px"}} ></img>

</Col>

    <Col md={6}>

<p style= {{ fontSize:"18px" }}> <b>• Role of girl education and our services to achieve the end</b></p>
<br></br>
<p className="pfont text-muted text-justify"> The focal area in which we provide assistance is to empower girls through education. They receive academic help from government institutions which offer them free education or charge minimal fees. We fund their education. Education can empower girls as they become capable of skilled workers and future professionals. This acts as the perfect means to uplift their respective family status in future. They utilize the platform provided to them to stand up for their community and enhance their living conditions. </p>

<br></br>
</Col>
    </Row>

    <br></br>
<br></br>



<Row >


<Col md={6}>

<p style= {{ fontSize:"18px" }}> <b>• Aid to enroll in government educational institutions </b></p>
<br></br>
<p className="pfont text-muted text-justify">  The girls are selected on the basis of their family income which means that girls belonging to families living Below Poverty Line are selected to become the privileged one. A comprehensive sponsorship program is created to take care of higher education in the field of Medicine, Engineering, and Management courses.   </p>

<br></br>

</Col>

<Col md={6}>

<img src="https://i.postimg.cc/85hyyB9h/s4.jpg" className="responsiveImg"style={{borderRadius:"20px"}} ></img>

</Col>

</Row>

<br></br>
<br></br>



<Row >


<Col md={6}>

<img src="https://i.postimg.cc/ydXPh63j/e4.jpg" className="responsiveImg" style={{borderRadius:"20px"}}></img>

</Col>

<Col md={6}>

<p style= {{ fontSize:"18px" }}> <b>• We invite the community to join as volunteers</b></p>
<br></br>
<p className="pfont text-muted text-justify">Community support matters in our Endeavour. Volunteers from the society come forth to offer assistance in empowering girls. Since we do not accept aid in the form of cash, the volunteers help in the accumulation of aid in the form of a kind. The volunteers are engaged in spreading awareness about our NGO and adding quality to our services.  </p>

<br></br>

</Col>


</Row>

<br></br>
<br></br>

<Row> 
<Col md={6}>
    <p  style= {{ fontSize:"18px" }}><b>• We accept fund only in the form of kind</b></p>
    <br></br>
    <p className="pfont text-muted text-justify">We take pride to announce the fact that we do not accept cash from the donors. We only accept aid in the form of kind like old newspapers, furniture, clothing, etc. We motivate the people to give us these resources which can be sold as scrap. The money earned by selling such stuff is used for funding the education of the underprivileged girls looking for higher studies after 10+2.  </p>
</Col>
<Col md={6}>
<img src="https://i.postimg.cc/sgs4Qcth/d.jpg" className="responsiveImg" style={{borderRadius:"20px"}}></img>
</Col>
</Row>

<br></br>
<br></br>
<Row >


<Col md={6}>
<img src="https://i.postimg.cc/D0xPDCgb/e2.jpg" className="responsiveImg" style={{borderRadius:"20px"}}></img>


</Col>

<Col md={6}>
<p style= {{ fontSize:"18px" }}> <b>• We support Beti Bachao, Beti Padhao</b></p>
<br></br>
<p className="pfont text-muted text-justify"> The society can keep on harping endlessly about Beti Bachao, Beti Padhao but we in -----------NGO work tirelessly to achieve the end with impressive results. The rural areas still consider girls to be a burden and are obsessed with the idea to get them married to boys of their caste. But they need to change their mindset and recognize women as a career-oriented person. This is the biggest service which we aim to offer to the communities living below the poverty line. </p>

<br></br>

</Col>
</Row>

<br></br>
<br></br>


<Row >


<Col md={6}>

<p style= {{ fontSize:"18px" }}> <b>• Empowered girls can turn volunteers and give back to the community. </b></p>
<br></br>
<p className="pfont text-muted text-justify">This is a unique service offered by the girls empowered with education by our NGO. Those girls are encouraged to adopt other girl child and provide support to fund their primary education. This is seen as a great initiative which the girls can accept and further strengthens the future of other girls. </p>

<br></br>

</Col>

<Col md={6}>
<img src="https://i.postimg.cc/Jzfc9HbK/e3.jpg" className="responsiveImg"style={{borderRadius:"20px"}} ></img>


</Col>



</Row>
<br></br>
<br></br>
<br></br>

    </Container>
 

</div>

            </div>


        )
    }
}
export default Services