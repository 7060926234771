import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import SliderEdu from '../Components/sliderEducation';
import SliderWoman from '../Components/sliderWoman';
import {Container,Row,Col} from 'reactstrap';
import { baseurl } from './BaseUrl';
import axios from 'axios';
const get_homecontent = baseurl + 'get_homecontent5';

export default class Education extends Component {

    constructor(props) {
        super(props);
    
    this.state = {
            data: ''
        };
       
    }
    componentDidMount() {
   
        axios.post(get_homecontent)
        .then(Response => {
     
        this.setState( {
        data:Response.data.data[0].environment
        } );
        
        })
        .catch(err => {
        console.log(err);
        })
        
        
            }
        

    render() {
        return (
            <div className="bg">
               
               <br></br>
                <Container> 
                <div>  <marquee behavior="alternate" direction="right"><b><p style={{color:"maroon",fontStyle:"italic"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
                </Container>
                      
            <div>
                 {/* <p style={{textAlign:"center",fontSize:"50px"}}> Environment</p> */}
          
<img src="https://i.postimg.cc/hG5fvBYy/e2.jpg" className="environment"></img>
</div> 

                
<div> 
    <br></br>
    <br></br>
    <Container>
    <h1 style={{color:"#2E3092"}}>Environment:</h1>
    <br></br>
<Row> 
    <Col md={8}> 
   
    <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div> 
</Col> 
   

 

 
      
<Col md={4} style={{textAlign:"center"}}> 
    <br></br>
    <img src="https://i.postimg.cc/BvrPN3C8/env.jpg" className="responsive"  ></img>
  

    <br></br> <br></br> <br></br>
            <br></br>
               <img src="https://i.postimg.cc/XYYtVGCb/r.gif" className="rrr"></img>
        </Col>
 </Row>
  
<br></br>
<br></br>
<br></br>
    </Container> <NewHeader />
    </div>      
              
            </div>
        );
    }
}  
