import React, { Component } from 'react';
import SliderWomanEm from '../Components/sliderWoman';

export default class WomanEmpowerment extends Component {
    render() {
        return (
            <div>
            <SliderWomanEm/>
                
            </div>
        );
    }
}  
