


import React, { Component } from 'react';
import { Container, Col, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Button, Row, Label, Input, Card, CardHeader, CardBody } from 'reactstrap';
//import {Link} from 'react-router-dom';
import { Link, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { baseurl } from './BaseUrl';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faAddressCard, faMobileAlt, faEnvelope, faGraduationCap, faPhone, faUnlock, faUser } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/assets/index.css';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
// import {sms} from './sms';
const form1sub = baseurl + 'MAResourceRegistration1/form1';
const VerifyResRegOtp = baseurl + 'MAVerifyResRegOtp1';
const findAllStateUrl = baseurl + 'findAllstate1';
const form3sub = baseurl + 'MAregistrationStep3';


//const Registrationurl = baseurl + 'registration';

export default class MultipleSignUp extends Component {
    //state={isLogged:false}

    constructor(props) {
        //alert("dsa");
        super(props);
        this.state = {
            firstForm: true,
            arr_state: [],
            // secondForm: false,
            // thirdForm: false,
            //   forthForm: false,
            title: '',
            Name: '',
            email: '',
            phone: '',
            //State for Form 2
            form2button: true,
            regOtp: '',

            State_value: '',
            defaultOption: 'Select user type',


            //State for Form 3


            //state for Form 4

        }
        //  this.file1submit = this.file1submit.bind(this);
        //1st
        this.handleName = this.handleName.bind(this);
        this.handleemail = this.handleemail.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handleFirstForm = this.handleFirstForm.bind(this);
        //2nd
        this.handleRegOtp = this.handleRegOtp.bind(this);
        // this.handlepw = this.handlepw.bind(this);
        // this.handleConPw = this.handleConPw.bind(this);
        // this.handleSecondForm = this.handleSecondForm.bind(this);
        //3rd
        // this.handlePincode = this.handlePincode.bind(this);
        // this.handleaddress = this.handleaddress.bind(this);
        // this.handlePincode = this.handlePincode.bind(this);
        // this.handleCity=this.handleCity.bind(this);
        // this.handleState=this.handleState.bind(this);

        // this.handleThirdForm = this.handleThirdForm.bind(this);


    }
    handleState_value = event => {
        //alert(event.value);

        this.setState({
            defaultOption: event.value
        })
    }
    handleName(e) {
        // const re = /^[a-zA-Z]+$/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        this.setState({ firstName: e.target.value, fnameError: false });
        // }
    }
    handleemail(e) {
        // const re = /^[a-zA-Z]+$/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        this.setState({ email: e.target.value, lnameError: false });
        // }
    }
    //  handlePhone(e) { this.setState({ phone: e.target.value }) }

    ////////////////////////////////////

    handlePhone(e) {
        if (e.target.value.length > 10) {
            return;
        }
        this.setState({ phone: e.target.value, phoneError: false });
    }
    handleFirstForm(e) {
        e.preventDefault();
        if (e.target.fname.value === '') {
            this.setState({ fnameError: true })
            return;
        }
        if (e.target.lname.value === '') {
            this.setState({ lnameError: true })
            return;
        }
        if (e.target.contact.value.length < 10) {
            alert("Please Enter a valid Number");
            this.setState({ phoneError: true })
            return;
        }
        if (this.state.defaultOption === 'Select user type') {
            alert("Please select title");
           // this.setState({ phoneError: true })
            return;
        }
        //alert(this.state.defaultOption);
        console.log("1st form submitted");
        let form1Obj = {
            Name: e.target.fname.value,
            email: e.target.lname.value,
            phoneNumber: e.target.contact.value,
            title: this.state.defaultOption

        }
        //alert(JSON.stringify(form1Obj));
        localStorage.setItem("email", e.target.lname.value);
        localStorage.setItem("mobile", e.target.contact.value);
        axios.post(form1sub, form1Obj)
            .then(response => {
                console.log(response);
                if (response.data.statusCode === 400) {
                    alert("User is already Registered");
                    toast.error("User is already Registered", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true
                    });
                }
                else if (response.status === 200) {
                    //set userid for further use into localstorage
                    // alert("www");
                    let reguserId = response.data._id;
                    localStorage.setItem("reguserId", reguserId)


                    sessionStorage.setItem("user_id", response.data.userID);
                    sessionStorage.setItem("Name", response.data.Name);
                    sessionStorage.setItem("email", response.data.email);
                    sessionStorage.setItem("phoneNumber", response.data.phoneNumber);

                    console.log("Successfully Registered")
                    this.setState({
                        firstForm: false,
                        secondForm: true
                    })
                }
            })
    }

    /////////////////////////////

    //methods for form2
    handleRegOtp(e) {
        if (e.target.value.length > 6) {
            return;
        }
        else if (e.target.value.length === 6) {
            //confirm registration otp is wrong or right
            let phone = this.state.phone;
            let verifyObj = {
                phoneNumber: phone,
                otp: e.target.value
            }
            this.setState({ regOtp: e.target.value })
            axios.post(VerifyResRegOtp, verifyObj)
                .then(response => {
                    console.log(response);
                    if (response.data.statusCode === 200) {

                        alert("OTP Verified")
                        toast("OTP Verified", {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: true
                        });

                        this.setState({
                            secondForm: false,
                            thirdForm: true
                            //isLogged: true
                        })
                        //alert("rfe")

                        // sessionStorage.setItem("logged", "res")
                        //           this.setState({ isLogged:true })
                        // window.location.reload();
                    }
                    else if (response.data.statusCode === 400) {
                        alert("OTP is wrong")
                        toast("OTP is wrong", {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: true
                        });
                    }
                })
        }
        else {
            this.setState({ regOtp: e.target.value })
        }
    }






    static defaultProps = {
        center: {
            lat: 28.414648,
            lng: 77.094404
        },
        zoom: 15
    };


    onDismiss() {
        this.setState({ visible: false });
    }





    componentDidMount() {
        //console.log(this.props.id);//Selected Service Id
        axios.get(findAllStateUrl)
            .then(Response => {
                console.log(Response)
                let filtered = Response.data.filter(item => {
                    return item.Service === this.props.id
                })
                console.log(filtered);
                filtered.map(item => {
                    Object.assign(item, { showDescription: false })
                })
                console.log(filtered);
                this.setState({
                    rowsss: filtered
                })
                for (var i = 0; i < this.state.rowsss.length; i++) {
                    this.state.arr_state.push(this.state.rowsss[i].title);
                }
                //alert(JSON.stringify(this.state.arr_doner))

            })
            .catch(err => {
                console.log(err);
            })
    }



    handleMessage = event => {

	
        this.setState({
        Message: event.value
     })
         
    }



    handleThirdForm(e) {
        e.preventDefault();
        console.log(e.target);
        // alert(this.state.defaultOption);
        console.log("3rd form submitted");
        let reguserId = localStorage.getItem("reguserId");
      
        let mobile = localStorage.getItem("mobile");
        let email = localStorage.getItem("email")
      
        let subObject = {
            userId: reguserId,
         
           
            // address: e.target.address.value,
            Message: e.target.Message.value,
           
            mobile: mobile,
            email: email,
           
        }
        axios.post(form3sub, subObject)
            .then(res => {
                console.log(res.status);
       if(res.status === 200) {
      
      
      //   sms.send({
      //      "mobile": this.state.phoneNumber,
      //     "message": `is your OTP for ngo org.com login. Do not share OTP with anyone`
      // })
      
      
      
      
       {
        //alert("rfe")
           
      sessionStorage.setItem("logged", "res")
      sessionStorage.setItem("user_id",reguserId);
           this.setState({ isLogged:true })
      window.location.reload();
       }
      
       
                //handle cases based on res.status 200 or 404
                this.setState({
                    thirdForm: false,
                    forthForm: true
       
                });
        this.myRef.current.scrollTo(0, 0);
       
      
       
      
       
       
       } else {
       alert("Some thing went wrong! Please try again");
       
       }
            })
            .catch(err => {
                console.log(err)
            })
      }




    render() {
        return (
            <div>

                <Card className=" shadow border-0 " style={{ backgroundColor: "white" }}>
                    <CardBody className="px-lg-5 py-lg-5">
                        <div style={{ textAlign: "center", color: "gray" }}>
                            <img style={{ height: "40px", width: "60px" }} src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img>
                            <h3 style={{ textAlign: "center", color: "gray" }}><u> Sign up  </u></h3>

                            <br></br>
                            {this.state.firstForm &&
                                <Form key={1} onSubmit={this.handleFirstForm}>

                                    <FormGroup>

                                        {/* <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText  style={{backgroundColor:"white"}}>
                       
                        <FontAwesomeIcon icon={faUser}aria-hidden="true" ></FontAwesomeIcon>
                      </InputGroupText>
                    </InputGroupAddon> */}

                                        <Dropdown name="State_ss" options={this.state.arr_state} onChange={this.handleState_value} value={this.state.defaultOption} />

                                        {/* </InputGroup> */}
                                    </FormGroup>



                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{ backgroundColor: "white" }}>
                                                    {/* <i className="ni ni-hat-3" /> */}
                                                    <FontAwesomeIcon icon={faGraduationCap} aria-hidden="true" ></FontAwesomeIcon>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input style={{ boxShadow: "none" }} type="text" name="fname" placeholder="Name*" value={this.state.firstName} onChange={this.handleFirstName} className={this.state.fnameError ? 'error' : null} />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{ backgroundColor: "white" }}>
                                                    {/* <i className="ni ni-email-83" /> */}
                                                    <FontAwesomeIcon icon={faEnvelope} aria-hidden="true" ></FontAwesomeIcon>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input style={{ boxShadow: "none" }} type="text" name="lname" placeholder="Email*" value={this.state.email} onChange={this.handleemail} className={this.state.lnameError ? 'error' : null} />
                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{ backgroundColor: "white" }}>
                                                    {/* <i className="ni ni-email-83" /> */}
                                                    <FontAwesomeIcon icon={faPhone} style={{ transform: "rotate(90deg)" }} aria-hidden="true" ></FontAwesomeIcon>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input style={{ boxShadow: "none" }} type="number" name="contact" placeholder="Phone Number*" value={this.state.phone} onChange={this.handlePhone} className={this.state.phoneError ? 'error' : null} />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>

                                        <Button type="submit" block >Get OTP</Button>

                                    </FormGroup>
                                    {/* <hr color='#045AC4' style={{ height: '1px' }}></hr><br></br>
                                    <h5 style={{ textAlign: 'center', fontWeight: 'bold', color: '#045AC4' }} >Already Registered</h5> */}
                                    {/* <Link to="/DonorLogin" >
                                        <Button style={{ color: "white", backgroundColor: "#045AC4", width: "100%" }} block>Login Here</Button>
                                    </Link> */}
                                </Form>}
                        </div>



                        {/* ************************************************************************************* */}




                        {this.state.secondForm &&
                            <Form key={2} onSubmit={this.handleSecondForm}>

                                <FormGroup>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText style={{ backgroundColor: "white" }}>
                                                {/* <i className="ni ni-hat-3" /> */}
                                                <FontAwesomeIcon icon={faUnlock} aria-hidden="true" ></FontAwesomeIcon>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input style={{ boxShadow: "none" }} type="text" name="otp" placeholder="OTP" value={this.state.regOtp} onChange={this.handleRegOtp} required />
                                    </InputGroup>
                                </FormGroup>




                                <FormGroup>

                                    <Button type="submit" disabled={this.state.form2button} block>Send</Button>
                                </FormGroup>


                                {/* <Route exact path="/DonorSign"
                    render={() => (this.state.isLogged ? (<Redirect to="/DonorDetailsForm" />) : (null))} />
  */}





                            </Form>}






                        {this.state.thirdForm &&
                            <Form key={3} onSubmit={this.handleThirdForm}>










                                <FormGroup>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText style={{ backgroundColor: "white" }}>
                                                {/* <i className="ni ni-email-83" /> */}
                                                <FontAwesomeIcon icon={faEnvelope} aria-hidden="true" ></FontAwesomeIcon>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input style={{ boxShadow: "none" }} type="textarea" name="Message"

                                            placeholder="Message*" />
                                    </InputGroup>
                                </FormGroup>


                                {/* <Col md={3}>
                            <FormGroup>
                            <Label for="Location" >
                                <b> Location</b></Label> */}


                                {/* <GooglePlacesAutocomplete  onSelect={({ description }) => (
 this.setState({ address: description })
)}
    renderSuggestions={(active, suggestions, onSelectSuggestion) => (
      <div className="suggestions-container">
        {
          suggestions.map((suggestion) => (
            <div
              className="suggestion"
              onClick={(event) => onSelectSuggestion(suggestion, event)}
            >
              {suggestion.description}
            </div>
          ))
        }
      </div>
    )}
  /> */}
                                {/* </FormGroup> */}





                                {/* </Col> */}




                                <Button type="submit">Submit</Button>


                                {/* <Route exact path="/DonorSign"
                  render={() => (this.state.isLogged ? (<Redirect to="/DonorDetailsForm" />) : (null))} /> */}

                            </Form>}

                    </CardBody>
                    <br></br>
                </Card>




                <br></br> <br></br><br></br>

            </div>
        )
    }
}
//export default DonorSign






