import React from "react";
import NewHeader from '../Components/NewHeader';
import { Container,  Row, Col,  Button, CardBody,   Card, } from "reactstrap";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faMobileAlt, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


class RateCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        visible: true,

    };
let is_login = sessionStorage.getItem("logged");

if(is_login === 'none'|| is_login===null) {
this.props.history.push('/');
}
    this.onDismiss = this.onDismiss.bind(this);

}



  onDismiss() {
    this.setState({ visible: false });
}
    render() {
      return (
      <div>
           <div className="donorbg" >
           <Container>
           <br></br><br></br><br></br>
           <Row className="justify-content-center" >
           <Col  md="12">
           <Card className=" shadow border-0 " style={{backgroundColor:"white"}}>
           <Row className="justify-content-end mt-0 ">

                                        <Link to="/DealerDashboard" style={{ color: "white", textDecoration: "none" }}>
                                            <FontAwesomeIcon style={{ color: "red", fontSize: "30px",marginTop:"10px", marginRight:"30px"}} icon={faTimesCircle} ></FontAwesomeIcon> </Link>
                                    </Row> 
           <CardBody  className="px-lg-5 py-lg-5">
           <h2 style={{textAlign:"center"}} className=" text-muted"><u>Price Card</u></h2>
           <br></br>
           <Card className=" shadow border-0 " style={{backgroundColor:"#D5FFDF"}}>
           <CardBody  className="px-lg-3 py-lg-3">
           <h5>NOTE: </h5>  
         <p className=" text-muted" ><b> • Second-hand item prices may vary according to its condition and working.</b> </p> 
         <p className=" text-muted"> <b>• All recyclable item prices may vary according to its market prices.</b></p>
           </CardBody>
         
           </Card>
           <br></br>
<Card className=" shadow border-0 " style={{backgroundColor:"white"}}>
<CardBody  className="px-lg-3 py-lg-3">
<Row>
    <Col md={4}>
    <Card className=" shadow border-0 ">
    <CardBody style={{textAlign:"center"}} className="px-lg-3 py-lg-3">
    <img src="../Image/plastic.jpg" className="responsiveImg " style={{height:"150px",width:"150px"}}></img>
    <br></br>  
   <h5 className="text-muted">Plastic</h5>
     <h6> Rs 5/kg</h6> 
   
    </CardBody>
    </Card>
  
    </Col>
    <Col md={4}>
    <Card className=" shadow border-0 ">
    <CardBody style={{textAlign:"center"}} className="px-lg-3 py-lg-3">
    <img src="../Image/newspaper.jpg" className="responsiveImg" style={{height:"150px",width:"150px"}}></img>
    <br></br>  
   <h5 className="text-muted">Newspaper</h5>
     <h6> Rs 5/kg</h6> 
   
    </CardBody>
    </Card>


    </Col>
    <Col md={4}>
    <Card className=" shadow border-0 ">
    <CardBody style={{textAlign:"center"}} className="px-lg-3 py-lg-3">
    <img src="../Image/wood.jpg" className="responsiveImg" style={{height:"150px",width:"150px"}}></img>
    <br></br>  
   <h5 className="text-muted">Wood</h5>
     <h6> Rs 5/kg</h6> 
   
    </CardBody>
    </Card>
    </Col>
  </Row>
  <br></br>
  <Row>
    <Col md={4}>
    <Card className=" shadow border-0 ">
    <CardBody style={{textAlign:"center"}} className="px-lg-3 py-lg-3">
    <img src="../Image/iron.jpg" className="responsiveImg" style={{height:"150px",width:"150px"}}></img>
    <br></br>  
   <h5 className="text-muted">Iron</h5>
     <h6> Rs 5/kg</h6> 
   
    </CardBody>
    </Card>
  
    </Col>
    <Col md={4}>
    <Card className=" shadow border-0 ">
    <CardBody style={{textAlign:"center"}} className="px-lg-3 py-lg-3">
    <img src="../Image/glass.jpg" className="responsiveImg" style={{height:"150px",width:"150px"}}></img>
    <br></br>  
   <h5 className="text-muted">Glass</h5>
     <h6> Rs 5/kg</h6> 
   
    </CardBody>
    </Card>


    </Col>
    <Col md={4}>
    <Card className=" shadow border-0 ">
    <CardBody style={{textAlign:"center"}} className="px-lg-3 py-lg-3">
    <img src="../Image/books.jpg" className="responsiveImg" style={{height:"150px",width:"150px"}}></img>
    <br></br>  
   <h5 className="text-muted">books</h5>
     <h6> Rs 5/kg</h6> 
   
    </CardBody>
    </Card>
    </Col>
  </Row>
  <br></br>
  <Row>
    <Col md={4}>
    <Card className=" shadow border-0 ">
    <CardBody style={{textAlign:"center"}} className="px-lg-3 py-lg-3">
    <img src="../Image/ewaste.jpg" className="responsiveImg" style={{height:"150px",width:"150px"}}></img>
    <br></br>  
   <h5 className="text-muted">e-waste</h5>
     <h6> Rs 5/kg</h6> 
   
    </CardBody>
    </Card>
  
    </Col>
    <Col md={4}>
    <Card className=" shadow border-0 ">
    <CardBody style={{textAlign:"center"}} className="px-lg-3 py-lg-3">
    <img src="../Image/clothes.png" className="responsiveImg" style={{height:"150px",width:"150px"}}></img>
    <br></br>  
   <h5 className="text-muted">Clothes</h5>
     <h6> Rs 5/kg</h6> 
   
    </CardBody>
    </Card>


    </Col>
    <Col md={4}>
    <Card className=" shadow border-0 ">
    <CardBody style={{textAlign:"center"}} className="px-lg-3 py-lg-3">
    <img src="../Image/car.jpg" className="responsiveImg" style={{height:"150px",width:"150px"}}></img>
    <br></br>  
   <h5 className="text-muted">Car</h5>
     <h6> Rs 5/kg</h6> 
   
    </CardBody>
    </Card>
    </Col>
  </Row>
</CardBody>
 
</Card>
           </CardBody>
           </Card>
                         
           </Col>
           </Row>
                   <br></br><br></br><br></br>     
           </Container><NewHeader />
                            

           </div>

      </div>
      );
    }}
  export default RateCard