import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import Custommenu from './Custommenu';
import Custommenu1 from './Custommenu1';
import Custommenu2 from './Custommenu2';
import Custommenu3 from './Custommenu3';
import 'react-sticky-header/styles.css';
import StickyHeader from 'react-sticky-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import {
  UncontrolledCollapse, NavbarBrand, Collapse, Navbar, NavItem, NavLink, Nav,
  Container, Row, Col, UncontrolledDropdown,
  DropdownItem, DropdownToggle, DropdownMenu, Button, NavbarToggler

} from "reactstrap";

class OrgHeader extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);

    this.state = {
      isOpen: false
    };
    

  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  logout() {

    sessionStorage.setItem("logged", "none");
	sessionStorage.setItem("user_id","")
    window.location.reload();
  }
  render() {
    let is_login = sessionStorage.getItem("logged");
    console.log(is_login);
    if (is_login === 'res') {

      return (
        <StickyHeader
          header={
            <div className="header bg-gradient-info ">
              <>
                <Container className="px-4">
                  <Navbar
                    className="navbar-top  navbar-horizontal navbar-dark"
                    expand="xl"
                  >

                    <NavbarBrand><i style={{ fontSize: "25px", fontFamily: "Luminari", color: "white" }}><div className="logoDiv"> <img style={{ height: "40px", width: "55px", marginTop: "10px", marginLeft: "8px", marginRight: "5px" }} src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img></div></i>

                    </NavbarBrand>

                    <button className="navbar-toggler" id="navbar-collapse-main">
                      <span className="navbar-toggler-icon" />
                    </button>
                    <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
                      <div className="navbar-collapse-header d-md-none">

                      </div>
                      <Nav className="ml-auto" navbar>


                        <NavItem>
                          <NavLink className="nav-link-icon" to="/BeneficiaryView" tag={Link}>

                            <FontAwesomeIcon style={{ color: "white" }} icon={faHistory} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white" }}><b>Beneficiary History</b></span>
                          </NavLink>
                        </NavItem>


                        <NavItem>
                          <NavLink className="nav-link-icon" to="/DonorDetailsForm" tag={Link}>

                            <FontAwesomeIcon style={{ color: "white" }} icon={faHandHoldingUsd} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white" }}><b>Donate Now</b></span>
                          </NavLink>
                        </NavItem>


                        {/*  <NavItem>
              <NavLink className="nav-link-icon" to="/" tag={Link}>
                    
                     <FontAwesomeIcon style={{color:"white"}} icon={faHome}  aria-hidden="true"></FontAwesomeIcon> 
                    <span className="nav-link-inner--text" style={{color:"white"}}><b>View Donor</b></span>
                  </NavLink>
                  </NavItem> */}


                        <NavItem>
                          <NavLink className="nav-link-icon" to="/DonorDashboard" tag={Link}>

                            <FontAwesomeIcon style={{ color: "white" }} icon={faGlobe} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white" }}><b>Dashboard</b></span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink onClick={this.logout} className="nav-link-icon" to="http://pra-nee.org/" tag={Link}>

                            <FontAwesomeIcon style={{ color: "white" }} icon={faArrowAltCircleLeft} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white", fontSize: "19px" }}><b>Logout</b></span>
                          </NavLink>
                        </NavItem>



                        {/*              
               <NavItem>
<Link onClick={this.logout} to="/" className="nav-link">
                            <div className="hvr-rectangle-out">
                             <p style={{color:"white"}}>Logout</p>   
                            </div>
                        </Link>

             
                </NavItem> */}

                      </Nav>
                    </UncontrolledCollapse>

                  </Navbar>
                </Container>
              </>
            </div>
          } >
          {/* <section>
        <p>
        This section will be what the sticky header scrolls over before entering into
        sticky state. See the gif above or run the test story book to see examples.
      </p> 
     
    </section> */}
        </StickyHeader>
      )

    }

    else if (is_login === 'regment') {
      return (
        /////////////////////////////Ragman Login(Logistic)
        <StickyHeader
          header={
            <div className="header bg-gradient-info ">
              <>
                <Container className="px-4">
                  <Navbar
                    className="navbar-top  navbar-horizontal navbar-dark"
                    expand="xl"
                  >

                    <NavbarBrand to="/" tag={Link}><i style={{ fontSize: "25px", fontFamily: "Luminari", color: "white" }}><div className="logoDiv"> <img style={{ height: "40px", width: "55px", marginTop: "10px", marginLeft: "8px", marginRight: "5px" }} src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img></div></i>

                    </NavbarBrand>
                    <button className="navbar-toggler" id="navbar-collapse-main">
                      <span className="navbar-toggler-icon" />
                    </button>
                    <UncontrolledCollapse navbar toggler="#navbar-collapse-main">

                      <Nav className="ml-auto" navbar>
                        <NavItem>
                          <NavLink className="nav-link-icon" to="/" tag={Link}>
                            {/* <i className="fa fa-home" aria-hidden="true" /> */}
                            <FontAwesomeIcon style={{ color: "white" }} icon={faHome} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white" }}><b>Picking Request</b></span>
                          </NavLink>


                        </NavItem>



                        <NavItem>

                          <UncontrolledDropdown  >
                            {/* <Link to="/card">About Us</Link> */}
                            <DropdownToggle nav caret>
                              <span className="nav-link-inner--text" style={{ fontSize: "16px", color: "white" }}>  <FontAwesomeIcon style={{ color: "white" }} icon={faUserCircle} aria-hidden="true"></FontAwesomeIcon>
                                <b>About us</b> </span>
                            </DropdownToggle>
                            <DropdownMenu className="menucolor">
                              <DropdownItem>
                                <Link className="mainlink" to="/DonorSign" style={{ textDecoration: "none" }}> <b> Donor</b></Link>
                              </DropdownItem>
                              <DropdownItem>
                                <Link className="mainlink" to="/FormBeneficiarysign" style={{ textDecoration: "none" }}> <b> Beneficiary</b></Link>
                              </DropdownItem>
                              <DropdownItem>
                                <Link className="mainlink" to="/SignUp" style={{ textDecoration: "none" }}> <b> Logistic</b></Link>
                              </DropdownItem>
                              {/* <DropdownItem>
                                                         <Link className="mainlink" to="/EducationLink" style={{ textDecoration:"none"}}> <b> Ragman</b></Link>
                                                       </DropdownItem> */}

                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </NavItem>




                        <NavItem>
                          <NavLink className="nav-link-icon" to="/RateCard" tag={Link}>
                            {/* <i className="fa fa-home" aria-hidden="true" /> */}
                            <FontAwesomeIcon style={{ color: "white" }} icon={faHome} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white" }}><b>Rate Card</b></span>
                          </NavLink>


                        </NavItem>





                        <NavItem>
                          <NavLink className="nav-link-icon" to="/Gallery" tag={Link}>
                            {/* <i className="fas fa-images" /> */}
                            <FontAwesomeIcon style={{ color: "white" }} icon={faImages} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white" }} ><b>Price List</b></span>
                          </NavLink>

                        </NavItem>

                        <NavItem>
                          <NavLink className="nav-link-icon" to="/" tag={Link}>
                            {/* <i className="ni ni-planet" /> */}
                            <FontAwesomeIcon icon={faGlobe} style={{ color: "white" }} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white" }}><b>Dashboard </b></span>
                          </NavLink>
                        </NavItem>


                        <NavItem>
                          <NavLink onClick={this.logout} className="nav-link-icon" to="http://pra-nee.org/" tag={Link}>

                            <FontAwesomeIcon style={{ color: "white" }} icon={faArrowAltCircleLeft} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white", fontSize: "19px" }}><b>Logout</b></span>
                          </NavLink>
                        </NavItem>

                      </Nav>
                    </UncontrolledCollapse>

                  </Navbar>
                </Container>
              </>
            </div>
          } >
          {/* <section>
  <p>
  This section will be what the sticky header scrolls over before entering into
  sticky state. See the gif above or run the test story book to see examples.
</p> 

</section> */}
        </StickyHeader>



      )
    }

    else if (is_login == 'dealer') {

      return (
        <StickyHeader
          header={
            <div className="header bg-gradient-info ">
              <>
                <Container className="px-4">
                  <Navbar
                    className="navbar-top  navbar-horizontal navbar-dark"
                    expand="xl"
                  >

                    <NavbarBrand tag={Link}><i style={{ fontSize: "25px", fontFamily: "Luminari", color: "white" }}><div className="logoDiv"> <img style={{ height: "40px", width: "55px", marginTop: "10px", marginLeft: "8px", marginRight: "5px" }} src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img></div></i>

                    </NavbarBrand>
                    <button className="navbar-toggler" id="navbar-collapse-main">
                      <span className="navbar-toggler-icon" />
                    </button>
                    <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
                      <div className="navbar-collapse-header d-md-none">
                        <Row>
                          <Col className="collapse-brand" xs="2">
                            <Link to="/">
                              <img
                                alt="..."
                                src="assets/img/brand/argon-react.png"
                              />
                            </Link>
                          </Col>
                          <Col className="collapse-close" xs="2">
                            <button
                              className="navbar-toggler"
                              id="navbar-collapse-main"
                            >
                              <span />
                              <span />
                            </button>
                          </Col>
                        </Row>
                      </div>
                      <Nav className="ml-auto" navbar>
             



                      <NavItem>
                          <NavLink className="nav-link-icon" to="/RateCard" tag={Link}>
                            {/* <i className="fa fa-home" aria-hidden="true" /> */}
                            <FontAwesomeIcon style={{ color: "white" }} icon={faHome} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white" }}><b>Rate Card</b></span>
                          </NavLink>


                        </NavItem>




                        <NavItem>
                          <NavLink className="nav-link-icon" to="/DealerDashboard" tag={Link}>

                            <FontAwesomeIcon style={{ color: "white" }} icon={faGlobe} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white" }}><b>Dashboard</b></span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink onClick={this.logout} className="nav-link-icon" to="http://pra-nee.org/" tag={Link}>

                            <FontAwesomeIcon style={{ color: "white" }} icon={faArrowAltCircleLeft} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white", fontSize: "19px" }}><b>Logout</b></span>
                          </NavLink>
                        </NavItem>



                       



                    

                      </Nav>
                    </UncontrolledCollapse>

                  </Navbar>
                </Container>
              </>
            </div>

          } >
          {/* <section>
<p>
This section will be what the sticky header scrolls over before entering into
sticky state. See the gif above or run the test story book to see examples.
</p> 

</section> */}
        </StickyHeader>



      )




    }


    else if (is_login == 'admin') {
      return (

        <StickyHeader
          header={
            <div className="header bg-gradient-info ">
              <>
                <Container className="px-4">
                  <Navbar
                    className="navbar-top  navbar-horizontal navbar-dark"
                    expand="xl"
                  >

                    <NavbarBrand tag={Link}><i style={{ fontSize: "25px", fontFamily: "Luminari", color: "white" }}><div className="logoDiv"> <img style={{ height: "40px", width: "55px", marginTop: "10px", marginLeft: "8px", marginRight: "5px" }} src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img></div></i>

                    </NavbarBrand>
                    <button className="navbar-toggler" id="navbar-collapse-main">
                      <span className="navbar-toggler-icon" />
                    </button>
                    <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
                      <div className="navbar-collapse-header d-md-none">
                        <Row>
                          <Col className="collapse-brand" xs="2">
                            <Link to="/">
                              <img
                                alt="..."
                                src="assets/img/brand/argon-react.png"
                              />
                            </Link>
                          </Col>
                          <Col className="collapse-close" xs="2">
                            <button
                              className="navbar-toggler"
                              id="navbar-collapse-main"
                            >
                              <span />
                              <span />
                            </button>
                          </Col>
                        </Row>
                      </div>
                      <Nav className="ml-auto" navbar>
          <NavItem>
          <NavLink className="nav-link-icon" to="/DashboardAdmin" tag={Link}>
                {/* <i className="fa fa-home" aria-hidden="true" /> */}
                <FontAwesomeIcon style={{color:"white"}} icon={faHome}  aria-hidden="true"></FontAwesomeIcon>
                <span className="nav-link-inner--text" style={{color:"white"}}><b>Dashboard</b></span>
              </NavLink>

             
                  </NavItem>








                  <NavItem>
                          <UncontrolledDropdown>
                            {/* <Link to="/card">About Us</Link> */}
                            {/* <DropdownToggle  nav caret> 
                                    <span  className="nav-link-inner--text" style={{fontSize:"16px",color:"white"}}> <b>About Us</b></span>
                                   </DropdownToggle> */}
                            <DropdownToggle nav caret>
                              <span className="nav-link-inner--text" style={{ fontSize: "16px", color: "white" }}> <FontAwesomeIcon style={{ color: "white" }} icon={faFeatherAlt} aria-hidden="true"></FontAwesomeIcon><b>&nbsp;Update Content</b> </span>

                            </DropdownToggle>

                            <DropdownMenu className="menucolor" >
                              <DropdownItem>
                                <Link className="mainlink" to="/contentHome" style={{ textDecoration: "none" }}><b>Home</b></Link>
                              </DropdownItem>
                           
                             
                              

                               <Custommenu1 />

                             

                              <Custommenu2 />
                              {/* <DropdownItem>
                                <Link className="mainlink" to="/OurTeam" style={{ textDecoration: "none" }}><b>Our Team</b> </Link>
                              </DropdownItem> */}



                            
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </NavItem>
                       
                       


           
                        


                  {/* <NavItem>
          <NavLink className="nav-link-icon" to="/" tag={Link}>
            
                <FontAwesomeIcon style={{color:"white"}} icon={faHome}  aria-hidden="true"></FontAwesomeIcon>
                <span className="nav-link-inner--text" style={{color:"white"}}><b>Logistic</b></span>
              </NavLink>

             
                  </NavItem> */}


                   
                       

                        <NavItem>
              <NavLink className="nav-link-icon" to="/MasterUserType" tag={Link}>
                {/* <i className="fas fa-images" /> */}
                <FontAwesomeIcon style={{color:"white"}} icon={faImages}  aria-hidden="true"></FontAwesomeIcon>
                <span className="nav-link-inner--text" style={{color:"white"}} ><b>Add UserType</b></span>
              </NavLink>

              </NavItem>
             
            <NavItem>
              <NavLink className="nav-link-icon" to="/MasterDonorForm" tag={Link}>
                {/* <i className="ni ni-planet" /> */}
                <FontAwesomeIcon  icon={faGlobe} style={{color:"white"}} aria-hidden="true"></FontAwesomeIcon>
                <span className="nav-link-inner--text" style={{color:"white"}}><b>Add Donating Item </b></span>
              </NavLink>
            </NavItem>
         
            <NavItem>
                          <NavLink onClick={this.logout} className="nav-link-icon" to="http://pra-nee.org/" tag={Link}>

                            <FontAwesomeIcon style={{ color: "white" }} icon={faArrowAltCircleLeft} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white", fontSize: "19px" }}><b>Logout</b></span>
                          </NavLink>
                        </NavItem>
            </Nav>
      
      
                    </UncontrolledCollapse>

                  </Navbar>
                </Container>
              </>
            </div>

          } >

          {/* <section>
<p>
This section will be what the sticky header scrolls over before entering into
sticky state. See the gif above or run the test story book to see examples.
</p> 

</section> */}

        </StickyHeader>


      )



    }






    else {
      return (
        <StickyHeader
          header={

            <div className="header bg-gradient-info " >
              <>
                <Navbar
                  className="navbar-top  navbar-horizontal navbar-dark"
                  expand="xl"
                >
                  <Container className="px-4">
                 
                    <NavbarBrand to="/" tag={Link}><i style={{ fontSize: "25px", fontFamily: "Luminari", color: "white" }}><div className="logoDiv"> <img style={{ height: "40px", width: "55px", marginTop: "10px", marginLeft: "8px", marginRight: "5px" }} src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img></div></i>

                    </NavbarBrand>
                    <button className="navbar-toggler" id="navbar-collapse-main">
                      <span className="navbar-toggler-icon" />
                    </button>
                    <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
                      <div className="navbar-collapse-header d-md-none">
                        {/* <Row>
                  <Col className="collapse-brand" xs="2">
                    <Link to="/">
                      <img
                        alt="..."
                        src="assets/img/brand/argon-react.png"
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="2">
                    <button
                      className="navbar-toggler"
                      id="navbar-collapse-main"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row> */}
                      </div>
                      <Nav className="ml-auto" navbar>
                        <NavItem>
                          <NavLink className="nav-link-icon" to="/" tag={Link}>
                            {/* <i className="fa fa-home" aria-hidden="true" /> */}
                            <FontAwesomeIcon style={{ color: "white" }} icon={faHome} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white" }}><b>Home</b></span>
                          </NavLink>


                        </NavItem>








                        <NavItem>
                          <UncontrolledDropdown>
                            {/* <Link to="/card">About Us</Link> */}
                            {/* <DropdownToggle  nav caret> 
                                    <span  className="nav-link-inner--text" style={{fontSize:"16px",color:"white"}}> <b>About Us</b></span>
                                   </DropdownToggle> */}
                            <DropdownToggle nav caret>
                              <span className="nav-link-inner--text" style={{ fontSize: "16px", color: "white" }}> <FontAwesomeIcon style={{ color: "white" }} icon={faFeatherAlt} aria-hidden="true"></FontAwesomeIcon><b>&nbsp;About</b> </span>

                            </DropdownToggle>

                            <DropdownMenu className="menucolor" >
                              <DropdownItem>
                                <Link className="mainlink" to="/Pranee1" style={{ textDecoration: "none" }}><b>प्रnee</b></Link>
                              </DropdownItem>
                              {/* <DropdownItem>
                                            <Link className="mainlink" to="/Overview" style={{textDecoration:"none"}}><b>Overview </b>  </Link>
                                        </DropdownItem> */}
                              <DropdownItem>
                                <Link className="mainlink" to="/Vision" style={{ textDecoration: "none" }}><b>Vision</b> </Link>
                              </DropdownItem>
                              {/* <DropdownItem>
                                <Link className="mainlink" to="/Mentor" style={{ textDecoration: "none" }}><b>Mentor</b> </Link>
                              </DropdownItem> */}
                              {/*                                        
                                        <DropdownItem divider/>

                            < h6 style={{textAlign:"center"}}><u>Leadership</u> </h6> 
                            <DropdownItem>
                              <Link className="mainlink" to="/GargiProfile" style={{ textDecoration: "none" }}><b>Gargi Bahuguna</b></Link>
                            </DropdownItem> 
                            <DropdownItem>
                              <Link className="mainlink" to="/SudhirProfile" style={{ textDecoration: "none" }}><b>Sudhir Bahuguna</b></Link>
                            </DropdownItem>
                          

                            <DropdownItem divider/> */}

                              <Custommenu />

                              <DropdownItem>
                                <Link className="mainlink" to="/OurTeam" style={{ textDecoration: "none" }}><b>Our Team</b> </Link>
                              </DropdownItem>

                              {/* <DropdownItem>
                                            <Link className="mainlink" to="/Aim"style={{textDecoration:"none"}}> <b>Aim</b> </Link>
                                        </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </NavItem>
                        <NavItem>
                        <UncontrolledDropdown>
                            <DropdownToggle nav caret>
                              <span className="nav-link-inner--text" style={{ fontSize: "16px", color: "white" }}> <FontAwesomeIcon style={{ color: "white" }} icon={faNetworkWired} aria-hidden="true"></FontAwesomeIcon><b>&nbsp;Activities</b> </span>
                              <DropdownMenu className="menucolor">
                                {/* <DropdownItem>
                                            <Link className="mainlink" to="/Services"style={{textDecoration:"none"}}> <b>Services</b> </Link>
                                        </DropdownItem> */}
                                <DropdownItem>
                                  <Link className="mainlink" to="/Consultancy" style={{ textDecoration: "none" }}> <b>Counselling</b> </Link>
                                </DropdownItem>

                                <DropdownItem>
                                  <Link className="mainlink" to="/Funding" style={{ textDecoration: "none" }}><b> Funding</b></Link>
                                </DropdownItem>
                                <DropdownItem>
                                <Link className="mainlink" to="/Gallery" style={{ textDecoration: "none" }}> <b>Gallery</b> </Link>
                                </DropdownItem>
                                
                                <DropdownItem>
                                <Link className="mainlink" to="/Faq" style={{ textDecoration: "none" }}> <b>FAQ</b> </Link>
                                </DropdownItem>
                              </DropdownMenu>
                            </DropdownToggle>
                          </UncontrolledDropdown>  </NavItem> 
                        <NavItem>
                          <UncontrolledDropdown  >
                            {/* <Link className="mainlink" to="/card">About Us</Link> */}
                            <DropdownToggle nav caret>
                              <span className="nav-link-inner--text" style={{ fontSize: "16px", color: "white" }}> <FontAwesomeIcon style={{ color: "white" }} icon={faMagic} aria-hidden="true"></FontAwesomeIcon><b>&nbsp;Impact</b> </span>
                            </DropdownToggle>
                            <DropdownMenu className="menucolor" >
                              <DropdownItem>
                                <Link className="mainlink" to="/Education" style={{ textDecoration: "none" }}> <b> Women Empowerment</b></Link>
                              </DropdownItem>
                              <DropdownItem>
                                <Link className="mainlink" to="/Environment" style={{ textDecoration: "none" }}> <b> Environment</b></Link>
                              </DropdownItem> 
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </NavItem> 
                        <NavItem>
                          {/* <UncontrolledDropdown  >
                            <DropdownToggle nav caret>
                              <span className="nav-link-inner--text" style={{ fontSize: "16px", color: "white" }}> <FontAwesomeIcon style={{ color: "white" }} icon={faImages} aria-hidden="true"></FontAwesomeIcon><b>&nbsp;Gallery</b> </span>
                              <DropdownMenu className="menucolor" >
                                <DropdownItem>
                                  <Link className="mainlink" to="/Gallery" style={{ textDecoration: "none" }}> <b>Photos</b> </Link>
                                </DropdownItem> 
                              
                              </DropdownMenu>
                            </DropdownToggle>
                          </UncontrolledDropdown> */}
                        </NavItem> 
                        <NavItem>
                          <NavLink className="nav-link-icon" to="/Contact" tag={Link}>
                            {/* <i className="fas fa-address-card" /> */}
                            <FontAwesomeIcon style={{ color: "white" }} icon={faAddressCard} aria-hidden="true"></FontAwesomeIcon>
                            <span className="nav-link-inner--text" style={{ color: "white" }}><b>Contact </b></span>
                          </NavLink>  
                        </NavItem>
                      


                        <NavItem> 
                          <UncontrolledDropdown >
                            {/* <Link to="/card">About Us</Link> */}
                            <DropdownToggle nav caret>
                              <span className="nav-link-inner--text" style={{ fontSize: "16px", color: "white" }}>  <FontAwesomeIcon style={{ color: "white" }} icon={faUserCircle} aria-hidden="true"></FontAwesomeIcon> <b>Get Involved</b> </span>
                            </DropdownToggle>
                            <DropdownMenu className="menucolor">
                            <DropdownItem>
                                <Link className="mainlink" to="/RWA" style={{ textDecoration: "none" }}> <b> RWA</b></Link>
                              </DropdownItem>
                              <DropdownItem>
                                <Link className="mainlink" to="/OtherNgo" style={{ textDecoration: "none" }}> <b> NGO</b></Link>
                              </DropdownItem> 
                              <DropdownItem>
                                <Link className="mainlink" to="/Corporate" style={{ textDecoration: "none" }}> <b> CSR</b></Link>
                              </DropdownItem> 
                              <DropdownItem>
                                <Link className="mainlink" to="/GOI" style={{ textDecoration: "none" }}> <b> GOI</b></Link>
                              </DropdownItem> 

  
                              </DropdownMenu>
                          </UncontrolledDropdown>
                        </NavItem> 




                        <NavItem> 
                          <UncontrolledDropdown >
                            {/* <Link to="/card">About Us</Link> */}
                            <DropdownToggle nav caret>
                              <span className="nav-link-inner--text" style={{ fontSize: "16px", color: "white" }}>  <FontAwesomeIcon style={{ color: "white" }} icon={faUserCircle} aria-hidden="true"></FontAwesomeIcon> <b>Join</b> </span>
                            </DropdownToggle>
                            <DropdownMenu className="menucolor">
                              <DropdownItem>
                                <Link className="mainlink" to="/DonorSign" style={{ textDecoration: "none" }}> <b> Donor</b></Link>
                              </DropdownItem>
                              <DropdownItem>
                                <Link className="mainlink" to="/FormBeneficiarysign" style={{ textDecoration: "none" }}> <b>Beneficiary</b></Link>
                              </DropdownItem>
                              <DropdownItem>
                                <Link className="mainlink" to="/DealerSignup" style={{ textDecoration: "none" }}> <b>Vendor</b></Link>
                              </DropdownItem>

                              <DropdownItem>
                                <Link className="mainlink" to="/SignUp" style={{ textDecoration: "none" }}> <b>Ragman</b></Link>
                              </DropdownItem>
                             
                             
                             
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </NavItem> 

                        <NavItem>
                          <UncontrolledDropdown>
                            <DropdownToggle nav caret>
                              <span className="nav-link-inner--text" style={{ fontSize: "16px", color: "white" }}>
                                {/* <i className="ni ni-key-25" /> */}
                                <FontAwesomeIcon style={{ transform: "rotate(180deg)", color: "white" }} icon={faKey} aria-hidden="true"></FontAwesomeIcon>
                                &nbsp;<b>Login</b></span>
                            </DropdownToggle> 

                            <DropdownMenu className="menucolor" >
                              <DropdownItem> 
                                <Link className="mainlink" to="/DonorLogin" style={{ textDecoration: "none" }}><b>Donor</b></Link>
                              </DropdownItem> 
                              <DropdownItem>
                                <Link className="mainlink" to="/BeneficiaryLogin" style={{ textDecoration: "none" }}><b>Beneficiary</b></Link>
                              </DropdownItem>  
                              <DropdownItem>
                                <Link className="mainlink" to="/DealerLogin" style={{ textDecoration: "none" }}><b>Vendor</b></Link>
                              </DropdownItem> 
                              <DropdownItem>
                                <Link className="mainlink" to="/RagmanLogin" style={{ textDecoration: "none" }}><b>Ragman</b></Link>
                              </DropdownItem>
                              <DropdownItem>
                                <Link className="mainlink" to="/AdminLogin" style={{ textDecoration: "none" }}><b>Adminstration</b></Link>
                              </DropdownItem> 
                            </DropdownMenu>
                          </UncontrolledDropdown>  

                        </NavItem>


                      </Nav>
                    </UncontrolledCollapse>
                     
                  </Container>
                </Navbar>
              </>
            </div>
          } >

          {/* <section>
      <p>
      This section will be what the sticky header scrolls over before entering into
      sticky state. See the gif above or run the test story book to see examples.
      </p> 
      
      </section> */}

        </StickyHeader>
      )
    }





  }
}

export default OrgHeader;
