import React, { Component } from 'react';
import { Card, Container, CardTitle, CardText, Row, Col,CardHeader,CardImg } from 'reactstrap';
class Volunteer extends Component{
    render(){
        return(
         
  <div className="bg">
<br></br>

    <h2 style={{textAlign:"center"}}>Volunteers</h2>
    <br></br>
    <h4 className="text-muted" style={{textAlign:"center",fontStyle:"italic"}}><b>"The work of volunteers impacts on all our lives, Even if we are not aware of it.”</b></h4>
<br></br>
<Container className="bgfund">
<Row>

  <Col md={4}>
  <br></br>
    <Card style={{borderRadius:"30px"}}> 
  <a href="./image/Volunteer.jpg"  ><img src="./image/Volunteer.jpg"  className="responsiveImg" style={{borderRadius:"20px"}}></img></a>
  </Card>                     
  </Col>

  <Col md={4}>
  <br></br>
  <Card style={{borderRadius:"20px"}}>
  <a href="./image/vol1.jpg"  ><img src="./image/vol1.jpg" className="responsiveImg" style={{borderRadius:"20px"}}></img></a>
  </Card> 
  </Col>

 

  <Col md={4}>
  <br></br>
  <Card style={{borderRadius:"20px"}}>
  <a href="./image/Volunteer3.jpg"  ><img src="./image/Volunteer3.jpg" className="responsiveImg" style={{borderRadius:"20px"}}></img></a>
  </Card> 
  </Col>
  </Row>
  
<br></br>

<Row>
  <Col md={4}>
  <br></br>
  <Card style={{borderRadius:"20px"}}>
  <a href="./image/Volunteer4.PNG"  ><img src="./image/Volunteer4.PNG" style={{borderRadius:"20px"}} className="responsiveImg"></img></a>
  </Card> 
  </Col>

  <Col md={4}>
  <br></br>
  <Card style={{borderRadius:"20px"}}>
  <a href="./image/volunteer23.PNG"  ><img src="./image/volunteer23.PNG" style={{borderRadius:"20px"}} className="responsiveImg"></img></a>
  </Card> 
  </Col>

  <Col md={4}>
  <br></br>
  <Card style={{borderRadius:"20px"}}>
  <a href="./image/Volunteer8.jpg"  ><img src="./image/Volunteer8.jpg" style={{borderRadius:"20px"}} className="responsiveImg"></img></a>
  </Card> 
  </Col>

</Row>
<br></br>
<br></br>

</Container>
</div>
        );
    }
}

export default Volunteer;

