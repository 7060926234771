import React, { Component } from 'react';
import { Container,Row,Col,Card,CardBody} from 'reactstrap';
import NewHeader from '../Components/NewHeader';
import { baseurl } from './BaseUrl';
import axios from 'axios';

const get_homecontent = baseurl + 'get_homecontent3';

class Mentor extends Component {

  constructor(props) {
    super(props);

this.state = {
        data: ''
    };
   
}
componentDidMount() {
   
  axios.post(get_homecontent)
  .then(Response => {
  // alert(JSON.stringify(Response.data.data[0].ourteam));
  this.setState( {
  data:Response.data.data[0].ourteam
  } );
  
  })
  .catch(err => {
  console.log(err);
  })
  
  
      }
  

    render(){
        return(
            <div className="bg">
  {/* <div >
<img src="https://i.postimg.cc/YS8dYgpY/mentor.jpg" className="AboutUsImg"></img> \

            </div> */}

<div className="divbg"> 
<br></br><p style={{color:"white",textAlign:"center",fontSize:"50px"}}>Our Team</p>
          
           <p style={{color:"white",textAlign:"center",fontSize:"20px",fontStyle:"italic"}}>"Coming together is a beginning, staying together is progress, and working together is success." </p>
            <Container> 
            <p style={{color:"white",textAlign:"right",fontSize:"12px",fontStyle:"italic"}}>  Henry Ford  </p>
            </Container>
<br></br>

</div>
<br></br><br></br>
           
            <Container> 


<Row className="justify-content-center">
<Col md="11">

<article>
 
 <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div>
</article><hr color="#2E3092"  ></hr>
<br></br>
<br></br>



<br></br>
</Col>
</Row>
</Container> <NewHeader />
            </div>
        )
    }

}
 export default  Mentor