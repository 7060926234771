import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import {
    Container, Card, CardBody, Button, CardTitle, CardText,
    Row, Col, Form, FormGroup, InputGroup, InputGroupText, InputGroupAddon, Input,
} from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHeart, faMapMarkerAlt, faPenAlt, faPhone, faStickyNote, faSignature, faAlignCenter } from '@fortawesome/free-solid-svg-icons';
import { } from '@fortawesome/free-brands-svg-icons';

const Marker = props => {
    return <div></div>
}

class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 28.414648,
            lng: 77.094404
        },
        zoom: 15
    };
    constructor(props) {
        super(props);

        this.state = {
            visible: true,
            Name: "",
            Email: "",
            Subject: "",
            Message: ""
        };

        this.onDismiss = this.onDismiss.bind(this);

    }
    handleNameChange = event => {
        this.setState({
            Name: event.target.value
        })
    }

    handleEmailChange = event => {
        this.setState({
            Email: event.target.value
        })
    }
    handleSubjectChange = event => {
        this.setState({
            Subject: event.target.value
        })
    }

    handleMessageChange = event => {
        this.setState({
            Message: event.target.value
        })
    }

    onDismiss() {
        this.setState({ visible: false });
    }

    handleSubmit = event => {
        alert(` Hi ${this.state.Name} ,your message sent `)

        const user = {
            Name: this.state.Name,
            Email: this.state.Email,
            Subject: this.state.Subject,
            Message: this.state.Message,

        };
        axios.post('http://localhost:5100/Contactques/', user)
            .then(res => {
                console.log(res);
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        event.preventDefault();
    }
    render() {
        return (

            <div className="Background ">
                <Container className="px-4">
                    <br></br>
                    <Row className="justify-content-center">

                        <h2 style={{ color: "white" }}>CONTACT</h2>

                    </Row>
                    <hr color="white"></hr>

                    <Row className="justify-content-center">
                        <h5 style={{ color: "white" }}>We'd <FontAwesomeIcon icon={faHeart} ></FontAwesomeIcon> to help!</h5>
                    </Row>

                    <Row className="justify-content-center">
                        <p style={{ color: "white" }}>pra-nee always welcome new ideas .Feel free to say hello!</p>
                    </Row>

                    <br></br><br></br>

                    <Row className="justify-content-center">
                        <Col md={5}>
                            <Form onSubmit={this.handleSubmit}>
                                <FormGroup>

                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText style={{ backgroundColor: "white" }}>

                                                <FontAwesomeIcon className="hcolor" icon={faSignature} aria-hidden="true" ></FontAwesomeIcon>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input style={{ boxShadow: "none" }} type="text" placeholder="Enter your name*" value={this.state.Name}
                                            onChange={this.handleNameChange} required />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>

                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText style={{ backgroundColor: "white" }}>

                                                <FontAwesomeIcon className="hcolor" icon={faEnvelope} aria-hidden="true" ></FontAwesomeIcon>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input style={{ boxShadow: "none" }} type="email" placeholder="Enter email*" value={this.state.Email}
                                            onChange={this.handleEmailChange} required />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>

                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText style={{ backgroundColor: "white" }}>

                                                <FontAwesomeIcon className="hcolor" icon={faPenAlt} aria-hidden="true" ></FontAwesomeIcon>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input style={{ boxShadow: "none" }} type="text" placeholder="Subject*" value={this.state.Subject}
                                            onChange={this.handleSubjectChange} required />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>

                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText style={{ backgroundColor: "white" }}>

                                                <FontAwesomeIcon className="hcolor" icon={faStickyNote} aria-hidden="true" ></FontAwesomeIcon>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input style={{ boxShadow: "none" }} type="textarea" placeholder="E-mail content*" value={this.state.Message}
                                            onChange={this.handleMessageChange} />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Button className="btn btn-info" block><b>Send</b></Button>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={6}>


                            <Row className="justify-content-center" style={{paddingTop:"30px"}}>
                                <Col sm ={1} xs={1} md={2}>
                                    <FontAwesomeIcon style={{ color: "white", fontSize: "20px" }} icon={faMapMarkerAlt} ></FontAwesomeIcon> <br></br>
                                    <FontAwesomeIcon style={{ color: "white", fontSize: "20px", transform: "rotate(90deg)", marginTop: "50px" }} icon={faPhone} ></FontAwesomeIcon> <br></br>
                                    <FontAwesomeIcon style={{ color: "white", fontSize: "20px", marginTop: "20px" }} icon={faEnvelope} ></FontAwesomeIcon><br></br>

                                </Col>
                                <Col sm ={11} xs={11} md={8}>
                                    <div style={{ textAlign: "Center",color:"white" }}>
                                        RSN Bahuguna Foundation
                                        2524 C 2 ,
                                        Vasant Kunj New Delhi,
                                        Pincode-110070

                               <br></br><br></br>
                                9540711700
                                <br></br> <br></br>
                                Runjhun@wayme.in
                            </div>

                                </Col>

                            </Row>
                            <br></br><br></br>
                            <hr color="white"></hr>
                            <Row className="justify-content-center">
                               <p style={{color:"white"}}><img style={{height:"30px",width:"45px"}} src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img> www.pra-nee.org</p>
                            </Row>
                        </Col>
                    </Row>


                </Container>

                <NewHeader />

                <br></br>
                <br></br>
                <br></br>
               

            </div>
        )
    }
}
export default Contact

