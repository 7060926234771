import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import { Container, Col, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Button, Row, Label, Input, Card, CardHeader, CardBody } from 'reactstrap';
//import {Link} from 'react-router-dom';
import { Link, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import MultipleSignUp from './MultipleSignUp';
import { baseurl } from './BaseUrl';
import { ToastContainer, toast } from 'react-toastify';

const get_homecontent = baseurl + 'get_Rwa';
// import {sms} from './sms';
const form1sub = baseurl + 'ResourceRegistration/form1';
const VerifyResRegOtp = baseurl + 'VerifyResRegOtp'
const form2sub = baseurl + 'ResourceRegistration/form2';
const form3sub = baseurl + 'ResourceRegistration/form3';
const postalCode = baseurl + 'postalcode';


//const Registrationurl = baseurl + 'registration';

export default class DonorSign extends Component {
  //state={isLogged:false}

  constructor(props) {
    //alert("dsa");
    super(props);
    this.state = {
		data: '',
      firstForm: true,
      secondForm: false,
      thirdForm: false,
      //   forthForm: false,
      Name: '',
      email: '',
      phone: '',
      //State for Form 2
      form2button: true,
      regOtp: '',

      //State for Form 3
      pincode: '',
      City: '',
      State: '',
      address: ''


      //state for Form 4

    }
    //  this.file1submit = this.file1submit.bind(this);
    //1st
    this.handleName = this.handleName.bind(this);
    this.handleemail = this.handleemail.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleFirstForm = this.handleFirstForm.bind(this);
    //2nd
    this.handleRegOtp = this.handleRegOtp.bind(this);
    // this.handlepw = this.handlepw.bind(this);
    // this.handleConPw = this.handleConPw.bind(this);
    this.handleSecondForm = this.handleSecondForm.bind(this);
    //3rd
    // this.handlePincode = this.handlePincode.bind(this);
    // this.handleaddress = this.handleaddress.bind(this);
    this.handlePincode = this.handlePincode.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleState = this.handleState.bind(this);

    this.handleThirdForm = this.handleThirdForm.bind(this);


  }

  handleName(e) {
    // const re = /^[a-zA-Z]+$/;
    // if (e.target.value === '' || re.test(e.target.value)) {
    this.setState({ firstName: e.target.value, fnameError: false });
    // }
  }
  handleemail(e) {
    // const re = /^[a-zA-Z]+$/;
    // if (e.target.value === '' || re.test(e.target.value)) {
    this.setState({ email: e.target.value, lnameError: false });
    // }
  }
  //  handlePhone(e) { this.setState({ phone: e.target.value }) }

  ////////////////////////////////////

  handlePhone(e) {
    if (e.target.value.length > 10) {
      return;
    }
    this.setState({ phone: e.target.value, phoneError: false });
  }
  handleFirstForm(e) {
    e.preventDefault();
    if (e.target.fname.value === '') {
      this.setState({ fnameError: true })
      return;
    }
    if (e.target.lname.value === '') {
      this.setState({ lnameError: true })
      return;
    }
    if (e.target.contact.value.length < 10) {
      alert("Please Enter a valid Number");
      this.setState({ phoneError: true })
      return;
    }
    console.log("1st form submitted");
    let form1Obj = {
      Name: e.target.fname.value,
      email: e.target.lname.value,
      phoneNumber: e.target.contact.value
    }
    localStorage.setItem("email", e.target.lname.value);
    localStorage.setItem("mobile", e.target.contact.value);
    axios.post(form1sub, form1Obj)
      .then(response => {
        console.log(response);
        if (response.data.statusCode === 400) {
          alert("User is already Registered");
          toast.error("User is already Registered", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true
          });
        }
        else if (response.status === 200) {
          //set userid for further use into localstorage
          // alert("www");
          let reguserId = response.data._id;
          localStorage.setItem("reguserId", reguserId)


          sessionStorage.setItem("user_id", response.data.userID);
          sessionStorage.setItem("Name", response.data.Name);
          sessionStorage.setItem("email", response.data.email);
          sessionStorage.setItem("phoneNumber", response.data.phoneNumber);

          console.log("Successfully Registered")
          this.setState({
            firstForm: false,
            secondForm: true
          })
        }
      })
  }

  /////////////////////////////

  //methods for form2 
  handleRegOtp(e) {
    if (e.target.value.length > 6) {
      return;
    }
    else if (e.target.value.length === 6) {
      //confirm registration otp is wrong or right
      let phone = this.state.phone;
      let verifyObj = {
        phoneNumber: phone,
        otp: e.target.value
      }
      this.setState({ regOtp: e.target.value })
      axios.post(VerifyResRegOtp, verifyObj)
        .then(response => {
          console.log(response);
          if (response.data.statusCode === 200) {

            alert("OTP Verified")
            toast("OTP Verified", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true
            });

            this.setState({
              secondForm: false,
              thirdForm: true
              //isLogged: true
            })
            //alert("rfe")

            // sessionStorage.setItem("logged", "res")
            //           this.setState({ isLogged:true })
            // window.location.reload();
          }
          else if (response.data.statusCode === 400) {
            alert("OTP is wrong")
            toast("OTP is wrong", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true
            });
          }
        })
    }
    else {
      this.setState({ regOtp: e.target.value })
    }
  }
  // handlepw(e) { this.setState({ pw: e.target.value }) }
  // handleConPw(e) { this.setState({ conpw: e.target.value }) }
  handleSecondForm(e) {
    // alert(`your number ${this.state.phone} Registered successfully ` )

    console.log(e.target);

    axios.post(form2sub)
      .then(res => {
        console.log(res.status);
        if (res.status === 200) {
          //handle cases based on res.status 200 or 404
          alert("successfully registered");
          this.setState({
            secondForm: false,
            thirdForm: true,
            //  isLogged: true
          })
        } else {
          alert("Some thing went worng! Please try again");
        }
      })
      .catch(err => {
        console.log(err)
      })
  }


  //////////////////////////////////////////////////

  handlePincode(e) {
    if (e.target.value.length >= 7) {
      return;
    }
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ pincode: e.target.value });
      if (e.target.value.length === 6) {
        //send axios request
        console.log("send axios request");
        axios.post(postalCode, { postalCode: e.target.value })
          .then(response => {
            // handle success
            console.log(response);
            let data = response.data.PostOffice[0];
            this.setState({
              //  city: data.District,
              // state: data.State
              city: data.District,
              state: data.State
            })
          })
          .catch(error => {
            // handle error
            console.log(error);
          })
          .finally(function () {
            // always executed
          });
      }
    }
  }



  handleCity = event => {


    this.setState({
      City: event.value
    })

  }
  handleState = event => {
    //alert(event.value);


    this.setState({
      State: event.value

    })


  }

  onLocationSelect(data, details) {
    alert("fdf");
    console.log(data, details);
    this.setState({
      region: {
        latitude: details.geometry.location.lat,
        longitude: details.geometry.location.lng,
        //latitudeDelta: LATITUDE_DELTA,
        //longitudeDelta: LONGITUDE_DELTA,
      }
    })
  }


  handleThirdForm(e) {
    e.preventDefault();
    console.log(e.target);
    // alert(this.state.defaultOption);
    console.log("3rd form submitted");
    let reguserId = localStorage.getItem("reguserId");

    let mobile = localStorage.getItem("mobile");
    let email = localStorage.getItem("email")

    let subObject = {
      userId: reguserId,


      // address: e.target.address.value,
      pincode: e.target.pincode.value,
      City: e.target.City.value,
      State: e.target.State.value,
      location: e.target.location.value,
      mobile: mobile,
      email: email,

    }
    axios.post(form3sub, subObject)
      .then(res => {
        console.log(res.status);
        if (res.status === 200) {


          //   sms.send({
          //      "mobile": this.state.phoneNumber,
          //     "message": `is your OTP for ngo org.com login. Do not share OTP with anyone`
          // })




          {
            //alert("rfe")

            sessionStorage.setItem("logged", "res")
            this.setState({ isLogged: true })
            window.location.reload();
          }


          //handle cases based on res.status 200 or 404
          this.setState({
            thirdForm: false,
            forthForm: true

          });
          this.myRef.current.scrollTo(0, 0);






        } else {
          alert("Some thing went wrong! Please try again");

        }
      })
      .catch(err => {
        console.log(err)
      })
  }


  static defaultProps = {
    center: {
      lat: 28.414648,
      lng: 77.094404
    },
    zoom: 15
  };


  onDismiss() {
    this.setState({ visible: false });
  }

  
     componentDidMount() {
   
        axios.post(get_homecontent)
        .then(Response => {
        // alert(JSON.stringify(Response.data.data[0].funding));
        this.setState( {
        data:Response.data.data[0].rwa
        } );
        
        })
        .catch(err => {
        console.log(err);
        })
        
        
            }
  
  
  
  
  
  
  
  render() {
    return (
      <div className="bg">
        {/* <div className="donorbg">  */}
        <br></br>
        <Container>
          <div>  <marquee behavior="alternate" direction="right"><b><p style={{ color: "maroon", fontStyle: "italic" }}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
        </Container>

        <div className="divbg"><br></br> <p style={{ color: "white", textAlign: "center", fontSize: "50px" }}>RWA</p>
          <p style={{ color: "white", textAlign: "center", fontSize: "20px", fontStyle: "italic" }}>Residents Welfare Association<br></br> <br></br>

          </p> </div>
        <Container >

          <br></br> <br></br>
          <Row className="justify-content-center">

          <Col md="7">
            <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div>


                </Col>  
            <Col md="5">
              <br></br>
              <MultipleSignUp />
            </Col>

          </Row>
          <br></br> <br></br><br></br>
        </Container> <NewHeader />
      </div>
    )
  }
}
//export default DonorSign

