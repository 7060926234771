

import React, { Component } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, Button, Card, CardBody, Form } from 'reactstrap';
import {Link,Route,Redirect} from 'react-router-dom';
import axios from 'axios';
import { Multiselect } from 'multiselect-react-dropdown';
import { baseurl } from './BaseUrl';
const sendmail = baseurl + 'send_mail';
const findAllitemUrl = baseurl + 'listofdoner';
class RecMessage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: true,
            to: "",



        };
this.state = {
options: []
};



        this.onDismiss = this.onDismiss.bind(this);
        this.onSelectmulti = this.onSelectmulti.bind(this);

    }

componentDidMount() {
    //alert("ff");
axios.post(findAllitemUrl)
.then(Response => {
//alert(JSON.stringify(Response.data.data.length));
 for(var i =0;i<Response.data.data.length;i++){
// alert(Response.data.data[i].email)
      this.state.options.push({name:Response.data.data[i].email});
      }
//alert(JSON.stringify(this.state.options));

})
.catch(err => {
console.log(err);
})
}
    handletoChange = event => {
        this.setState({
            to: event.target.value
        })
    }

    onDismiss() {
        this.setState({ visible: false });
    }

    handleSubmit = event => {
        //   alert(` Hi ${this.state.tomail} ,your message sent `)
       // alert(event.target.to.value);
        //alert(this.state.selectedList);

        let form1Obj = {
            to: this.state.all_email,
            subject: event.target.subject.value,
            text: event.target.comment.value

        }
        //alert(JSON.stringify(form1Obj));
       
          axios.post(sendmail, form1Obj)
              .then(res => {
                  alert("Mail Send Sucessfully!");
                  //console.log(res);
                 // console.log(res.data);
window.location.reload();
              })
              .catch(function (error) {
                  console.log(error);
              });
          event.preventDefault();
          /*
        axios.post(sendmail, form1Obj)
            .then(response => {
                // alert(JSON.stringify(response.status));
                alert("dr");
            })
*/
    }

onSelectmulti(selectedList, selectedItem) {
this.state = {
all_email: []
};
for(var i =0;i<selectedList.length;i++){
 //alert(selectedList[i].name)
 
 this.state.all_email.push(selectedList[i].name);
}
 //alert(JSON.stringify(this.state.all_email));

}

onRemove(selectedList, removedItem) {
//alert("fds");
}


    render() {
        return (
            <div className="bgfund">
                <Container >
                    <br></br> <br></br>
                    <Row className="justify-content-center">
                        <Col md="10">
                            <Card className=" shadow border-0 " style={{ backgroundColor: "white" }}>
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div style={{ textAlign: "center", color: "gray" }}>
                                        <img style={{ height: "40px", width: "60px" }} src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img>
                                        <br></br>
                                        <h3 style={{ textAlign: "center", color: "gray" }}><u> Send Email  </u></h3>

                                        <br></br><br></br>

                                        <Form key={1} onSubmit={this.handleSubmit}>
                                            <Row  >
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label style={{ color: "black", fontSize: "20px", fontWeight: "bold" }} for="Recipients" >Select Donor Email</Label>

                                                    </FormGroup>


                                                </Col>
                                                <Col md={7}>

<Multiselect
options={this.state.options} // Options to display in the dropdown
selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
onSelect={this.onSelectmulti} // Function will trigger on select event
onRemove={this.onRemove} // Function will trigger on remove event
displayValue="name" // Property name to display in the dropdown options
/>


                                                 
                                                </Col>
                                            </Row>
                                            <div></div>
                                            <Row >
                                                <Col  md={4}>
                                                    <FormGroup> <Label style={{ color: "black", fontSize: "20px", fontWeight: "bold" }} for="Subject" >Subject</Label> </FormGroup>
                                                </Col>
                                                <Col md={7}>
                                                    <FormGroup>
                                                        <Input type="name" name="subject" id="subject" placeholder="Email subject" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label style={{ color: "black", fontSize: "20px", fontWeight: "bold" }} for="Message" >Message</Label>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={7}>
                                                    <FormGroup>
                                                        <Input type="textarea" name="comment" id="comment" placeholder="Type email content here" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row >
                                                <Col md={6} style={{textAlign:"right"}}>
                                                    <Button className=" btn-success">Send</Button>
                                                </Col>

                                                <Col style={{textAlign:"left"}} md={6}><button className="btn-danger"><Link to="/dashboardAdmin" style={{color:"white",textDecoration:"none"}}>Back</Link></button>
                                                </Col>

                                            </Row>
                                        </Form>


                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <br></br> <br></br><br></br>
                </Container>

            </div >
        )
    }

}
export default RecMessage;






