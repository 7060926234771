import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons';
class ViewAmount extends Component{
    render(){
        return(
          <div>
           <h1 style={{align:faAlignCenter}}> In Process</h1>
            </div>
      //       <Table>
      //   <thead>
      //     <tr>
      //       <th>Sr.No</th>
      //       <th>Item Name</th>
      //       <th> Item Details</th>
      //       <th>Date</th>
      //       <th>Amount</th>
      //     </tr>
      //   </thead>
      //   <tbody>
      //     <tr>
      //       <th scope="row">1</th>
      //       <td>Paper</td>
      //       <td>Useless Paper</td>
      //       <td>06/08/2019</td>
      //       <td>2000</td>
      //     </tr>
      //     <tr>
      //       <th scope="row">2</th>
      //       <td>Chair</td>
      //       <td>Not good</td>
      //       <td>08/08/2019</td>
      //       <td>3000</td>
      //     </tr>
      //     <tr>
      //       <th scope="row">3</th>
      //       <td>Book</td>
      //       <td>For student</td>
      //       <td>11/08/2019</td>
      //       <td>5000</td>
      //     </tr>
      //     <tr><td></td>
      //     <td></td>
      //     <td></td>
      //     <td>Total Amount=</td>
      //     <td>10000</td></tr>
      //   </tbody>
      // </Table>
        );
    }
}
export default ViewAmount;