import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import SliderEdu from '../Components/sliderEducation';
import SliderWoman from '../Components/sliderWoman';
import {Container,Row,Col} from 'reactstrap';
import { baseurl } from './BaseUrl';
import axios from 'axios';

const get_homecontent = baseurl + 'get_homecontent4';
export default class SelectionProcess extends Component {
    constructor(props) {
        super(props);
    
    this.state = {
            data: ''
        };
       
    }
    componentDidMount() {
   
        axios.post(get_homecontent)
        .then(Response => {
      
        this.setState( {
        data:Response.data.data[0].women
        } );
        
        })
        .catch(err => {
        console.log(err);
        })
        
        
            }
        

    render() {
        return (
            <div className="bg">
               
               <br></br>
                <Container> 
                <div>  <marquee behavior="alternate" direction="right"><b><p style={{color:"maroon",fontStyle:"italic"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
                </Container>
                      
            <div className="divbg"> <br></br> <p style={{color:"white",textAlign:"center",fontSize:"50px"}}>Selection Process</p>
            <p style={{color:"white",textAlign:"center",fontSize:"20px",fontStyle:"italic"}}>“Great vision without great people is irrelevant.”
            <Container> 
           
         <p style={{color:"white",textAlign:"right",fontSize:"12px",fontStyle:"italic"}}>  Jim Collins </p>
         </Container>
</p> <br></br></div>

                
<div> 

    
    <br></br>
    <br></br>
    <Container>
    <Row className="justify-content-center">
   
    {/* <h1 style={{color:"#2E3092"}}>Empowerment:</h1> */}
    
    <br></br>
   
    <p style={{color:"#2E3092"}} className="justify-content-center text-justify pfont " >Striking a correct and transparent balance in the selection process has been a challenging part for us but we have created an extremely fair and unbiased system that will provide the deserving candidate with a lifetime opportunity.</p>
    </Row>
    <br></br>
    <Row className="justify-content-center">
    <Col md={10}> 
    <p className="justify-content-center pfont text-justify text-muted" >• We request the Government of Uttarakhand and Educational Department to share the list of 20 girls after the Class 12 Board exam results.</p>
    <p className="justify-content-center pfont text-justify text-muted" >• Proper verification is executed that helps our team to recognize the right candidates. We approach the girls accordingly.</p>
    <p className="justify-content-center pfont text-justify text-muted" >• A platform has been created by us to allow the selected girls to apply.</p>
    <p className="justify-content-center pfont text-justify text-muted" >•	Our selection board consists of 2 Trustees from RSN Bahuguna Foundation and 1 external member. The panel has the responsibility to select the candidate as per the availability of funds. </p>
    </Col>
    </Row>
    
 
    <br></br><br></br>
 <Row className="justify-content-center">
  <img src="https://i.postimg.cc/JnCdd9vc/selection.jpg" className="old"  ></img> 
 </Row>

<br></br><br></br>
<br></br>




    </Container><NewHeader />
    </div>      
              
            </div>
        );
    }
}  
