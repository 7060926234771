import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import { Container, Row, Col, FormGroup, Label,Badge, Input, Button, Card, CardBody, Form } from 'reactstrap';
import {Link,Route,Redirect} from 'react-router-dom';
import axios from 'axios';

import { baseurl } from './BaseUrl';
import FlatList from 'flatlist-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faMobileAlt, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Gallery from 'react-grid-gallery';


const get_gallary = baseurl + 'get_gallary';



//cms gallery
class Gallery1 extends Component {

   constructor(props) {
        super(props);
        this.state ={
            file: null,
Rows: [ ],
Rows_s: [ ],
new_arr: [ ],
Rows_count: 0,
        };

       // this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
       // this.handleDelete = this.handleDelete.bind(this);
    }
	
	
	
	
	
	

    onChange(e) {
        this.setState({file:e.target.files[0]});
    }
renderPerson = (person, idx) => {
//alert(JSON.stringify(person));

  return (
     
 
      
<Row>

<a href={`${person.Image_url}`} ><img src={`${person.Image_url}`}></img></a>

</Row>

 
  );
}

componentDidMount() {
     
        axios.post(get_gallary)
            .then(Response => {
			//	alert(JSON.stringify(Response.data.data));
			let abc = [];
//this.setState({Rows:Response.data.data})
//this.setState({Rows_count:Response.data.data.length})
var row_count = Response.data.data.length;
for(let i=0; i<row_count;  i++){
	//alert(JSON.stringify(Response.data.data[i].Image_url));
	abc = {src:Response.data.data[i].Image_url,thumbnail:Response.data.data[i].Image_url,thumbnailWidth:320,thumbnailHeight:200,caption:'pra-nee'}
	this.state.new_arr.push(abc);
	
}

//alert(JSON.stringify(this.state.new_arr));
//alert(JSON.stringify(this.state.new_arr));

		this.setState({Rows_s:this.state.new_arr});

               
            })
            .catch(err => {
                console.log(err);
            })
}



    render() {
        return (
            <div className="bgfund">
              <Container>
                <br></br>

              
                       <Gallery images={this.state.Rows_s}/>
       


                        <br></br><br></br><br></br><br></br><br></br>
                        <Row> </Row>

                        <br></br><br></br><br></br><br></br>  <br></br><br></br><br></br><br></br>
                       

                </Container>
                <NewHeader />
            </div >
        )
    }

}
export default Gallery1;





