import React, { Component } from 'react';
import { Container,Row,Col,Card,CardBody} from 'reactstrap';
class Mentor extends Component {
    render(){
        return(
            <div className="bg">
  {/* <div >
<img src="https://i.postimg.cc/YS8dYgpY/mentor.jpg" className="AboutUsImg"></img> \

            </div> */}

<div className="divbg"> 
<br></br><p style={{color:"white",textAlign:"center",fontSize:"50px"}}>Mentor</p>
            <p style={{color:"white",textAlign:"center",fontSize:"20px",fontStyle:"italic"}}>“My mentor said, ‘Let’s go do it,’ not ‘You go do it.’ How powerful when someone says, ‘Let’s!’”
            
            <Container> 
            <p style={{color:"white",textAlign:"right",fontSize:"12px",fontStyle:"italic"}}>  Jim Rohn  </p>
            </Container>
</p><br></br>

</div>
<br></br><br></br>
           
            <Container> 


<Row className="justify-content-center">
<Col md="9">
<p className="pfont  text-justify"><b style={{ color:"#2E3092"}}>Dr VK Bahuguna</b> retired as  Principal Secretary for the Government of Tripura, India.</p>
<p className="pfont text-muted text-justify">His  work focuses on forests, animal resources and agriculture/ horticulture/watershed management and state land use.</p>
<p className="pfont text-muted text-justify">He holds a Ph.D in Forest Ecosystems and an MSc in Zoology from the University of Garhwal, India; an MSc in Resource Management from the University of Edinburg, UK  and a Post-Graduate Diploma in Forestry from the Indian Forest College (now Indira Gandhi National Forest Academy).</p>
<p className="pfont text-muted text-justify">He served as Director General of the Indian Council of Forestry Research and Education, Chancellor of the Forest Research Institute Deemed University, Dehradun, India, and Inspector General of Forest at the Ministry of Environment and Forests, as well as Associate Professor at the Indian Institute of Forest Management.</p>
<p className="pfont text-muted text-justify">He received the Queen’s Award for Forestry from the Commonwealth Forestry Association UK, and the ‘Great Son of India Award 2013’ from the All India Intellectual Federation. He has published more than 160 publications in national and international journals.</p>
<p className="pfont text-justify"><b style={{ color:"#2E3092"}}>Dr VK Bahuguna is eldest son of late Sri Nand Bahuguna</b></p>




<br></br>
</Col>
</Row>
</Container>
            </div>
        )
    }

}
 export default  Mentor