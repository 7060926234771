import React, { Component } from 'react';
import { Container,Row, Col,FormGroup,Label,Button,Card,CardBody,Table} from 'reactstrap';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { baseurl } from './BaseUrl';
import FlatList from 'flatlist-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faMobileAlt, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const visitor = baseurl + 'all_visitor';

class VisitorView extends Component{

constructor(props) {
  super(props);
  this.state = {
       Rows: [ ],
       Rows_count: 0,
 
  }

  let is_login = sessionStorage.getItem("logged");

if(is_login === 'none') {
this.props.history.push('/');
}
    // this.onDismiss = this.onDismiss.bind(this);
   
 }
renderPerson = (person, idx) => {
//alert(JSON.stringify(person));
  return (
      <tr  key={`${person._id}`}>
        <td>{person.system_ip} </td>
        <td>{person.system_network} </td>
        <td>{person.createdAt} </td>
       
      </tr>
  );
}
componentDidMount() {
       
        axios.get(visitor)
            .then(Response => {
               //alert(JSON.stringify(Response.data.length));
this.setState({Rows:Response.data})
this.setState({Rows_count:Response.data.length})
               
            })
            .catch(err => {
                console.log(err);
            })
}

    render(){
        return(
        <div className="bg">
            <br></br>
            <Container>
            <p>No of visitors =&nbsp;{this.state.Rows_count}</p>
            <Row className="justify-content-end mt-0">
                                        <Link to="/DashboardAdmin" style={{ color: "white", textDecoration: "none" }}>
                                            <FontAwesomeIcon style={{ color: "red", fontSize: "30px" }} icon={faTimesCircle} ></FontAwesomeIcon> </Link>
                                    </Row> 
<Row>
<Table id="students">
<tr>
<th>Visitor IP </th>
<th>Visitor Network </th>
<th>Visited Date </th>

 </tr>
                                 
<FlatList list={this.state.Rows} renderItem={this.renderPerson}/>
</Table>
            </Row>
            </Container>
               
           
<div> </div>

</div>
)
    }
}
export default VisitorView