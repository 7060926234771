import React,{Component} from 'react';
import {} from 'reactstrap';

class HomeLogistic extends Component{
    render(){
        return(
            <div>
                <h1>
                    Welcome to Logistic!!!!
                </h1>
            </div>
        )
    }
}
export default HomeLogistic