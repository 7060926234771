import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';

class PreviewBeneficiary extends Component {
  render() {
    return (
        <div>
          <br></br>
      <Container className="bgclrBeneForm">
      <br></br>
        <h2 style={{textAlign:"center"}}><b>Beneficiary Registration Form</b></h2>
        <br></br>
        
      <Row >

<div >
  <p className="Parag1"><b></b></p><br>

  </br>

  <Form className="ContentBe">
    <FormGroup row>
      <Label for="FName" sm={2}>
        <b>First Name</b></Label>
      <Col sm={4}>
        <Input type="FName" Value="Ankit"/>
      </Col>
      <Label for="LName" sm={2}>
        <b>Last Name</b>
      </Label>
      <Col sm={4}>
        <Input type="LName" value="Kumar" />
      </Col>

    </FormGroup>



    <FormGroup row>
      <Label for="FatherName" sm={2}>
        <b>Father Name</b></Label>
      <Col sm={4}>
        <Input type="FatherName" Value="Raman" />
      </Col>
      <Label for="MotherName" sm={2}>
        <b>Mother Name</b>
      </Label>
      <Col sm={4}>
        <Input type="MotherName" Value="Rakhi" />
      </Col>

    </FormGroup>



    <FormGroup row>
      <Label for="DOB" sm={2}>
        <b>Date Of Birth</b></Label>
      <Col sm={4}>
        <Input type="DOB" Value="05-05-1997" />
      </Col>

      <Label for="Pancard No." sm={2}>
        <b>PanCard No.</b></Label>
      <Col sm={4}>
        <Input type="txtPancard" Value="AAP123K" />
      </Col>

    </FormGroup>

    <FormGroup row>


      <Label for="Email" sm={2}>
        <b>Email</b></Label>
      <Col sm={4}>
        <Input type="txtEmail" Value="Ankit@gmail.com" />
      </Col>

      <Label for="Mobile No." sm={2}>
        <b>Mobile No.</b></Label>
      <Col sm={4}>
        <Input type="txtMobileNo" Value="7898654512" />
      </Col>

    </FormGroup>

    <FormGroup row>

      <Label for="MaritalStatus" sm={2}>
        <b>Marital status</b></Label>
      <Col sm={4}>

        <Input type="select" name="select" id="MaritalStatus">
          <option selected>Single</option>
          <option>Married</option>
          <option>Other</option>

        </Input>
      </Col>

      <Label for="Gender" sm={2}>
        <b>Gender</b></Label>
      <Col sm={4}>

        <Input type="select" name="select" id="exampleSelect">
          <option selected>Male</option>
          <option>Female</option>

        </Input>
      </Col>
    </FormGroup>
    <FormGroup row>
                  <Label for="Qualification" sm={2}>
                    <b>Qualification</b></Label>
                  <Col sm={4}>
                    <Input type="txtQlifictn" value="BBA" />
                  </Col>

                  <Label for="AnnualIncome" sm={2}>
                    <b>Annual Income</b></Label>
                  <Col sm={4}>
                    <Input type="txtAnnualIncome"  value="10000"/>
                  </Col>

                </FormGroup>
                <FormGroup row>
                  <Label sm={2}>
                    <b>Date</b></Label>
                  <Col sm={4}>
                    <Input type="Date" value="06-08-2019"/>
                  </Col>

                  <Label sm={2}>
                    <b>Father Occupation</b></Label>
                  <Col sm={4}>
                    <Input type="txtOccuption" value="Farmer"/>
                  </Col>

                </FormGroup>
                <FormGroup row>
                  <Label sm={2}>
                    <b>State</b></Label>
                  <Col sm={4}>
                    <Input type="text" value="Delhi"/>
                  </Col>

                  <Label sm={2}>
                    <b>City</b></Label>
                  <Col sm={4}>
                    <Input type="text" Value="Rohini"/>
                  </Col>

                </FormGroup>

    <FormGroup row>


      <Label for="Current Address" sm={2}>
        <b>Current Address</b></Label>
      <Col sm={4}>
        <Input type="textarea" Value="Sec 12 New Delhi Pin no-100231" />
      </Col>

      <Label for="Permanent Address" sm={2}>
        <b>Permanent Address</b></Label>
      <Col sm={4}>
        <Input type="textarea" Value="Sec 12 New Delhi Pin no-100231" />
      </Col>

    </FormGroup>

    <FormGroup row>


      <Label for="Upload Photo" sm={2}>
        <b>Upload Photo</b></Label>
      <Col sm={4}>
        <Input type="File" name="BeneImg" id="BeneImg" accept="image/png, image/jpeg" />
      </Col>

      <Label for="Upload Documents" sm={2}>
        <b>Upload Documents</b></Label>
      <Col sm={4}>
      <Input type="File"  className="form-control"/>
      </Col>

    </FormGroup>
<br></br>
    <Col md={12}>
      <Button>Edit</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                              <Button>Ok</Button>
    </Col>
<br></br>
  </Form>

</div>
<br></br>

</Row>

      </Container>
      <br></br>
      <br></br>
      </div>
    );
  }
}
export default PreviewBeneficiary;

