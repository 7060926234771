

import React, {  } from 'react'
import NewHeader from '../Components/NewHeader';
import {
    
    Row,Alert, Container,  Col,  Button,    Card,  CardHeader, CardSubtitle
} from 'reactstrap';
import { faAddressCard, faMobileAlt, faEnvelope, faEdit, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from 'react-router';
import {Link} from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
const Marker = props => {
    return <div><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></div>
} 

class LogisticDashboard extends React.Component {
    static defaultProps = {
        center: {
            lat: 28.414648,
            lng: 77.094404
        },
        zoom: 15
    };
    constructor(props) {
        super(props);

        this.state = {
            visible: true
        };

        this.onDismiss = this.onDismiss.bind(this);

        let is_login = sessionStorage.getItem("logged");

if(is_login === 'none') {
this.props.history.push('/')
}

    }

    onDismiss() {
        this.setState({ visible: false });
    }

    render() {
        return (
            <div>
            <br></br>
            <Container style={{ backgroundColor: '#d5d5d5' }}>
            <Row>
                    <Col></Col>   <Col><b>Ragman Dashboard</b></Col>  <Col></Col>
                            </Row>
                <div style={{ padding: '10px' }}>
                    <Row style={{ padding: '10px' }}>
                        <Col md={4} sm={12}>
                            <Row>
                                <Col md={12}>
                                    <Card style={{ paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
                                        <Row>
                                            <Col md={10}>My Profile</Col>
                                            <Col md={2}><FontAwesomeIcon icon={faEdit} /></Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col md={12}><FontAwesomeIcon icon={faAddressCard} fixedWidth />{sessionStorage.getItem("Name")}</Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col md={12}><FontAwesomeIcon icon={faMobileAlt} fixedWidth /> {sessionStorage.getItem("phoneNumber")}</Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col md={12}><FontAwesomeIcon icon={faEnvelope} fixedWidth /> {sessionStorage.getItem("email")}</Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                          
                        </Col>
                      
                                
                               
                            </Row>
                           
                           
                           
                           
                            <br></br>
                           
                            <br></br>
                    
                    {/* For Spacing */}
                   
                   
                </div>
            </Container><NewHeader />
            <br></br>
            </div>
        );
    }
}
export default LogisticDashboard;