
import React, { Component } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, Button, Card, CardBody, Form } from 'reactstrap';
import {Link,Route,Redirect} from 'react-router-dom';
import axios from 'axios';
import CKEditor from 'ckeditor4-react';
import { baseurl } from './BaseUrl';
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const content_change = baseurl + 'content_change6';
const get_homecontent = baseurl + 'get_homecontent6';

class contentCounselling extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: true,
            page: ""
        };
this.state = {
            data: '<p>React is really <em>nice</em>!</p>'
        };
        this.handleChange = this.handleChange.bind( this );
        this.onEditorChange = this.onEditorChange.bind( this );
        this.onDismiss = this.onDismiss.bind(this);
       

    }


    onEditorChange( evt ) {
        this.setState( {
            data: evt.editor.getData()
        } );
    }
   
    handleChange( changeEvent ) {
        this.setState( {
            data: changeEvent.target.value
        } );
    }

    onDismiss() {
        this.setState({ visible: false });
    }
componentDidMount() {
    //alert("ff");
axios.post(get_homecontent)
.then(Response => {
// alert(JSON.stringify(Response.data.data[0].home));
this.setState( {
data:Response.data.data[0].counselling
} );

})
.catch(err => {
console.log(err);
})
}
    handleSubmit = event => {
       
        let form1Obj = {
            counselling_content: this.state.data,
        }
       
          axios.post(content_change, form1Obj)
              .then(res => {
                  alert("Content Updated Sucessfully!");
 window.location.reload();
              })
              .catch(function (error) {
                  console.log(error);
              });
          event.preventDefault();
       
    }




    render() {
        return (
            <div className="bgfund">
                <Container >
                    <br></br> <br></br>
                    <Row className="justify-content-center">
                        <Col md="10">
                            <Card className=" shadow border-0 " style={{ backgroundColor: "white" }}>
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div style={{ textAlign: "center", color: "gray" }}>
                                        <img style={{ height: "40px", width: "60px" }} src="https://i.postimg.cc/0jv8yvyb/Logo2.png"></img>
                                        <br></br>
                                        <h3 style={{ textAlign: "center", color: "gray" }}><u>Counselling CMS </u></h3>

                                        <br></br><br></br>

                                        <Form key={1} onSubmit={this.handleSubmit}>
                                           
                                            <Row>
                                               

                                                <Col md={11}>
                                                    <FormGroup>
                                                    <CKEditor
data={this.state.data}
onChange={this.onEditorChange}

config={ {
extraPlugins : [ [ 'colorbutton','font','justify','showblocks','easyimage','uploadimage '] ]
} }

/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row >
                                                <Col md={6} style={{textAlign:"right"}}>
                                                    <Button className=" btn-success">Update</Button>
                                                </Col>

                                                <Col style={{textAlign:"left"}} md={6}><button className="btn-danger"><Link to="/dashboardAdmin" style={{color:"white",textDecoration:"none"}}>Back</Link></button>
                                                </Col>

                                            </Row>
                                        </Form>


                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <br></br> <br></br><br></br>
                </Container>

            </div >
        )
    }

}
export default contentCounselling





