import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import SliderEdu from '../Components/sliderEducation';
import SliderWoman from '../Components/sliderWoman';
import {Container,Row,Col} from 'reactstrap';
import { baseurl } from './BaseUrl';
import axios from 'axios';

const get_homecontent = baseurl + 'gethostbene';
export default class HostBeneficiary extends Component {
    constructor(props) {
        super(props);
    
    this.state = {
            data: ''
        };
       
    }
    componentDidMount() {
   
        axios.post(get_homecontent)
        .then(Response => {
      
        this.setState( {
        data:Response.data.data[0].hostbene
        } );
        
        })
        .catch(err => {
        console.log(err);
        })
        
        
            }
        

    render() {
        return (
            <div className="bg">
               
               <br></br>
                <Container> 
                <div>  <marquee behavior="alternate" direction="right"><b><p style={{color:"maroon",fontStyle:"italic"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
                </Container>
                      
            <div className="divbg"> <br></br> <p style={{color:"white",textAlign:"center",fontSize:"50px"}}> Host your beneficiary</p>
            <p style={{color:"white",textAlign:"center",fontSize:"20px",fontStyle:"italic"}}>“Inter-dependence is a beautiful emotion”. 
            <Container> 
           
         {/* <p style={{color:"white",textAlign:"right",fontSize:"12px",fontStyle:"italic"}}>  Malcolm Forbes </p> */}
         </Container>
</p> <br></br></div>

                
<div> 

    
    <br></br>
    <br></br>
    <Container >
    <Row >
   
    {/* <h3 style={{color:"#2E3092"}}>“Inter-dependence is a beautiful emotion”</h3> */}
    
    <br></br>
   
    <p >  <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div> 
       </p>
       </Row>         <Row>  
       <Col md={6}>

<br></br><br></br>

<img src="https://i.postimg.cc/WpS5sX9s/old.png" className="old"  ></img> </Col>
                            <Col md={6}> 
                            <img 
                             style={{height:"313px",width:"640px" , marginTop:"80px"}} 
                            src="https://i.postimg.cc/ncB4WMb6/tri.png"></img>
   
   </Col>



 </Row>

<br></br><br></br>
<br></br>




    </Container> <NewHeader />
    </div>      
              
            </div>
        );
    }
}  
