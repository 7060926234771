import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import { Link } from "react-router-dom";
import { baseurl } from './BaseUrl';
import axios from 'axios';
const get_homecontent = baseurl + 'getnews';
class AidImages extends Component {

    constructor(props) {
        super(props);

this.state = {
            data: ''
        };
       
    }



    componentDidMount() {
   
        axios.post(get_homecontent)
        .then(Response => {
        // alert(JSON.stringify(Response.data.data[0].funding));
        this.setState( {
        data:Response.data.data[0].cnews
        } );
        
        })
        .catch(err => {
        console.log(err);
        })
        
        
            }
        

    render(){
        return(
           
            <div>
           
       {/* <Container style={{height:"290px" }}> */}
       <Container className="news">
       
               
			    <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div>
              
               </Container>
              
          


            </div>
            
           
            
        )
    }
}export default AidImages