import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import {Container,Row,Col} from 'reactstrap';

import { baseurl } from './BaseUrl';
import axios from 'axios';

const get_homecontent = baseurl + 'get_homecontent7';

class Funding extends Component{
    constructor(props) {
        super(props);

this.state = {
            data: ''
        };
       
    }

    componentDidMount() {
   
        axios.post(get_homecontent)
        .then(Response => {
        // alert(JSON.stringify(Response.data.data[0].funding));
        this.setState( {
        data:Response.data.data[0].funding
        } );
        
        })
        .catch(err => {
        console.log(err);
        })
        
        
            }
        

    render(){
        return(
            <div className="bg">
                <br></br>
                <Container> 
                <div>  <marquee behavior="alter" direction="right"><b><p style={{color:"maroon"}}> Together we can and hope to bring the dawn of change in the society.</p> </b></marquee></div>
                </Container>
                
                <div className="divbg"><br></br> <p style={{color:"white",textAlign:"center",fontSize:"50px"}}>Our Funds</p>
                <p style={{color:"white",textAlign:"center",fontSize:"20px",fontStyle:"italic"}}>Transparency of funds is our prime responsibility.</p> <br></br></div>


                 <br></br>
                 <br></br>
                 <Container>
           
   
                <h1 style={{color:"#2E3092"}} ><b  style={{color:"#ED2A7B"}}>प्र</b><b style={{color:"#2E3092"}}>nee</b> Funding:</h1>
                 <br></br>
                 <Row> 
   
   <Col md={6}>
 
 
 <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div>

                <br></br>

                </Col>
               


                <Col md={6}>
                <br></br>
                <img src="https://i.postimg.cc/rwgw6x8C/Funding1.jpg" style={{borderRadius:"20px" ,height:"500px",width:"500px"}} ></img>     
                </Col>
             </Row>

            
                    <br></br>
       
                <br></br>
                </Container>
                <NewHeader />

                </div>

            


        )
    }
}
export default Funding