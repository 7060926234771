import React, { Component } from 'react';
import NewHeader from '../Components/NewHeader';
import { Card, Row,Button, CardImg, CardTitle, CardText, CardDeck,
    CardSubtitle, CardBody,Container } from 'reactstrap';
    import {Link} from 'react-router-dom';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faMobileAlt, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


    
class ViewDonor extends Component{

  constructor(props) {
    super(props);

    this.state = {
        visible: true,

    };
let is_login = sessionStorage.getItem("logged");

if(is_login === 'none') {
this.props.history.push('/');
}
    this.onDismiss = this.onDismiss.bind(this);

}



  onDismiss() {
    this.setState({ visible: false });
}
    render()
    {
        return(
          <div className="bgPage">
            <Container> <br></br> <br></br>
            <Row className="justify-content-end mt-0">
                                        <Link to="/DonorDashboard" style={{ color: "white", textDecoration: "none" }}>
                                            <FontAwesomeIcon style={{ color: "red", fontSize: "30px" }} icon={faTimesCircle} ></FontAwesomeIcon> </Link>
                                    </Row> 
                <h6>All Donors </h6> <br></br> <br></br>
<CardDeck>
      <Card>
        <CardImg top width="100%" src="https://static.toiimg.com/thumb/resizemode-4,width-1200,msid-58728222/58728222.jpg&w=256&h=180" alt="Card image cap" />
        <CardBody>
          <CardTitle>Donor 1</CardTitle>
          <CardSubtitle>Study material</CardSubtitle>
          <CardText>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</CardText>
          {/* <Button>More</Button> */}
        </CardBody>
      </Card>
      <Card>
        <CardImg top width="100%" src="https://imgstaticcontent.lbb.in/lbbnew/wp-content/uploads/sites/1/2015/12/soundofhope.jpg?w=750&h=500&dpr=1w=256&h=180" alt="Card image cap" />
        <CardBody>
          <CardTitle>Donor 2</CardTitle>
          <CardSubtitle>Cloth</CardSubtitle>
          <CardText>This card has supporting text below as a natural lead-in to additional content.</CardText>
          {/* <Button>More</Button> */}
        </CardBody>
      </Card>
      <Card>
        <CardImg top width="100%" src="https://c8.alamy.com/comp/BMH5MR/man-giving-food-to-beggars-jaipur-rajasthan-india-BMH5MR.jpg?&w=256&h=180" alt="Card image cap" />
        <CardBody>
          <CardTitle>Donor 3</CardTitle>
          <CardSubtitle>Food</CardSubtitle>
          <CardText>This is a wider card with supporting text below as a</CardText>
          {/* <Button>More</Button> */}
        </CardBody>
      </Card>
    </CardDeck>
  
  <br></br> <br></br>

    <CardDeck>
      <Card>
        <CardImg top width="100%" src="https://static.toiimg.com/thumb/resizemode-4,width-1200,msid-58728222/58728222.jpg&w=256&h=180" alt="Card image cap" />
        <CardBody>
          <CardTitle>Donor 1</CardTitle>
          <CardSubtitle>Study material</CardSubtitle>
          <CardText>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</CardText>
          {/* <Button>More</Button> */}
        </CardBody>
      </Card>
      <Card>
        <CardImg top width="100%" src="https://imgstaticcontent.lbb.in/lbbnew/wp-content/uploads/sites/1/2015/12/soundofhope.jpg?w=750&h=500&dpr=1w=256&h=180" alt="Card image cap" />
        <CardBody>
          <CardTitle>Donor 2</CardTitle>
          <CardSubtitle>Cloth</CardSubtitle>
          <CardText>This card has supporting text below as a natural lead-in to additional content.</CardText>
          {/* <Button>More</Button> */}
        </CardBody>
      </Card>
      <Card>
        <CardImg top width="100%" src="https://c8.alamy.com/comp/BMH5MR/man-giving-food-to-beggars-jaipur-rajasthan-india-BMH5MR.jpg?&w=256&h=180" alt="Card image cap" />
        <CardBody>
          <CardTitle>Donor 3</CardTitle>
          <CardSubtitle>Food</CardSubtitle>
          <CardText>This is a wider card with supporting text below as a</CardText>
          {/* <Button>More</Button> */}
        </CardBody>
      </Card>
    </CardDeck>
  
    <br></br> <br></br>


    </Container>
    <NewHeader />
    </div>
        );
    }
}

export default ViewDonor;