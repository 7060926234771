import React, { Component } from 'react';
import { Container, Card, Row, Col, Input, Label, Form, FormGroup, Button, CustomInput, } from 'reactstrap';
class Materialdetails extends Component {
    render() {
        return (
            <div className="bgPage">
            <Container>
                <br></br>
                
                    <Card className="gallery">
                      
                        <Form >
                            <h4 style={{ textAlign: "center" }}>Material Detail</h4>
                            <hr color='black' />
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="text"><b> Donor Name</b></Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Input type="text" placeholder="enter donor name"></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="text"><b>Donor E-mail</b></Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Input type="email" placeholder="enter email"></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="text"><b>Donor Ph.No.</b></Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Input type="phonenumber" placeholder="enter phone number"></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="text"><b>Donor Location</b></Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Input type="text" placeholder="enter donor location"></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>

                                <Col md={6}>

                                    <FormGroup>
                                        <Label for="exampleCheckbox"><b>Item list</b><p>(select items from the list)</p></Label>
                                        </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                        <FormGroup>
                                        <div>
                                            <CustomInput type="checkbox" id="Checkbox" label="Paper" />
                                            <CustomInput type="checkbox" id="Checkbox2" label="Plastic" />
                                            <CustomInput type="checkbox" id="Checkbox3" label="Iron" />
                                            <CustomInput type="checkbox" id="Checkbox4" label="electronics" />
                                        </div>
                                    </FormGroup>
                                </Col>

                            </Row>

                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="text"><b>Items Weight</b></Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Input type="text" placeholder="enter total item weight"></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div align='center'>
                                        <Button color="info">Submit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                       
                    </Card>
                
                <br></br>
            </Container>
            </div>
        )
    }

}
export default Materialdetails;