import React, { Component } from 'react';
import { Container, Table } from 'reactstrap';

class PendingDonorDetails extends Component {
    render() {
        return (
            <div>
                <Container>
                    <br></br>
                    <h2 style={{ textAlign: 'center' }}>Donor Details</h2>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Name</th>
                                <th>Location</th>
                                <th>Ph.No</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">3</th>
                                <td>Mohit</td>
                                <td>faridabad</td>
                                <td>8978456532</td>
                                <td>22-7-2019</td>
                            </tr>
                            <tr>
                                <th scope="row">4</th>
                                <td>Manav</td>
                                <td>Delhi</td>
                                <td>9678459832</td>
                                <td>25-08-2019</td>
                            </tr>
                            <tr>
                                <th scope="row">5</th>
                                <td>Mahak</td>
                                <td>Noida</td>
                                <td>8978456532</td>
                                <td>28-07-2019</td>
                            </tr>
                            <tr>
                                <th scope="row">5</th>
                                <td>Richa</td>
                                <td>Gurgaon</td>
                                <td>7898562585</td>
                                <td>14-08-2019</td>
                            </tr>
                        </tbody>
                    </Table>
                    <br></br>
                </Container>
            </div>
        )
    }
}
export default PendingDonorDetails